import react from 'react';
import './BoostEarnings.css';

const BoostEarnings = ()=>{
    return(
        <>
<section className="boostEarningsMain">
    <div className="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
              <div class="headboostEarnings">

                  <h2><span>Rental event coverages to help you</span> boost earnings and drive profitability.</h2>
              </div>
          </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div className="boostEarningsOuter">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="tab" href="#boostEarnings_1">Personal Rentals</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#boostEarnings_2">Gig Rentals</a>
                        </li>
                    </ul>
                
                    <div class="tab-content">
                        {/* START Tab 1 */}
                        <div class="tab-pane active" id="boostEarnings_1">
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                    <div className='boostEarningsLeft'>
                                        <h3><span>Personal or leisure activities</span> other than generating income</h3>
                                        <p>Designed specifically for personal use and leisure, not for profit-making or 
                                        commercial purposes.</p>
                                        <small>USE CASES</small>
                                        <ul>
                                            <li>
                                                <img width="24px" height="24px" src="/img/rentshield/personal_rentals_1.svg" alt="icon"/>
                                                <span>Vacations or weekend trips</span> 
                                            </li>
                                            <li>
                                                <img width="24px" height="24px" src="/img/rentshield/personal_rentals_2.svg" alt="icon"/>
                                                <span>Visiting family or friends</span> 
                                            </li>
                                            <li>
                                                <img width="24px" height="24px" src="/img/rentshield/personal_rentals_3.svg" alt="icon"/>
                                                <span>Running errands</span> 
                                            </li>
                                            <li>
                                                <img width="24px" height="24px" src="/img/rentshield/personal_rentals_4.svg" alt="icon"/>
                                                <span>Corporate travel (when not involving business operations like delivery or ride-hailing)</span> 
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                    <div className='boostEarningsRight'>
                                        <figure>
                                            <img class="img-fluid" src="/img/rentshield/boostEarnings_1.jpg" alt="Personal Rentals"/>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* START Tab 2 */}
                        <div class="tab-pane fade" id="boostEarnings_2">
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                    <div className='boostEarningsLeft'>
                                        <h3><span>Gig economy rental</span> for commercial purposes</h3>
                                        <p>Tailored for gig economy professionals, providing flexible rentals for business and commercial 
                                        use. This requires specialized coverage and  insurance cards in renters' names, ensuring 
                                        compliance and protection for hosts and drivers.</p>
                                        <ul>
                                            <li>
                                                <img width="24px" height="24px" src="/img/rentshield/gigRentals_1.svg" alt="icon"/>
                                                <span>Driving for ride-hailing services like Uber or Lyft</span> 
                                            </li>
                                            <li>
                                                <img width="24px" height="24px" src="/img/rentshield/gigRentals_2.svg" alt="icon"/>
                                                <span>Delivering goods or food for platforms like DoorDash, Uber Eats, or Instacart</span> 
                                            </li>
                                            <li>
                                                <img width="24px" height="24px" src="/img/rentshield/gigRentals_3.svg" alt="icon"/>
                                                <span>Performing courier or package delivery services</span> 
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                    <div className='boostEarningsRight'>
                                        <figure>
                                            <img class="img-fluid" src="/img/rentshield/boostEarnings_1.jpg" alt="Personal Rentals"/>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div> 
            
                    </div>
            
                </div>
            </div>
        </div>
    </div>
</section>
        </>
    )
}

export default BoostEarnings;