import React, { useEffect } from 'react';
import style from './RentIdanimation.css'
import img1 from './assets/images/shield_energy_1.svg';
import img2 from './assets/images/math_1.svg';
import img3 from './assets/images/do_not_touch_1.svg';

import img4 from './/assets/images/rentid.svg';
import img5 from './assets/images/rentid_1.svg';
import img6 from './assets/images/settings_1.svg';
import img7 from './assets/images/computer_check_1.svg'
const RentIdanimation = () => {
  
  useEffect(() => {
    const container4 = document.querySelector('.container4');
    const wrappers4 = document.querySelector('.wrappers4');

    const images = [
      img1, img2, img3, img4,img5,img6,img7
    ];

    // Generating the Card for using Array
    const elementsData = [
      "InsuranceValidation",
      "DriverIQ",
      "BlockList",
      "RentId",
      "IdentityVerification",
      "RiskManagement",
      "VerificationDash"
    ];

    
    const cardLinks = [
      "#AutoInsuranceValidation", "#DriverIQScore", "#NationalBlockListDatabase", "#CustomerRelations", "#RealtimeGuestVerification", "#RiskManagement", "#VerificationDashboard", "/payments", "#ai", "#AnalyticsReporting",
      "/violations", "/email", "/guest-app", "/FinancialMangementsync", "/booking", "/extras", "/redirect",
    ];


    Array.from({ length: 36 }).forEach((_, index) => {
      const elements = document.createElement('div');
      elements.classList.add('items5', `item-${index + 1}`);

      if (elementsData[index]) {
        const title = document.createElement('span');
       // title.innerHTML = elementsData[index];

        let camelCaseText = title.innerHTML; // Extract the current text
        camelCaseText = elementsData[index]; // Update the variable
		   let formattedText = camelCaseText.replace(/([A-Z])/g, ' $1').trim();
        title.innerHTML =  formattedText;     // Update the DOM element
        

         const img = document.createElement('img');
        img.src = images[index % images.length]; // Cycle through images
        img.classList.add('icon');


        if (elementsData[index] === "RentId") {
          elements.classList.add('rentid-card'); // Add custom class here
        }

         const anchor = document.createElement('a');
        document.querySelectorAll('.items5').forEach((item) => {
          item.addEventListener('click', () => {
            const anchor = item.querySelector('.card-link');
            if (anchor)
 {
              window.location.href = anchor.getAttribute('href');
            }
          });
        });
        anchor.href = cardLinks[index]; // Assign link based on the index
       // console.log(index,'test data');
      
        anchor.classList.add('card-link');
      
        //anchor.classList.add('card-link');
     
         anchor.appendChild(img);
         elements.appendChild(anchor);
       // elements.append(img);
        elements.append(title);
        elements.style.gridArea = elementsData[index];
        elements.setAttribute('data-name', elementsData[index]);

        wrappers4.append(elements);
      }
    });



    // Generating SVG Line for each Element
    const elementsDataName = [
   
      ["IdentityVerification", "InsuranceValidation"],
      ["RentId", "RiskManagement"],
      ["RentId", "BlockList"],
      ["DriverIQ", "InsuranceValidation"],
      ["RentId", "VerificationDash"],
      
    ];

    // Generating SVGs inside the .container4
    elementsDataName.forEach((_, index) => {
      const svgcontainer4 = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svgcontainer4.classList.add('svg-lines4', `svg-lines4-${index + 1}`);
      container4.appendChild(svgcontainer4);
    });

    // Function to create SVG paths
    const createSVGPath = (startX, startY, endX, endY) => {
      const svgNS = "http://www.w3.org/2000/svg";
      const path = document.createElementNS(svgNS, "path");
      const pathData = `
        M ${startX} ${startY}
        L ${startX} ${endY}
        L ${endX} ${endY}
        L ${endX} ${endY}
      `;
      path.setAttribute("d", pathData);
      path.setAttribute("stroke", "orange");
      path.setAttribute("stroke-width", "3");
      path.setAttribute("fill", "none");
      path.classList.add("animate-path4");

      return path;
    };

    const connectElements = () => {
      elementsDataName.forEach((name, index) => {
        const svgcontainer4 = document.querySelector(`.svg-lines4-${index + 1}`);
        const [startName, endName] = name;

        const item1 = document.querySelector(`[data-name='${startName}']`);
        const item2 = document.querySelector(`[data-name='${endName}']`);

        // Clear previous SVG content
        svgcontainer4.innerHTML = "";

        // Get positions and dimensions
        const rect1 = item1.getBoundingClientRect();
        const rect2 = item2.getBoundingClientRect();

        // Calculate start and end points
        const startX = rect1.left + rect1.width / 2;
        const startY = rect1.top + rect1.height / 2;
        const endX = rect2.left + rect2.width / 2;
        const endY = rect2.top + rect2.height / 2;

        // Append path to SVG container4
        svgcontainer4.appendChild(createSVGPath(startX, startY, endX, endY));
      });
    };

    const drawLines = () => {
      connectElements();
      const paths = document.querySelectorAll('.animate-path4');

      paths.forEach((path) => {
        const pathLength = path.getTotalLength();
        path.style.strokeDasharray = pathLength;
        path.style.strokeDashoffset = pathLength;
        path.style.setProperty('--path-length', `${pathLength}px`);
      });
    };

    const animatingLine = async () => {
      const path1 = document.querySelector('.svg-lines4-1 path');
      const path2 = document.querySelector('.svg-lines4-2 path');
      const path3 = document.querySelector('.svg-lines4-3 path');
      const path4 = document.querySelector('.svg-lines4-4 path');
      const path5 = document.querySelector('.svg-lines4-5 path');
      const RentId = document.querySelector("[data-name='RentId']");
      const InsuranceValidation = document.querySelector("[data-name='InsuranceValidation']");
      const IdentityVerification = document.querySelector("[data-name='IdentityVerification']");
      const BlockList = document.querySelector("[data-name='BlockList']");
      const DriverIQ = document.querySelector("[data-name='DriverIQ']");
      const RiskManagement  = document.querySelector("[data-name='RiskManagement']");
      const VerificationDash = document.querySelector("[data-name='VerificationDash']")
      async function animateCards() { 
        while (true) {
            // First Animation Sequence
            eachAnimation(path1, IdentityVerification, InsuranceValidation, "add");
            await delay(2000);
            eachAnimation(path1, IdentityVerification, InsuranceValidation, "remove");
            
            eachAnimation(path2, RentId, RiskManagement, "add");
            await delay(2000);
            eachAnimation(path2, RentId, RiskManagement, "remove");

            eachAnimation(path3, RentId, BlockList, "add");
            await delay(2000);
            eachAnimation(path3, RentId, BlockList, "remove");

            eachAnimation(path4, DriverIQ, InsuranceValidation, "add");
            await delay(2000);
            eachAnimation(path4, DriverIQ, InsuranceValidation, "remove");

            eachAnimation(path5, RentId, VerificationDash, "add");
            await delay(2000);
            eachAnimation(path5, RentId, VerificationDash, "remove");
 
 

     



        }
    }
    function delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
  }
  animateCards();
    };
    

    const eachAnimation = (path, card1, card2, action) => {

      const text1 = card1.querySelector('span');
      const text2 = card2.querySelector('span');


      if (action === "add") {
        path.classList.add("animate-now");
        card1.classList.add("animate-now");
        // card2.classList.add("animate-now-delay");
        setTimeout(() => {
          card2.classList.add('animate-now-delay');
      }, 1100);

        if (text1) text1.classList.add("show-text");
        if (text2) text2.classList.add("show-text");
      } else if (action === "remove") {
        path.classList.remove("animate-now");
        card1.classList.remove("animate-now");
        card2.classList.remove("animate-now-delay");
      }
    };

    // Initial drawing and animation
    setTimeout(() => {
      drawLines();
      animatingLine();
    }, 500);

    // Event listeners
    //window.addEventListener("resize", connectElements);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", connectElements);
      // Clean up any SVGs or elements created if necessary
    };
  }, []); // Empty dependency array to run only on mount

  return (
    <div className="container4">
    
      <div className="wrappers4"></div>
    </div>
  );
};

export default RentIdanimation;
