import react from 'react';
import { useState } from "react";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Container, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import './GuestVerification.css';

const GuestVerification = () => {
  // Set the default expanded state to the first accordion (panel0)
  const [expanded, setExpanded] = useState("panel0");

  // Data for each accordion
  const accordionData = [
    {
      title: "Account Validation",
      content: "When guests sign up on the Rentid platform, we validate their email and phone number through our security system to ensure trust and safety.",
      image: "/img/RentID/guestVerificatioRight_1.jpg",
    },
    {
      title: "Driver’s License Validation",
      content: "Verify driver’s licenses against official databases while analyzing document integrity to detect tampering, forgery, or altered documents.",
      image: "/img/RentID/guestVerificatioRight_2.jpg",
    },
    {
      title: "Biometric Verification",
      content: "Automated AI facial recognition and biometric analysis ensure renters are accurately matched with their identification documents.",
      image: "/img/RentID/guestVerificatioRight_3.jpg",
    },
    {
      title: "Identity Cross-Referencing",
      content: "Cross-check renter information against multiple authoritative databases and registries to identify red flags.",
      image: "/img/RentID/guestVerificatioRight_4.jpg",
    },
    {
      title: "Guest Risk Indicators",
      content: "Simple to understand data-based insights to assist in risk decision making. ",
      image: "/img/RentID/guestVerificatioRight_5.jpg",
    },
  ];


  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (

    <section className='guestVerificationMain'>
    <div className='container'>
  
              <div className='row justify-content-center'>
                <div className="col-md-10">
                  <div className="headGuestVerification">
                    <h2>Realtime <span>Guest Verification</span></h2>
                  </div>
                </div>
              </div>
 
              <div className='row'>
                    <div className="col-lg-4">
                      <div class="guestVerificationLeft">
                        <img class="img-fluid" src="/img/rentid_logo_icon.svg" alt="icon"/>
                        <h3>Instant, Accurate Trust and Safety Tools</h3>
                        <p>Verify guests effortlessly with our multi-layered tech. Swiftly confirm identities and reduce fraud risk, 
                          ensuring fleet security with cutting-edge verification.</p>
                      </div>
                    </div>

                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                              <div class="guestVerificationMiddle">
                                  {accordionData.map((accordion, index) => (
                                    <Box key={index} className="boxAccordian">
                                      <Accordion className="headingAccordian"
                                        expanded={expanded === `panel${index}`}
                                        onChange={handleAccordionChange(`panel${index}`)}
                                      >
                                        <AccordionSummary
                                               expandIcon={<ExpandMoreIcon />}
                                               aria-controls="panel1-content"
                                               >
                                          
                                          <Typography>
                                            {accordion.title}
                                          </Typography>
                                        </AccordionSummary>
                                      </Accordion>
                                      {/* Content below the accordion button */}
                                      {expanded === `panel${index}` && (
                                        <Box className="summaryAccordian">
                                          <Typography>{accordion.content}</Typography>
                                        </Box>
                                      )}
                                    </Box>
                                  ))}
                              </div>
                            </div>
                            <div className="col-lg-6 col-sm-6">
                              <div class="guestVerificatioRight">
                                      {accordionData.map((accordion, index) => (
                                        expanded === `panel${index}` ? (
                                          <Box key={index} sx={{ textAlign: 'center' }}>
                                            <img
                                              src={accordion.image}
                                              alt={`Image for ${accordion.title}`}
                                            />
                                          </Box>
                                        ) : null
                                      ))}
                              </div>
                            </div>
                        </div>
                    </div>
              </div>
 


    </div>
    </section>
    
  );
};

export default GuestVerification;
