import react from 'react';
import './LoyalGueststyle.css'

const LoyalGuest = ()=> {
    return(
        <>
       <section className='loyalGuest'>
       <div className='container'>
            <div className='row'>
                <div className='col-md-12 text-center'>
                      <div className='guestHeading'>
                            <h3>From First-Time Renter to <span>Loyal Guest.</span></h3>
                            <p>We’ve taken the guess work out of building trust, protecting your fleet and optimizing 
                              earnings with automated rental risk assessment tools. </p>
                            <div className='loyalGuestImg'>
                                <img class="img-fluid" src="/img/loyal-guest-new.png" alt="guest"/>
                            </div>
                          </div>
                      </div>
                </div>
       </div>
       </section>
        </>
    )
}

export default LoyalGuest;