import React from 'react';
import { Box, Button, Container, Grid, Typography , display} from '@mui/material';
import logo from '../../assets/images/logo_new.png';
import arrow_down from "../../assets/images/arrow-down-01.png";
import rent_success from "./assets/rent-success-logo.svg";
import success_purple from "../../assets/images/success_purple.png";
import { Link } from 'react-router-dom';
import Rentsuccessanimation from './Rentsuccessanimation';
import RentHeader from '../RentHeader/RentHeader';
import BetaForm from '../BetaForm';
const RentSuccess2 = () => {


  return (
  <>
      <Box
        sx={{
          background: 'linear-gradient(180deg, #082C72 0%, #001132 55%, #000000 100%)',
          width: '100%',
        }}
      >
        <Container>
       <RentHeader />


{/* Banner section start from here */}

      <section className='bannerSuccessMain'>
          <div className="row">
                <div className='col-lg-5'>
                    <div className='bannerSuccessLeft'>
                    <img src={rent_success}></img>
                    <small>CUSTOMER AND TECHNICAL SUPPORT</small>
                    <h1 class="successDesktop"><span>Live & automated</span> rental support when needed</h1>
                    <h1 class="successMobile"><span>Live &</span><span> automated</span> rental support when needed</h1>
                      <p>The <span>rentid</span> support team for you and your guests. We’re always available via chat, text, phone, or email, 
                      and powered by humans or robots for quicker service.</p>
                    </div>
                </div>

                <div className='col-lg-7'>
                    {/* <img src={success_purple} alt=''/> */}
                    <Rentsuccessanimation />
                </div>
          </div>
      </section>
        
        </Container>
      </Box>
      <BetaForm/>
  </>
  )
}

export default RentSuccess2
