import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import logo from '../../assets/images/logo_new.png';
import arrow_down from "../../assets/images/arrow-down-01.png"
import academy from "./assets/rentacad.svg";
import rend_id from "../../assets/images/rend_id.png";
import rend_id2 from "../../assets/images/yellowglow.png";
import { Link } from 'react-router-dom';
import Rentacademyanimation from './Rentacademyanimation';
import RentHeader from '../RentHeader/RentHeader';
import BetaForm from '../BetaForm';
const RentAcademy2 = () => {

  return (
    <>
         <Box
        sx={{
          background: 'linear-gradient(180deg, #082C72 0%, #001132 55%, #000000 100%)',
          height: '',
          width: '100%',
        }}
      >
        <Container>
         <RentHeader/>


{/* Banner section start from here */}

        <section className='bannerRentAcademyMain'>
        <div className="row">
        <div className='col-lg-5'>
        <div className='bannerAcademyLeft'>
            <img src={academy}></img>
            <small>RENTAL BEST PRACTICES FOR GROWTH</small>
              <h1>
             Learn on your time,<span className="uper-span">not your</span> <span>dime.</span>
              </h1>
              <p>Leverage our platform to educate yourself, inform your guests, and train your staff for healthier operations.</p>
            </div>
        </div>

            <div className='col-lg-7'>
                {/* <img src={rend_id2} alt=''/> */}
              <Rentacademyanimation />
            </div>
        </div>
      </section>
        
        </Container>
      </Box>
      <BetaForm/>
    </>
  )
}

export default RentAcademy2
