import react from 'react';
import './OffRentInsurance.css';

const OffRentInsurance = ()=>{
    return(
        <>
<section className="offRentInsuranceMain">
  <div className="container">
        <div class="row justify-content-center">
            <div class="col-md-9">
                <div class="headoffRentInsurance">
                    <h2><span>Off-Rent Insurance</span> to protect assets and remain compliant.</h2>
                    <a class="btn btnWhite" href="#">Start Off-Rent Quote<img src="/img/arrow_spl.svg" alt="icon"/></a>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div className="col-lg-8 col-md-10">
                <div class="row justify-content-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="offRentInsuranceInner">
                            <img width="54" height="54" src="/img/rentshield/protect_1.svg" alt="icon"/>
                            <h3>Protect Your Assets</h3>
                            <p>Purchase only the coverage you need, and let us protect your fleet when it’s not in use. 
                            Keep your tags active without relying on other insurance policies.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="offRentInsuranceInner">
                            <img width="54" height="54" src="/img/rentshield/protect_2.svg" alt="icon"/>
                            <h3>Ease of Mind</h3>
                            <p>Off-rent coverage exceeds state minimum limits in nearly all 50 states, offering comprehensive coverage for 
                            fleet use during maintenance, refueling, and car washes.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="offRentInsuranceInner">
                            <img width="54" height="54" src="/img/rentshield/protect_3.svg" alt="icon"/>
                            <h3>Legally Required</h3>
                            <p>Your vehicles must be insured on and off your lot. Off-rent coverage provides primary commercial auto coverage, 
                            essential for vehicles used as income sources.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="offRentInsuranceInner">
                            <img width="54" height="54" src="/img/rentshield/protect_4.svg" alt="icon"/>
                            <h3>Off-Rent Events</h3>
                            <p>Coverage is activated when your vehicle is idle on your lot, providing continuous coverage as 
                            you prepare it for the next rental—including trips for gas, maintenance, or a car wash.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
</section>

<section className="ultimateInsuranceMain">
  <div className="container">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
            <div class="ultimateInsuranceLeft">
                <small>OFF RENT INSURANCE PROTECTION</small>
                <h2>The <span>ultimate insurance</span> partnership for car rental </h2>
                <div class="ultimateInsuranceBtn">
                    <a class="btn btnWhite" href="#">Start Off-Rent Quote<img src="/img/arrow_spl.svg" alt="icon"/></a>
                    <a class="btn btnTransparent_1" href="#">Talk to Sales<img src="/img/arrow-right-white.svg" alt="icon"/></a>
                </div>            
            </div>
        </div>
        <div class="col-lg-6 col-md-6">
            <div class="ultimateInsuranceRight">
                <img class="img-fluid" src="/img/rentshield/onRent_diagram.png" alt="img"/> 
            </div>
        </div>
    </div>
    <div class="row align-items-center">
        <div class="col-lg-9 col-md-12">
            <div class="ultimateInsuranceBottom">
                <p>At this time, Rentid recognizes American Business Insurance (ABI) as an accredited off-rent insurance provider who meets and exceeds insurance regulations & standards. 
            Maintaining an active off-rent policy is required to opt into RentShield’s on-rent coverage.</p>
            </div>
        </div>
    </div>
  </div>
</section>

        </>
    )
}

export default OffRentInsurance;