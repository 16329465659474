import React, { useContext, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { validationSchemas } from "./validationSchemas";
import GeneralInfomation from './GeneralInfomation';
import ProfilePicture from "./ProfilePicture";
import CreatePassword from "./CreatePassword";
import Verification from "./Verification";
import Confirmation from "./Confirmation";
// import RemoteVehicle from "./RemoteVehicle";
import { API_ENDPOINT } from "../../Constant/Constant";
import qs from "qs";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/FormDataContext";
import { Link } from "react-router-dom";
import { components } from "react-select";
import HeaderHostsignup from "../HostSignup/HeaderHostsignup/HeaderHostsignup";

const steps = [
  {
    component: GeneralInfomation,
   // validationSchema: validationSchemas[0],
    label: "General Information",
  },
  {
    component:ProfilePicture,
    label: "Profile Picture"
  },
  {
    component:CreatePassword,
    label: "Password"
  },
  {
    component:Verification,
    label: "Verification"
  },
  {
    component:Confirmation,
    label: "Confirmation"
  },

  // Ensure correct index
];

const HostForm = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationModal1, setShowConfirmationModal1] = useState(false);
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [telematicsData, setTelematicsData] = useState([]);
  const [insuranceData, setInsuraceData] = useState([]);
  const { updateUserFormData } = useContext(UserContext);
  const { userData } = useContext(UserContext);
  const [commaVal, setCommaVal] = useState("")

  const formatPhoneNumber = (phone) => {
    if (!phone) return '';
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  };
  const getaround = [];
  const softwarerental = [];
  const initialValues = {
    first_name: userData.firstName || '',
    last_name: userData.lastName || '',
    email_address: userData.email || '',
    // phone_number:  userData.phone || '',
    phone_number: formatPhoneNumber(userData.phone) || '',
    companyName:userData.companyName || '',
    state: userData.state || '',
    fleet_size: userData.fleet || '',
    // email_address: "",
    // phone_number: "",
    // companyName: "",
    // state: "",
    // fleet_size: "",
  };
 
  
  
console.log(initialValues);
  const nextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const prevStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleChange = (e) => {
    const { value } = e.target;

    setTelematicsData((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((item) => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };

  const handleChangeInsure = (e) => {
    const { value } = e.target;

    setInsuraceData((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter(item => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };


  const handleSubmit = async (data, { setSubmitting }) => {
 

    // setFormSubmitting(true)
    var insurace = insuranceData;
    var chckNumbers = insurace.map(Number);
    var insuranceprovider = chckNumbers.join(',');


 
    if (currentStep === steps.length - 1) {
      const manage_business = data.manage_business.toString();
      const commaSeparated = telematicsData.toString();
      

      try {
        var formData = {
          "beta_users[first_name]": data.first_name || "First Name",
          "beta_users[last_name]": data.last_name || "Last Name",
          "beta_users[phone_number]": data.phone_number || "",
          "beta_users[email_address]": data.email_address || "Email Address",
          'beta_users[state]': data.state || 'State',
          'beta_users[fleet_size]': data.fleet_size || 'State',
        };
        const urlEncodedData = qs.stringify(formData);


        const response = await axios.post(
          `${API_ENDPOINT}/beta/register`,
          // `https://api.getrentid.io/api/beta/register`,
          urlEncodedData,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
        updateUserFormData(data.first_name, data.last_name);
        nextStep();
        navigate("/BetaThankyou");
      } catch (error) {

        console.error("Error submitting form:", error);
      }

    } 
    else {
      
      nextStep();
    }

    setSubmitting(false);
  };



  const CurrentStepComponent = steps[currentStep].component;
  const currentValidationSchema = steps[currentStep].validationSchema;
  const progress = ((currentStep + 1) / steps.length) * 100;


  const handleOpenmodal = () => {
    setShowConfirmationModal1(true)
   
  }

  return (
    <div className="main beta-signup host_signup">
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div
              className="modal fade"
              id="exampleModal1"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
             
            >
              <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                  <div className="container">
                  {/* <header className=" pt-2">
                    <div className="row beta-header">
                      
                      <div className="col-lg-3 beta-flow-section">
                        <a href="/">
                          <img
                            src="/img/beta-signup/mobile-logo.png"
                            className="img-fluid"
                            alt="feature img"
                            style={{ maxHeight: "50px" }}
                          />
                        </a>
                      </div>

                      <div className="col-lg-9 beta-flow-section text-end">
                        <a href="/" className="btn return-hone">
                          Return Home
                        </a>
                      </div>
                    </div>
                  </header> */}
                    <div className="modal-header">
                      <div className="row w-100 align-items-center information-sec">
                        <div className="col-12 col-xs-12 col-md-6 p-0 d-flex gap-3">
                       
                        {currentStep > 0 && (
                        <img
                              src="/img/beta-signup/back.svg"
                              className="back_step"
                              alt="back"
                              onClick={prevStep}
                            />        )} <h5
                            className="modal-title text-start"
                            id="exampleModalLabel"
                          >
                            {steps[currentStep].label}
                          </h5>
                        </div>
                        <div className="col-12 col-xs-12 col-md-6 px-0">
                          <p className="step-count stepcount-text text-md-end mb-0 ps-md-0">
                            Step {currentStep + 1}/{steps.length}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-body bottom-border py-4">
                      <CurrentStepComponent
                        handleChange={handleChange}
                        handleChangeInsure={handleChangeInsure}
                        telematicsData={telematicsData}
                        insuranceData={insuranceData}
                        setTelematicsData={setTelematicsData}
                        setInsuraceData={setInsuraceData}
                        commaVal={commaVal}
                        setCommaVal={setCommaVal}
                      />
                    </div>
                  </div>
                  <div className="modal-footer pt-0 px-0">
                    <div className="row w-100">
                      <div className="col-12 p-0">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${progress}%` }}
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container beta-footer">
                    <div className="row align-items-center w-100 pt-3 px-md-0">
                      <div className="col-4 exit-button">
                        <button
                          type="button"
                          className="btn exitbtn"
                          onClick={() => setShowConfirmationModal(true)}
                        >
                          Exit
                        </button>
                      </div>
                      <div className="col-8 btn-continue text-end d-flex gap-2 align-items-center justify-content-end">
                      {currentStep > 0 && (
                          <button
                            type="button"
                            className="btn btn-outline-dark radius-5 backbtn "
                            onClick={prevStep}
                          >
                            <span className="">Back</span>
                        
                          </button>
                          
                        )}

                        {currentStep > 0 && (
                        <button
                          type="button"
                          className="btn btn-outline-dark radius-5 back-arrow-btn  d-lg-none"
                          onClick={prevStep}
                        >
                          <img
                            src="/img/beta-signup/back-icon.svg"
                            className="img-fluid back-icon"
                            alt="Back"
                          />
                        </button>
                      )}

                        <div className="footer-btn-sec">
                        {/* <img
                              src="/img/beta-signup/back-icon.svg"
                              className="img-fluid close-modal d-lg-none d-block"
                              alt="back"
                            /> */}
                        <button
                          type="submit"
                          className="btn btn-dark continuebtn sendbtn send_btn radius-5 ms-0"
                          disabled={isSubmitting}

                        >
                          {currentStep === steps.length - 1 ? "Continue" : "Continue"}
                        </button>
                        </div>

                      </div>
                    </div>
                    </div>
                   
                  </div>

                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>


{/* modal2 */}
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div
              className="modal fade"
              id="exampleModal2"
              tabIndex="-1"
              aria-labelledby="exampleModal2Label"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                  <div className="container">
                  {/* <header className=" pt-2">
                    <div className="row beta-header">
                      
                      <div className="col-lg-3 beta-flow-section">
                        <a href="/">
                          <img
                            src="/img/beta-signup/mobile-logo.png"
                            className="img-fluid"
                            alt="feature img"
                            style={{ maxHeight: "50px" }}
                          />
                        </a>
                      </div>

                      <div className="col-lg-9 beta-flow-section text-end">
                        <a href="/" className="btn return-hone">
                          Return Home
                        </a>
                      </div>
                    </div>
                  </header> */}
                    <div className="modal-header">
                      <div className="row w-100 align-items-center information-sec">
                        <div className="col-12 col-xs-12 col-md-6 p-0 d-flex gap-3">
                       
                        {currentStep > 0 && (
                        <img
                              src="/img/beta-signup/back.svg"
                              className="back_step"
                              alt="back"
                              onClick={prevStep}
                            />        )} <h5
                            className="modal-title text-start"
                            id="exampleModalLabel"
                          >
                            {steps[currentStep].label}
                          </h5>
                        </div>
                        <div className="col-12 col-xs-12 col-md-6 px-0">
                          <p className="step-count stepcount-text text-md-end mb-0 ps-md-0">
                            Step {currentStep + 1}/{steps.length}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-body bottom-border py-4">
                      <CurrentStepComponent
                        handleChange={handleChange}
                        handleChangeInsure={handleChangeInsure}
                        telematicsData={telematicsData}
                        insuranceData={insuranceData}
                        setTelematicsData={setTelematicsData}
                        setInsuraceData={setInsuraceData}
                        commaVal={commaVal}
                        setCommaVal={setCommaVal}
                      />
                    </div>
                  </div>
                  <div className="modal-footer pt-0 px-0">
                    <div className="row w-100">
                      <div className="col-12 p-0">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${progress}%` }}
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container beta-footer">
                    <div className="row align-items-center w-100 pt-3 px-md-0">
                      {/* <div className="col-4 exit-button">
                        <button
                          type="button"
                          className="btn exitbtn"
                          onClick={() => setShowConfirmationModal(true)}
                        >
                          Exit
                        </button>
                      </div> */}
                      <div className="col-8 btn-continue text-end d-flex gap-2 align-items-center justify-content-end">
                      {currentStep > 0 && (
                          <button
                            type="button"
                            className="btn btn-outline-dark radius-5 backbtn "
                            onClick={prevStep}
                          >
                            <span className="">Skip</span>
                        
                          </button>
                          
                        )}

                        {currentStep > 0 && (
                        <button
                          type="button"
                          className="btn btn-outline-dark radius-5 back-arrow-btn  d-lg-none"
                          onClick={prevStep}
                        >
                          <img
                            src="/img/beta-signup/back-icon.svg"
                            className="img-fluid back-icon"
                            alt="Back"
                          />
                        </button>
                      )}

                        <div className="footer-btn-sec">
                        {/* <img
                              src="/img/beta-signup/back-icon.svg"
                              className="img-fluid close-modal d-lg-none d-block"
                              alt="back"
                            /> */}
                        <button
                          type="submit"
                          className="btn btn-dark continuebtn sendbtn send_btn radius-5 ms-0"
                          disabled={isSubmitting}

                        >
                          {currentStep === steps.length - 1 ? "Confirm And Send" : "Continue"}
                        </button>
                        </div>

                      </div>
                    </div>
                    </div>
                   
                  </div>

                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className={`modal save_and_exit_modal fade ${showConfirmationModal ? 'show' : ''}`} id="confirmationModal" tabIndex="-1" aria-labelledby="confirmationModalLabel" aria-hidden={!showConfirmationModal} style={{ display: showConfirmationModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header m-0">
                  <h2 className="modal-title">
                    Save and Exit
                  </h2>
            </div>
            <div className="modal-body m-0">
              <h3>Are you sure you want to exit?</h3>
              <img width="40px" height="40px" src="/img/yellow_info.svg" alt="image"/>
            </div>
            <div className="modal-footer py-4">
               <div className="modalFooterBtn">
                   <button type="button" className="btn btnTransparent" >Back to Registration</button>
                  
                  <button type="button" className="btn btnBlue" onClick={handleOpenmodal}>Save and Exit</button>
                  {showConfirmationModal1 && (
        <div
          className="modal save_and_exit_modal show d-block"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">Information Saved!</h2>
                {/* <button
                  type="button"
                  className="close"
                  onClick={() => setShowConfirmationModal1(false)}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </div>
              <div className="modal-body">
                <h3>You’ve successfully saved your informaiton</h3>
                <img width="40px" height="40px" src="/img/check_green.svg" alt="image"/>
                <p>You can come back to complete registration at any time.</p>
              </div>
              <div className="modal-footer"> 
                <button
                  type="button"
                  className="btn btnBlue w-100"
                  onClick={() => {
                    setShowConfirmationModal1(false);
                    setTimeout(() => {
                    },1000)
                  }}
                >
                  Exit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  );

};

export default HostForm;
