import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import logo from '../../assets/images/logo_new.png';
import arrow_down from "../../assets/images/arrow-down-01.png"
import red from "./assets/images/rent-shield-logo.svg";
import redalert from "../../assets/images/redhover.png";
import rend_id2 from "../../assets/images/rend_id2.png";
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import RentShieldAnimation from './RentshieldAnimation';
import Insurance from './Insurance/Insurance';
import PrimaryCommercial from './PrimaryCommercial/PrimaryCommercial';
import GuestProtection from './GuestProtection/GuestProtection';
import RoadsideAssistance from './RoadsideAssistance/RoadsideAssistance';
import DigitalClaims from './DigitalClaims/DigitalClaims';
import ClaimsProcessing from './ClaimsProcessing/ClaimsProcessing';
import ProtectionSettings from './ProtectionSettings/ProtectionSettings';
import InsuranceServicesFaq from './InsuranceServicesFaq/InsuranceServicesFaq';
import RentShieldInsurance from './RentShieldInsurance/RentShieldInsurance';
import MoreSolutions from '../Rentid2/MoreSolutions/MoreSolutions';
import PopularTopics from '../Rentid2/PopularTopics/PopularTopics';
import LiabilityInsurance from './Liabilityinsurance/LiabilityInsurance';
import PhysicalDamage from './PhysicalDamage/PhysicalDamage';
import Supplemental from './Supplemental/Supplemental';
import NationalRoadside from './NationalRoadside/NationalRoadside';
import RentHeader from '../RentHeader/RentHeader';
import BetaForm from '../BetaForm';
import Footer from '../Footer/Footer';

const RentShield2 = () => {

  const navigate = useNavigate();

const location = useLocation();
console.log(location.pathname);

  return (
    <>
    
    <Box
        sx={{
          background: 'linear-gradient(180deg, #082C72 0%, #001132 55%, #000000 100%)',
          height: '100vh',
          width: '100%',
        }}
      >
        <Container>
          
      <RentHeader/>


{/* Banner section start from here */}

        <section className='bannerShieldIdMain'>
            <div className="row">
                <div className='col-lg-5'>
                  <div  className='bannerShieldLeft'>
                      <img src={red} alt=""/>
                      <small>EMBEDDED INSURANCE AND PROTECTION PLANS</small>
                      <h1 className='desktopShieldHead'><span>Coverage, protection</span> & peace of mind for revenue generating assets</h1>
                      <h1 className='mobileShieldHead'>
                      <span>Coverage,</span> <span>protection</span> & peace of mind for revenue generating assets
                      </h1>
                      <p>Rent your fleet confidently while maintaining compliance, legality, and sustainable growth.</p>
                  </div>
                </div>

                <div className='col-lg-7'>
                    {/* <img src={redalert} alt=''/> */}
                    <RentShieldAnimation/>
                </div>
            </div>
        </section>
        
        </Container>
      </Box>
       {/* <LiabilityInsurance/>
      <PhysicalDamage/>
      <Supplemental/>
      <NationalRoadside/> */}
    
      {location.pathname === '/rentshield1' && (
          <>
           <Insurance/>
      <PrimaryCommercial/>
      <GuestProtection/>
      <RoadsideAssistance/>
      <DigitalClaims/>
      <ClaimsProcessing/>
      <ProtectionSettings/>
      <InsuranceServicesFaq/>
      <RentShieldInsurance/>
      <MoreSolutions/>
      <PopularTopics/> 
      <Footer/>
          </>
        )}
        <BetaForm/>
      </>
      
  )
}

export default RentShield2;
