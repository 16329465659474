import react from 'react';
import './AutoInsurance.css';

const AutoInsurance = ()=>{
    return(
        <>
        <section className='autoInsuranceMain' id='AutoInsuranceValidation'>
            <div className='container'>
            <div className='row justify-content-center'>
                <div class="col-md-12">
                  <div class="autoInsuranceHeading">
                    <figure>
                      <img class="img-fluid" width="48px" height="48px" src="/img/shield_energy_white.svg" alt="icon"/>
                   </figure>
                   <div class="autoInsuranceText">
                    <h2>Auto Insurance Validation</h2>
                    <p>Verify guest coverages instantly and gain insights needed to protect your rental fleet.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                  <div class="col-xl-3 col-lg-4">
                    <div class="autoInsuranceLeft">
                      <img class="img-fluid" src="/img/autoInsuranceLeft.png" alt="icon"/>
                    </div>
                  </div>


                  <div class="col-xl-9 col-lg-8">
                    <div class="autoInsuranceRight">
                      <div className='row'>
                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div class="autoInsuranceInner">
                                  <figure>
                                      <img class="img-fluid" width="36px" height="36px" src="/img/policy_status_verification.svg" alt="icon"/>
                                  </figure>
                                  <h3>Policy Status<br/> Verification</h3>
                                  <p>Instantly check status of a renter's insurance policy to confirm  validity.</p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div class="autoInsuranceInner">
                                  <figure>
                                      <img class="img-fluid" width="36px" height="36px" src="/img/RentID/umbrella.svg" alt="icon"/>
                                  </figure>
                                  <h3>Coverage Level <br/>Confirmation</h3>
                                  <p>Ensure that insurance coverage meets or exceeds rental requirements.</p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div class="autoInsuranceInner">
                                  <figure>
                                      <img class="img-fluid" width="36px" height="36px" src="/img/RentID/monitoring.svg" alt="icon"/>
                                  </figure>
                                  <h3>Expiration <br/>Monitoring</h3>
                                  <p>Track expiration dates to prevent rentals with outdated coverage.</p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div class="autoInsuranceInner">
                                  <figure>
                                      <img class="img-fluid" width="36px" height="36px" src="/img/RentID/edit_user.svg" alt="icon"/>
                                  </figure>
                                  <h3>Claims<br/> History</h3>
                                  <p>Review the renter’s insurance claims history to assess risk levels.</p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div class="autoInsuranceInner">
                                  <figure>
                                      <img class="img-fluid" width="36px" height="36px" src="/img/RentID/building.svg" alt="icon"/>
                                  </figure>
                                  <h3>Carrier<br/> Validation</h3>
                                  <p>Verify insurance provider is reputable and recognized within the industry.</p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                <div class="autoInsuranceInner">
                                  <figure>
                                      <img class="img-fluid" width="36px" height="36px" src="/img/RentID/coverage_adequacy.svg" alt="icon"/>
                                  </figure>
                                  <h3>Coverage<br/> Adequacy</h3>
                                  <p>Confirm that the insurance provides sufficient protection the rental.</p>
                                </div>
                            </div>

                      </div>
                    </div>
                  </div>



              </div>



            </div>
        </section>
        </>
    )
}

export default AutoInsurance;