import react from 'react';
import Insurancestyle from './PrimaryCommercial.css';
import { Link } from 'react-router-dom';

const PrimaryCommercial = ()=>{
    return(
        <>
        <section className='primaryCommercialMain' id='CommercialAutoInsurance'>
            <img className="primary_2" src="/img/rentshield/primary_2.svg" alt="img"/>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                        <div className='primaryCommercialhead'>
                            <img className="img-fluid" src="/img/rentshield/power_shield.png" alt="img"/>
                            <h2>Primary Commercial Auto Insurance<span> exclusively for rental vehicles</span></h2>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                        <div className='primaryCommercialInner'>
                            <Link to="/primary-commericial" class="btn pinkBtn">Learn More<img src="/img/rentshield/arrow_white.svg" alt="icon"/></Link>
                            <figure className="primary_img_desk">
                                <img className="img-fluid" src="/img/rentshield/primary_img.png" alt="img"/>
                            </figure>
                            <figure className="primary_img_mobile">
                                <img className="img-fluid" src="/img/rentshield/primary_img_mobile.png" alt="img"/>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default PrimaryCommercial;