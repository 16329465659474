import react from 'react';
import './VehicleRental.css';

const VehicleRental = ()=>{
    return(
        <>
        <section className='insuranceSecMain insuranceShieldMain'>  
            <div className='container'>
                <div className='row align-items-end'>
                    <div className='col-lg-5 col-md-12 col-sm-12 col-12'>
                    <div className='insuranceLeft'>
                        <h6>AUTOMATED RENTAL WORKFLOWS</h6>
                        <h3>All-in-One Vehicle Rental Management Platform</h3>
                        <p>Transform your rental business with a powerful platform that puts everything you need at your fingertips. 
                        Streamline operations, automate tedious tasks, and deliver exceptional customer experiences.</p>
                        <ul className='insurance-list'>
                            <li><img src="/img/calendar_icon_2.svg" alt="Smart Booking Management"/>Smart Booking Management</li>
                            <li><img src="/img/phone_icon_2.svg" alt="Branded Digital Experiences"/>Branded Digital Experiences</li>
                            <li><img src="/img/car_icon_2.svg" alt="Fleet Management Center"/>Fleet Management Center</li>
                            <li><img src="/img/user_icon_2.svg" alt="Customer Relationship Tools"/>Customer Relationship Tools</li>
                            <li><img src="/img/chart_icon_2.svg" alt="Financial Management"/>Financial Management</li>
                            <li><img src="/img/analytics_icon_2.svg" alt="Analytics & Reporting"/>Analytics & Reporting</li>
                        </ul>
                    </div>
                    </div>
                    <div className='col-lg-7 col-md-12 col-sm-12 col-12'>
                        <div className='insuranceRight'>
                            <div className='insuranceRightImage'>
                                <img class="img-fluid" src="/img/rentdirect/vehicle_Rentid.jpg" alt="insurance" />
                                <div className='auto-insurance-sec'>
                                    <div className='auto-insurance'>
                                        < img src="/img/featured-img1.svg" alt="icon" />    
                                            <div class='inner-text-sec'>
                                            <h5>Rental Operational Workflows</h5>
                                            <p>Automations that keep your business efficient.e</p>
                                            </div>
                                    </div>
                                    <div className='auto-insurance left-space-box'>
                                        <img src="/img/featured-img1.svg" alt="icon" />   
                                        <div class='inner-text-sec'>
                                            <h5>Built-In Administrative Tasks</h5>
                                            <p>Digital management of all paperwork and files.</p>
                                        </div>
                                    </div>
                                    <div className='auto-insurance left-space-box1'>
                                        <img src="/img/featured-img1.svg" alt="icon" />   
                                        <div class='inner-text-sec'>
                                            <h5>On Demand Logistical Solutions</h5>
                                            <p>Rental logistics tailored to your needs.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
            
                
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default VehicleRental;