import React, { useState } from 'react'
import './Support.css'
import { Link } from 'react-router-dom';
import ScrollingMarquee from './ScrollingMarquee';
function Support() {

  const [activeIndex, setActiveIndex] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };
  const openModal = () => {
    const modalElement = document.getElementById("exampleModal");
    const modalInstance = new window.bootstrap.Modal(modalElement);
    modalInstance.show();
  };
  const faqData = [
    {
      id: "Two",
      question: "What is rentid and what does it offer?",
      answer:
        "Rentid is a comprehensive vehicle rental platform offering robust software solutions for rental operators and their customers. It provides tools for automated driver vetting, secure payment processing, real-time vehicle tracking, and flexible insurance options. Rentid ensures a seamless and efficient rental experience for both individual car rental hosts and larger rental agencies, with a user-friendly interface and advanced technology.",
    },
    {
      id: "Three",
      question: "What are the requirements to host vehicles on rentid?",
      answer:
        "Host must have legal rights to rent a vehicle through ownership, power of attorney or co-hosting agreement. Host must be at least 18 years of age, provide a valid ID and be able to legally work in the US in order to use the platform. While we generally recommend having an EIN number for tax purposes you can utilize the platform as a sole proprietorship.",
    },
    {
      id: "Four",
      question: "Which regions is your software currently available in?",
      answer:
        "Rentid is currently only available in the US on a waitlist queued basis.",
    },
    {
      id: "Five",
      question: "Can I customize the software to match my business needs?",
      answer:
        "Yes. Most of our core software products allow you to toggle features and set prices according to your preferences as well as white label the website.",
    },
    {
      id: "Six",
      question: "How secure is my data when using your software?",
      answer:
        "Rentid implements a SOC-2 security blanket, which is well beyond minimum requirements, to keep you and your clients data safe.",
    },
    // {
    //   id: "Seven",
    //   question: "What fleet management capabilities does your software provide?",
    //   answer: "?",
    // },
    {
      id: "Eight",
      question:
        "Can your software help me with insurance services for my rental cars?",
      answer:
        "It can. Qualified vehicles can take advantage of insurance coverages for both On Rent and Off Rent activities. What’s more we can help you verify your guests auto insurance to cover a rental event and/or allow you to bring your own commercial renters policy if you prefer.",
    },
    {
      id: "Nine",
      question: "How does the built-in risk driver vetting product work?",
      answer:
        "Our system validates guest identification and driver’s licenses from national databases which then combines with our internal proprietary system that builds a risk profile for each guest renter which includes driving history on the platform, adherence to terms of service and more.",
    },
    {
      id: "Ten",
      question: "How does Rentid help protect me from high risk guests?",
      answer:
        "Anyone who fails their initial ID Verification will automatically be put on our platform wide Do Not Rent List. Anyone committing suspicious activity will be manually added to our platform wide Do Not Rent List. We may also disable a guest's access due to your feedback which we take very seriously to help protect your business. Appeals can be made by guests for reinstatement if the ID was simply expired or incorrectly uploaded.",
    },
    {
      id: "Eleven",
      question:
        "Can I set different pricing for different locations/days or vehicle types?",
      answer:
        "Pricing is manually set so you can maximize your profits around seasonal or holiday events however you prefer. And you keep 100% of your booking rate!",
    },
    {
      id: "Twelve",
      question:
        "Are there any restrictions on the types of vehicles that can be rented through the platform?",
      answer:
        "There are some restrictions based on vehicle type, age of the vehicle and total mileage on the vehicle’s odometer. Vehicles will go through an algorithmic, online underwriting process during onboarding that is almost instantaneous.",
    },
    {
      id: "Thirteen",
      question:
        "Does platform insurance coverage work everywhere? Can I cross state lines, or into Canada / Mexico?",
      answer:
        "Coverage is applicable for accidents and losses occurring within the U.S. and Canada only.",
    },
    {
      id: "Fourteen",
      question:
        "Can guests use platform-insured vehicles for rideshare or delivery work?",
      answer:
        "No. The current policies only allow and cover for personal use leisure driving. We are however looking to add additional coverage options in the future to accommodate gig workers.",
    },
    {
      id: "Fifteen",
      question:
        "If a guest verifies their personal insurance can I still add additional insurance?",
      answer:
        "Yes. Technically there is no legal limit to the amount of insurance you can layer onto a rental period. The guest’s personal auto policy will sit as the primary regardless.",
    },
    {
      id: "Sixteen",
      question: "How do I file a claim?",
      answer:
        "When utilizing embedded insurance from the platform you gain automatic access to our digital claims process which includes a First Notice of Loss (FNOL) hub which you can upload all pertinent information for review by the Third Party Adjuster (TPA).",
    },
    {
      id: "Saventeen",
      question:
        "What should I do if I encounter a technical issue with the software?",
      answer:
        'If refreshing the page does not solve the problem please reach out to customer support with a detailed explanation of the issue. You can email them any time of day at support@rentid.io',
    },
    {
      id: "Eighteen",
      question: "How can I sign up for the platform?",
      answer:
        "Join our waitlist and you’ll be called in order of registry to join our beta and be one of the first to enjoy the platform.",
    },
  ];

  // const [activeIndex, setActiveIndex] = useState(null);

  // // Function to toggle active accordion item
  // const toggleAccordion = (index) => {
  //   setActiveIndex(activeIndex === index ? null : index);
  // };

  return (
    <>
    <section class="start-for-free">
      <div class="container-fluid">
        <div class="col-md-12 main-heading-text1 text-center">
        <ScrollingMarquee/>
          {/* <h3>	  <marquee width="100%" behavior="scroll" direction="left" scrollamount="7">
    <span class="infinite-txt">It’s time to get rentid. Start for free.  Earn more than ever before. Take control of your business. </span>
<span class="infinite-txt">It’s time to get rentid. Start for free.  Earn more than ever before. Take control of your business. </span>
   <span class="infinite-txt">It’s time to get rentid. Start for free.  Earn more than ever before. Take control of your business. </span>
   <span class="infinite-txt">It’s time to get rentid. Start for free.  Earn more than ever before. Take control of your business. </span>
  <span class="infinite-txt">It’s time to get rentid. Start for free.  Earn more than ever before. Take control of your business. </span>
<div></div></marquee> </h3> */}
	
          <p>Join our  <span>beta test group</span> - theres only upside from here.</p>
          <p className="free-text"><img src="/img/time.svg" alt="" class="img-fluid" />Takes less than 10 minutes</p>
          </div>
          <div className="beta-user">
          <button  className="btn-getstarted1 d-lg-block1 openModal">Become a Beta User</button>
                      {/* <BetaForm/> */}
           {/* <a className='beta_pop' data-bs-toggle="modal"  data-bs-target="#exampleModal">Become a Beta User</a> */}
           {/* <Link to="/beta-signup">Become a beta user</Link> */}
          </div>
          </div>
          </section>
      <section id="support" className="">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12 col-md-12 text-center">
              <p className="">SUPPORT</p>
              <h2 className="mb-5">How can we help you</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10 faq p-4">
              <div className="row align-items-center">
                <div className="col-md-4 col-4">
                  <img src="/img/faq.svg" alt="" class="img-fluid" />
                </div>
                <div className="col-md-8 col-8">
                  <p className="d-flex align-items-center justify-content-end m-0"> 
                  <a href="javascript:void(0)" onClick={handleExpandClick} className='expandimg'>
                  <img
                    src={isExpanded ? "/img/collapse.svg" : "/img/expand-arrow.svg"}
                    alt=""
                    width="24"
                  />
                  {isExpanded ? 'Collapse' : 'Expand'}
                </a>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h3 className="pt-4 m-0">FAQ</h3>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="accordion" id="accordionExample">
                    {faqData.slice(0, isExpanded ? faqData.length : 2).map((faq, index) => (
                      <div className="accordion-item" key={faq.id}>
                        <h2 className="accordion-header" id={`heading${faq.id}`}>
                          <button
                            className="accordion-button"
                            type="button"
                            onClick={() => toggleAccordion(index)}
                            aria-expanded={activeIndex === index ? 'true' : 'false'}
                            aria-controls={`collapse${faq.id}`}
                          >
                            {faq.question}{' '}
                            <i className={`bi ${activeIndex === index ? 'bi-dash' : 'bi-plus'}`}></i>
                          </button>
                        </h2>
                        <div
                          id={`collapse${faq.id}`}
                          className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
                          aria-labelledby={`heading${faq.id}`}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {faq.answer}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="col-md-12 text-center pt-4">

                  <a href="javascript:void(0)" onClick={handleExpandClick} className='expandimgarrow'>
                  <img
                    src={isExpanded ? "/img/uparrow.svg" : "/img/downnarrow.svg"} 
                    alt=""
                    width="24"
                  />                 
                </a>

                   
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Support