import react from 'react';
import Accordion from 'react-bootstrap/Accordion';
import './OnRent.css';

const OnRent = ()=>{
    return(
        <>
<section className="onRentMain">
  <div className="container">
      <div class="row justify-content-center">
          <div class="col-md-10">
              <div class="headOnRent">
                  <h2><span>On-Rent coverage.</span> Only pay when the vehicle is rented.</h2>
              </div>
          </div>
      </div>
    <div className="onRentOuter">

          <ul class="nav nav-tabs">
              <li class="nav-item">
                  <a class="nav-link active" data-bs-toggle="tab" href="#onRent_1">Liability</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#onRent_2">Physical Damage</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#onRent_3">SLI Protection</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#onRent_4">Business Type</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#onRent_5">Primary Coverage</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#onRent_6">A-Rated</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#onRent_7">Billing</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#onRent_8">Fleet Size</a>
              </li>
          </ul>
    
          <div class="tab-content">
              {/* START Tab 1 */}
              <div class="tab-pane active" id="onRent_1">
                  <div className='row'>
                      <div className='col-lg-6 col-md-12 col-sm-12'>
                          <div className='onRentLeft'>
                              <figure>
                                <img class="img-fluid" src="/img/rentshield/onRent_1.jpg" alt="Liability"/>
                              </figure>
                          </div>
                      </div>
                      <div className='col-lg-6 col-md-12 col-sm-12'>
                          <div className='onRentRight'>
                              <h3>State Minimum <span>Liability Insurance</span></h3>
                              <Accordion defaultActiveKey="1">
                                  <Accordion.Item eventKey="0">
                                      <Accordion.Header>Automatically Adjusted State Coverage Limits </Accordion.Header>
                                      <Accordion.Body>
                                        RentShield dynamically adjusts coverage limits to meet each state's legal requirements, ensuring seamless compliance and 
                                        allowing you to operate confidently across <strong>all state lines.</strong>
                                      </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="1">
                                      <Accordion.Header>Third-Party Property Damage and Bodily Injury Liability</Accordion.Header>
                                      <Accordion.Body>
                                      Protects you against claims arising from damages or injuries to third parties caused by your rental vehicles -- ensuring 
                                      peace of mind and financial security.
                                      </Accordion.Body>
                                  </Accordion.Item>
                            </Accordion>
                          </div>
                      </div>
                  </div>
              </div>

              {/* START Tab 2 */}
              <div class="tab-pane fade" id="onRent_2">
                  <div className='row'>
                      <div className='col-lg-6 col-md-12 col-sm-12'>
                          <div className='onRentLeft'>
                              <figure>
                                <img class="img-fluid" src="/img/rentshield/onRent_2.jpg" alt="Physical Damage"/>
                              </figure>
                          </div>
                      </div>
                      <div className='col-lg-6 col-md-12 col-sm-12'>
                          <div className='onRentRight'>
                              <h3><span>Physical Damage</span> Protection up to $110k</h3>
                              <Accordion defaultActiveKey="0">
                                  <Accordion.Item eventKey="0">
                                      <Accordion.Header>Accidents & Collisions</Accordion.Header>
                                      <Accordion.Body>
                                        Coverage for damages from crashes with vehicles, overturns, rollovers, and impacts with stationary objects.
                                      </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="1">
                                      <Accordion.Header>Damage & Vandalism</Accordion.Header>
                                      <Accordion.Body>
                                      Security against theft, intentional damage, and issues like glass breakage.
                                      </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="2">
                                      <Accordion.Header>Road & Environmental Hazards</Accordion.Header>
                                      <Accordion.Body>
                                      Safeguards your vehicle from potholes, road barriers, animal collisions, and other unexpected road risks.
                                      </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="3">
                                      <Accordion.Header>Weather & Natural Events</Accordion.Header>
                                      <Accordion.Body>
                                      Protection against natural disasters like floods, hail, earthquakes, and severe weather, as well as falling debris and fires.
                                      </Accordion.Body>
                                  </Accordion.Item>
                            </Accordion>
                          </div>
                      </div>
                  </div>
              </div> 

              {/* START Tab 3 */}
              <div class="tab-pane fade" id="onRent_3">
                  <div className='row'>
                      <div className='col-lg-6 col-md-12 col-sm-12'>
                          <div className='onRentLeft'>
                              <figure>
                                <img class="img-fluid" src="/img/rentshield/onRent_3.jpg" alt="SLI Protection"/>
                              </figure>
                          </div>
                      </div>
                      <div className='col-lg-6 col-md-12 col-sm-12'>
                          <div className='onRentRight'>
                              <h3><span>Supplemental Liability</span> Insurance up to $1M</h3>
                              <ul>
                                <li>
                                  <img width="24px" height="24px" src="/img/rentshield/check_blue.svg" alt="icon"/>
                                  <h4>Comprehensive Third-Party Liability Protection</h4>
                                  <small>Adds an additional safety net beyond your primary insurance limits.</small>
                                </li>
                                <li>
                                  <img width="24px" height="24px" src="/img/rentshield/check_blue.svg" alt="icon"/>
                                  <h4>Coverage up to $1M Beyond Primary</h4>
                                  <small>Ensures substantial financial protection against large claims.</small>
                                </li>
                                <li>
                                  <img width="24px" height="24px" src="/img/rentshield/check_blue.svg" alt="icon"/>
                                  <h4>Universal Applicability Across Vehicle Classes</h4>
                                  <small>Covers bodily injury and property damage claims made by others.</small>
                                </li>
                                <li>
                                  <img width="24px" height="24px" src="/img/rentshield/check_blue.svg" alt="icon"/>
                                  <h4>Substantial Financial Safeguard for Large Claims</h4>
                                  <small>Applicable to economy cars, luxury vehicles, and everything in between.</small>
                                </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div> 

              {/* START Tab 4 */}
              <div class="tab-pane fade" id="onRent_4">
                  <div className='row'>
                      <div className='col-lg-6 col-md-12 col-sm-12'>
                          <div className='onRentLeft'>
                              <figure>
                                <img class="img-fluid" src="/img/rentshield/onRent_4.jpg" alt="Business Type"/>
                              </figure>
                          </div>
                      </div>
                      <div className='col-lg-6 col-md-12 col-sm-12'>
                          <div className='onRentRight'>
                              <h3>Coverage for all <span>Business Types</span></h3>
                              <ul>
                                <li>
                                  <img width="24px" height="24px" src="/img/rentshield/check_blue.svg" alt="icon"/>
                                  <h4>Car Rental Agencies</h4>
                                </li>
                                <li>
                                  <img width="24px" height="24px" src="/img/rentshield/check_blue.svg" alt="icon"/>
                                  <h4>Service & Repair / Auto body</h4>
                                </li>
                                <li>
                                  <img width="24px" height="24px" src="/img/rentshield/check_blue.svg" alt="icon"/>
                                  <h4>Car Sharing Marketplaces</h4>
                                </li>
                                <li>
                                  <img width="24px" height="24px" src="/img/rentshield/check_blue.svg" alt="icon"/>
                                  <h4>Auto Dealerships</h4>
                                </li>
                                <li>
                                  <img width="24px" height="24px" src="/img/rentshield/check_blue.svg" alt="icon"/>
                                  <h4>Hotels/Vacation Rental</h4>
                                </li>
                                <li>
                                  <img width="24px" height="24px" src="/img/rentshield/check_blue.svg" alt="icon"/>
                                  <h4>Property Management</h4>
                                </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div> 

              {/* START Tab 5 */}
              <div class="tab-pane fade" id="onRent_5">
                  <div className='row'>
                      <div className='col-lg-6 col-md-12 col-sm-12'>
                          <div className='onRentLeft'>
                              <figure>
                                <img class="img-fluid" src="/img/rentshield/onRent_5.jpg" alt="Primary Coverage"/>
                              </figure>
                          </div>
                      </div>
                      <div className='col-lg-6 col-md-12 col-sm-12'>
                          <div className='onRentRight'>
                            <h3><span>Primary Coverage</span> on Every Rental</h3>
                            <p>Primary coverage offers first-in-line protection, responding immediately to claims without relying on other policies. 
                            Enjoy faster resolutions and greater peace of mind for every rental, while minimizing potential out-of-pocket expenses.</p>
                          </div>
                      </div>
                  </div>
              </div> 

                {/* START Tab 6 */}
              <div class="tab-pane fade" id="onRent_6">
                  <div className='row'>
                      <div className='col-lg-6 col-md-12 col-sm-12'>
                          <div className='onRentLeft'>
                              <figure>
                                <img class="img-fluid" src="/img/rentshield/onRent_6.jpg" alt="A Rated"/>
                              </figure>
                          </div>
                      </div>
                      <div className='col-lg-6 col-md-12 col-sm-12'>
                          <div className='onRentRight'>
                              <h3>Highest <span>Quality Insurance</span> Standards</h3>
                              <ul>
                                <li>
                                  <img width="24px" height="24px" src="/img/rentshield/check_blue.svg" alt="icon"/>
                                  <h4>Financial Stability</h4>
                                  <small>A-rated insurers have the reserves to cover claims, even in large-scale or unexpected events.</small>
                                </li>
                                <li>
                                  <img width="24px" height="24px" src="/img/rentshield/check_blue.svg" alt="icon"/>
                                  <h4>Reliable Claims Processing</h4>
                                  <small>Dependable, timely payouts eliminate delays or disputes, ensuring you get the coverage you deserve.</small>
                                </li>
                                <li>
                                  <img width="24px" height="24px" src="/img/rentshield/check_blue.svg" alt="icon"/>
                                  <h4>Long-Term Security</h4>
                                  <small>High ratings reflect a proven track record, reducing the risk of insolvency and guaranteeing sustained protection.</small>
                                </li>
                                <li>
                                  <img width="24px" height="24px" src="/img/rentshield/check_blue.svg" alt="icon"/>
                                  <h4>Confidence and Compliance</h4>
                                  <small>Enjoy peace of mind knowing you're backed by trusted insurers who meet stringent business and government standards.</small>
                                </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div> 

                {/* START Tab 7 */}
              <div class="tab-pane fade" id="onRent_7">
                  <div className='row'>
                      <div className='col-lg-6 col-md-12 col-sm-12'>
                          <div className='onRentLeft'>
                              <figure>
                                <img class="img-fluid" src="/img/rentshield/onRent_7.jpg" alt="Billing"/>
                              </figure>
                          </div>
                      </div>
                      <div className='col-lg-6 col-md-12 col-sm-12'>
                          <div className='onRentRight'>
                              <h3>Billed <span>Per Day,</span> only when needed</h3>
                              <Accordion defaultActiveKey="0">
                                  <Accordion.Item eventKey="0">
                                      <Accordion.Header>Flexibility:</Accordion.Header>
                                      <Accordion.Body>
                                      Pay only for the exact days you need coverage, avoiding unnecessary costs for unused days.
                                      </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="1">
                                      <Accordion.Header>Cost-Effectiveness:</Accordion.Header>
                                      <Accordion.Body>
                                      Eliminates the risk of overpaying for services during slower periods or gaps in usage.
                                      </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="2">
                                      <Accordion.Header>Scalability:</Accordion.Header>
                                      <Accordion.Body>
                                      Pay only for the exact days you need coverage, avoiding unnecessary costs for unused days.
                                      </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="3">
                                      <Accordion.Header>Transparency:</Accordion.Header>
                                      <Accordion.Body>
                                      Clear, itemized daily charges help you track expenses more accurately and manage budgets effectively.
                                      </Accordion.Body>
                                  </Accordion.Item>
                            </Accordion>
                          </div>
                      </div>
                  </div>
              </div> 

              {/* START Tab 8 */}
              <div class="tab-pane fade" id="onRent_8">
                  <div className='row'>
                      <div className='col-lg-6 col-md-12 col-sm-12'>
                          <div className='onRentLeft'>
                              <figure>
                                <img class="img-fluid" src="/img/rentshield/onRent_7.jpg" alt="Fleet Size"/>
                              </figure>
                          </div>
                      </div>
                      <div className='col-lg-6 col-md-12 col-sm-12'>
                          <div className='onRentRight'>
                            <h3>Coverage for <span>all rental fleet sizes</span></h3>
                            <p>Our On-Rent Coverage protects rentals of any size, offering flexible, cost-effective protection only when in use. 
                            Enjoy peace of mind knowing your fleet is safeguarded during every rental trip.</p>
                          </div>
                      </div>
                  </div>
              </div> 
          </div>
 
    </div>
  </div>
</section>
        </>
    )
}

export default OnRent;