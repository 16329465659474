import react from 'react';
import React, { useEffect } from 'react';
import Slider from 'react-slick';  // Import the React Slick Slider
import './VerificationDashboard.css';
const PrevArrow = (props) => (
    <button {...props} className="slick-prev">
      <img src="/img/RentID/arrow_left_sky.svg" alt="Previous" width="48" height="48" />
    </button>
  );
  
  const NextArrow = (props) => (
    <button {...props} className="slick-next">
      <img src="/img/RentID/arrow_right_sky.svg" alt="Next" width="48" height="48" />
    </button>
  );

const VerificationDashboard = () => {

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 30,
    autoplay: true,
    autoplaySpeed: 2000,
    loop: true,
    dots: false,
    arrows: true,
    focusOnSelect: true,
    centerPadding: '30px',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3, // Correcting the key to 'slidesToShow'
          infinite: false,
          centerPadding: "30px",
          margin: 30,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2, // Correcting the key to 'slidesToShow'
          centerPadding: "30px",
          margin: 20,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1, // Correcting the key to 'slidesToShow'
          margin: 20,
        },
      },
    ],
  };

  return (
    <section className="veriDashMain" id='VerificationDashboard'>
      <img class="veriDash_1" src="/img/RentID/timeline_1.svg" alt="img"/>
      <div className="container">

        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div class="veriDashHeading justify-content-center align-items-center">
                    <figure><img class="img-fluid" width="48px" height="48px" src="/img/RentID/veriDash_icon.svg" alt="icon"/></figure>
                    <div class="commonText">
                        <h2>Verification Dashboard</h2>
                        <p>Centralized risk management for hosts and staff.</p>
                    </div>
                </div>
            </div>
        </div>


        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <Slider {...settings} className="veriDashDetailOuter"> {/* Use React Slick Slider */}
                <div class="item">
                    <div class="veriDashDetail veriDashDetail_1">
                        <span>01</span>
                        <h3>Real-Time Alerts</h3>
                        <p>Stay informed with instant notifications about critical verification events.</p>
                        <img class="img-fluid" src="/img/RentID/dash_1.png" alt="icon"/>
                    </div>
                </div> 
                <div class="item">
                    <div class="veriDashDetail veriDashDetail_2">
                        <span>02</span>
                        <h3>Status Tracking</h3>
                        <p>Keep track of the verification status for each renter in real-time.</p>
                        <img class="img-fluid" src="/img/RentID/dash_2.png" alt="icon"/>
                    </div>
                </div> 
                <div class="item">
                    <div class="veriDashDetail veriDashDetail_3">
                        <span>03</span>
                        <h3>Risk Indicators</h3>
                        <p>Visualize risk levels associated with each renter through clear indicators.</p>
                        <img class="img-fluid" src="/img/RentID/dash_3.png" alt="icon"/>
                    </div>
                </div>
                <div class="item">
                    <div class="veriDashDetail veriDashDetail_4">
                        <span>04</span>
                        <h3>Trend Monitoring</h3>
                        <p>Identify and respond to emerging patterns that may impact your business.</p>
                        <img class="img-fluid" src="/img/RentID/dash_4.png" alt="icon"/>
                    </div>
                </div> 
              <div class="item">
                    <div class="veriDashDetail veriDashDetail_5">
                        <span>05</span>
                        <h3>Analytics Reporting</h3>
                        <p>Generate insightful reports to understand verification trends and performance.</p>
                        <img class="img-fluid" src="/img/RentID/dash_5.png" alt="icon"/>
                    </div>
                </div>

                <div class="item">
                    <div class="veriDashDetail veriDashDetail_6">
                        <img class="img-fluid" src="/img/RentID/rent-id-logo-testi.png" alt="icon"/>
                        <h4>Manage rental risk like a pro</h4>
                        <p>Keep track of the verification status for each renter in real-time.</p>
                        <a class="btn" href="#">Get Started</a>
                    </div>
                </div>   
 
            </Slider> {/* End of React Slick Slider */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerificationDashboard;
