import react from 'react';
import './CustomerRelationship.css';

const CustomerRelationship = ()=>{
    return(
        <>
<section className="customerRelationshipMain">
    <img class="customerRelationship_1" src="/img/rentdirect/customerRelationship_1.svg" alt="img"/>
    <div className="container">
        <div class="row justify-content-center">
          <div class="col-lg-9">
              <div class="headcustomerRelationship">
                  <h2>Customer Relationship Tools</h2>
                  <p>Strengthen guest relationships with tools that simplify engagement, provide insights, and support every interaction.</p>
              </div>
          </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#customerRelationship_1">
                            <img width="22px" height="24px" src="/img/rentdirect/most_morden_shield_icon.svg" alt="icon"/>
                            <span>Review Management</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#customerRelationship_2">
                            <img width="22px" height="24px" src="/img/rentdirect/most_morden_shield_icon.svg" alt="icon"/>
                            <span>Comprenhensive Guest Profile</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#customerRelationship_3">
                            <img width="22px" height="24px" src="/img/rentdirect/most_morden_shield_icon.svg" alt="icon"/>
                            <span>Scheduled Messages</span>
                        </a>
                    </li>
                </ul>
            
                <div class="tab-content">
                    {/* START Tab 1 */}
                    <div class="tab-pane active" id="customerRelationship_1">
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                                <div className='customerRelationshipLeft'>
                                    <figure>
                                        <img class="img-fluid" src="/img/rentdirect/customerRelationship_1.png" alt="Customer Relationship"/>
                                    </figure>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                                <div className='customerRelationshipRight'>
                                    <img width="48px" height="48px" src="/img/rentdirect/white_star.svg" alt="star"/>
                                    <h3>Review Management</h3>
                                    <p>Foster trust and build better connections. Hosts and guests can leave reviews for each 
                                    other after every trip, sharing feedback about the experience. Your voice helps create 
                                    a stronger, more reliable community for everyone.</p>
                                    <ul>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Manage Reviews with Ease:</strong> Use your dashboard to view, respond to, and track feedback effortlessly.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Star Rating:</strong> Share your experience with a quick and simple star rating system.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Add a Personal Touch:</strong> Write personalized reviews to highlight great experiences or provide constructive feedback.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Earn Tips for Outstanding Service:</strong> Accept tips directly from guests to recognize your exceptional efforts.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* START Tab 2 */}
                    <div class="tab-pane fade" id="customerRelationship_2">
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                                <div className='customerRelationshipLeft'>
                                    <figure>
                                        <img class="img-fluid" src="/img/rentdirect/customerRelationship_2.png" alt="Customer Relationship"/>
                                    </figure>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                                <div className='customerRelationshipRight'>
                                    <img width="48px" height="48px" src="/img/rentdirect/white_user.svg" alt="star"/>
                                    <h3>Comprehensive Guest Profile</h3>
                                    <p>Make informed decisions with our comprehensive guest profiles that provide you with 
                                    the insights you need to confidently approve bookings, protect your vehicle, and 
                                    enhance the rental experience.</p>
                                    <ul>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>DriverIQ™ Score:</strong> Access a detailed risk assessment powered by AI to evaluate guests before approving bookings</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Verification &Validation:</strong> Verify guest information, identity and auto insurance in seconds, ensuring peace of mind.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Booking History:</strong> Review past rentals and guest reviews to understand their reliability and trustworthiness.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Actionable Insights:</strong> Leverage data-driven metrics for smarter decision-making and a more secure rental process.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> 

                    {/* START Tab 3 */}
                    <div class="tab-pane fade" id="customerRelationship_3">
                    <div className='row align-items-center'>
                            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                                <div className='customerRelationshipLeft'>
                                    <figure>
                                        <img class="img-fluid" src="/img/rentdirect/customerRelationship_3.png" alt="Customer Relationship"/>
                                    </figure>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                                <div className='customerRelationshipRight'>
                                    <img width="48px" height="48px" src="/img/rentdirect/scheduled_icon.svg" alt="star"/>
                                    <h3>Scheduled & Automated Messages</h3>
                                    <p>Enhance communication with Scheduled & Automated Messages, a powerful tool for managing
                                    guest interactions. Customize templates to send timely, personalized updates based on
                                    key rental events, ensuring a professional and smooth experience for your guests.</p>
                                    <ul>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Event-Based Messaging:</strong> Send automated updates for bookings, vehicle pick-ups, returns, and other key moments.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Customizable Templates:</strong> Create tailored messages that align with your brand and tone.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Personalized Touch:</strong> Add guest names and rental details for clear and professional communication.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Efficient Automation:</strong> Keep guests informed while saving time on repetitive tasks.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> 
                    {/* END Tab 3 */}
    

                </div>
            </div>
        </div>
    </div>
</section>
        </>
    )
}

export default CustomerRelationship;