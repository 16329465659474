import react from 'react';
import './DriverIq.css';

const DriverIq = ()=>{
    return(
        <>
        <section className='driverIqMain' id='DriverIQScore'>
          <img class="driverIq_bg" src="/img/RentID/driverIq_bg_2.jpg" alt="bg"/>
            <div className='container'>
            <div className='row justify-content-end'>
                <div class="col-xl-6 col-lg-7">
                  <div class="driverIqRight">
                    <figure>
                      <img class="img-fluid" width="48px" height="48px" src="/img/RentID/driver-iq.svg" alt="icon"/>
                   </figure>
                    <h2>DriverIQ™ Risk Scoring</h2>
                    <h3>Data-Driven Risk Assessment</h3>
                    <p>Make informed rental decisions with DriverIQ™, our proprietary risk scoring system tailored for car rental operators. 
                      Each rental generates actionable insights, 
                      enhanced by host community-sourced feedback and scoring to validate renters and assess risks with confidence.</p>
                      <img class="img-fluid driverIq_RightImage" src="/img/RentID/driverIq_Right_2.png" alt="image"/>
                  </div>
                </div>
              </div>

            </div>
        </section>
        </>
    )
}

export default DriverIq;