import react from 'react';
import Insurancestyle from './RoadsideAssistance.css';

const RoadsideAssistance = ()=>{
    return(
        <>
        <section className='roadsideAssistanceMain' id='NationalRoadsideAssistance'>
 
         <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-lg-9 col-md-12 col-sm-12 col-12'>
                    <div className='roadsideAssistanceHead'>
                        <h2>National Roadside Assistance <span>whenever, wherever...you’re covered.</span></h2>
                        <p>Enjoy peace of mind and support your renters with our 24/7 <span>Standard and Plus Roadside Assistance Plans.</span> 
                        From basic coverage to enhanced protection, choose the plan that fits your needs and keeps your vehicle and 
                        guests safe.</p>
                    </div>
                </div>
              </div>
              {/* start sec row */}
              <div className='row'>
                <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12'>
                    <div className='roadsideAssistanceTop'>
                    <div className='roadsideAssistanceInn'>
                            <div class="roadsideAssistanceInner">
                                <h3>Standard Plan</h3>
                                <h4>Plan includes:</h4>
                                <ul>
                                    <li>
                                        <img src="/img/tow_truck_icon.svg" alt="truck"/><span>10-mile towing to your chosen location</span>
                                    </li>
                                    <li>
                                        <img src="/img/battery_icon.svg" alt="battery"/><span>Battery jump-starts</span>
                                    </li>
                                    <li>
                                        <img src="/img/fuel_icon.svg" alt="Fuel"/><span>Fuel delivery service</span>
                                    </li>
                                    <li>
                                        <img src="/img/oil_icon.svg" alt="Oil Delivery"/><span>Emergency oil delivery</span>
                                    </li>
                                    <li>
                                        <img src="/img/tier_icon.svg" alt="Tire change"/><span>Tire change or replacement assistance</span>
                                    </li>
                                </ul>
                                <div class="roadsidePolicy">
                                    <a href="#">Read Policy Information </a>
                                </div>
                                <div class="roadsideBtn">
                                    <a href="#" class="btn">Select</a>
                                </div>
                            </div>
          
                            <div class="roadsideAssistanceInner roadsideAssistanceInner_2">
                                <h3>Standard Plan</h3>
                                <h4>Plan includes:</h4>
                                <ul>
                                    <li>
                                        <img src="/img/tow_truck_icon.svg" alt="truck"/><span>25-mile towing to your chosen location</span>
                                    </li>
                                    <li>
                                        <img src="/img/battery_icon.svg" alt="battery"/><span>Battery jump-starts</span>
                                    </li>
                                    <li>
                                        <img src="/img/fuel_icon.svg" alt="Fuel"/><span>Fuel delivery service</span>
                                    </li>
                                    <li>
                                        <img src="/img/oil_icon.svg" alt="Oil Delivery"/><span>Emergency oil delivery</span>
                                    </li>
                                    <li>
                                        <img src="/img/tier_icon.svg" alt="Tire change"/><span>Tire change or replacement assistance</span>
                                    </li>
                                </ul>
                                <div class="roadsidePolicy">
                                    <a href="#">Read Policy Information </a>
                                </div>
                                <div class="roadsideBtn">
                                    <a href="#" class="btn btnGradient">Added!</a>
                                </div>
                            </div>
                    </div>
                    </div>
           
                </div>
                <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12'>
                   
                        <div className='roadsideAssistanceRightTop'>
                            <p>Easily add on or remove our embedded comprehensive roadside assistance program. Available anytime and anywhere in the United States.</p> 
                        </div>
                    
                        <div class="roadsideAssistanceFlex">
                        <div class="roadsideAssistanceGrid">
                            <div class="roadsideAssistanceRight">
                                <img class="img-fluid" width="32px" height="32px" src="/img/tow_truck_green.svg" alt="icon"/>
                                <h3>Emergency Towing<br/> Services</h3>
                            </div>
      
                            <div class="roadsideAssistanceRight">
                                <img class="img-fluid" width="32px" height="32px" src="/img/battery_green.svg" alt="icon"/>
                                <h3>Battery Jump-<br/>Start Service</h3>
                            </div>
        
                            <div class="roadsideAssistanceRight">
                                <img class="img-fluid" width="32px" height="32px" src="/img/tire_green.svg" alt="icon"/>
                                <h3>Flat Tire<br/> Changes</h3>
                            </div>
   
                            <div class="roadsideAssistanceRight">
                                <img class="img-fluid" width="32px" height="32px" src="/img/fuel_green.svg" alt="icon"/>
                                <h3>Fuel<br/> Delivery</h3>
                            </div>
    
                            <div class="roadsideAssistanceRight">
                                <img class="img-fluid" width="32px" height="32px" src="/img/key_green.svg" alt="icon"/>
                                <h3>Lockout<br/> Assistance</h3>
                            </div>
  
                            <div class="roadsideAssistanceRight">
                                <img class="img-fluid" width="32px" height="32px" src="/img/emergency_green.svg" alt="icon"/>
                                <h3>Emergency<br/> Extraction</h3>
                            </div>
                        </div>
                        </div>
            

                </div>
            </div>
            {/* end sec row */}

            <div className='row justify-content-center'>
                <div className='col-lg-9 col-md-12 col-sm-12 col-12'>
                    <div className='roadsideAssistanceBottom'>
                        <p><span>roadside assistance</span> is <span>included</span> for rentid <span>monthly subscribers.</span></p>
                        <a href="#" class="btn">Learn More <img src="/img/rentshield/arrow_white.svg" alt="icon"/></a>
                    </div>
                </div>
              </div>

         </div>
        </section>
        </>
    )
}

export default RoadsideAssistance;