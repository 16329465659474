import react from 'react';
import './PopularTopics.css';

const PopularTopics = ()=>{
    return(
        <>
        <section className='popularTopicsMain'>
            <div className='container'>

                <div class="row">
                    <div class="col-lg-6 col-md-8 col-sm-8">
                        <div class="popularTopicsHeading">
                            <h2>Learn More Topics</h2>
                            <p>Gain deeper insights and</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-4 col-sm-4">
                        <div class="popularTopicsBtn">
                            <a class="btn" href="#">Discover more</a>
                        </div>
                    </div>
                </div>

               {/* row start */}
               <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <div class="popularTopicsInner">
                            <figure>
                                <img class="img-fluid" src="/img/blog_1.jpg" alt="blog"/>
                            </figure>
                            <div class="popularData">
                                <h3>Digital Vehicle Inspections</h3>
                                <p>Monitor key performance indicators, track customer trends...</p>
                                <a href="#" class="btn">
                                    <span>Read more</span>
                                    <img src="/img/arrow-right-small.svg" alt="icon"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <div class="popularTopicsInner">
                            <figure>
                                <img class="img-fluid" src="/img/blog_1.jpg" alt="blog"/>
                            </figure>
                            <div class="popularData">
                                <h3>Digital Vehicle Inspections</h3>
                                <p>Monitor key performance indicators, track customer trends...</p>
                                <a href="#" class="btn">
                                    <span>Read more</span>
                                    <img src="/img/arrow-right-small.svg" alt="icon"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div class="popularTopicsInner">
                            <figure>
                                <img class="img-fluid" src="/img/blog_1.jpg" alt="blog"/>
                            </figure>
                            <div class="popularData">
                                <h3>Digital Vehicle Inspections</h3>
                                <p>Monitor key performance indicators, track customer trends...</p>
                                <a href="#" class="btn">
                                    <span>Read more</span>
                                    <img src="/img/arrow-right-small.svg" alt="icon"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                 {/* row end */}

            </div>
        </section>
        </>
    )
}

export default PopularTopics;