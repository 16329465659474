import Breadcrumb from 'react-bootstrap/Breadcrumb';
import './BreadcrumbCustom.css';

function BreadcrumbCustom() {
  return (
    <div className="breadcrumbOuter">
    <Breadcrumb>
      <Breadcrumb.Item href="#">Sign up</Breadcrumb.Item>
      {/* <Breadcrumb.Item href="#">
        Library
      </Breadcrumb.Item> */}
      <Breadcrumb.Item active>Welcome</Breadcrumb.Item>
    </Breadcrumb>
    </div>
  );
}

export default BreadcrumbCustom;