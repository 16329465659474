import react from 'react';
import Insurancestyle from './DigitalClaims.css';

const DigitalClaims = ()=>{
    return(
        <>
        <section className='DigitalClaimsMain' id='DigitalClaimsManagement'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-lg-9 col-md-12 col-sm-12 col-12'>
                        <div className='DigitalClaimsHead'>
                            <h2>Digital <span>Claims Management</span></h2>
                            <img src="/img/rentshield/power_success.png" alt="img"/>
                        </div>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                        <div className='DigitalClaimsInner'>
                            <img src="/img/rentshield/DigitalClaimsInner.png" alt="Digital Claims"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default DigitalClaims;