import react from 'react';
import './Safety.css';

const Safety = ()=>{
    return(
        <>
        <section className='safetyMain'>
            <div className='container'>
            <div className='row justify-content-center'>
                <div class="col-xl-7 col-lg-12 col-md-12">
                <div class="safetyInner">
                    <h2>Every aspect of trust, safety & risk all in one place</h2>
                    <p>experience how advanced verification and risk tools can enhance your rental operation.</p>
                      <div class="safetyBtn">
                           <a class="btn white" href="#">Contact Sales</a>
                           <a class="btn btnGradient" href="#">Get Started <img src="/img/arrow_spl.svg" alt="icon"/></a>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        </>
    )
}

export default Safety;