import react from 'react';
import Insurancestyle from './ProtectionSettings.css';

const ProtectionSettings = ()=>{
    return(
        <>
        <section className='protectionSettingsMain' id='CoverageSettings'>
        <img className="primary_3" src="/img/rentshield/primary_3.png" alt="img"/>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-lg-7 col-md-12 col-sm-12 col-12'>
                        <div className='protectionSettingshead'>
                            <h2>Customize every aspect of your <span> coverage & protection settings</span></h2>
                            <p>Easily adjust settings to define coverage responsibilities, manage protection fees, 
                            and decide actions for declined protection plans, giving you full control to tailor 
                            operations and protection settings seamlessly to your business needs and preferences.</p>
                        </div>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                        <div className='protectionSettingsInner'>
                            <img className="img-fluid" src="/img/rentshield/primary_img_2.png" alt="img"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default ProtectionSettings;