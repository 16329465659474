import react from 'react';
import Insurancestyle from './Insurancestyle.css';
import './Insurancestyle.css' 

const Insurance = ()=>{
    return(
        <>
        <section className='insuranceSecMain' id='insurancesec'>  
        <img class="primary_1" src="/img/rentshield/primary_1.svg" alt="img"/>
         <div className='container'>
              <div className='row align-items-end'>
                <div className='col-lg-5 col-md-12 col-sm-12 col-12'>
                 <div className='insuranceLeft'>
                     <h6>COMMERCIAL AUTO INSURANCE & PROTECTION</h6>
                     <h3>Dynamic On-Rent Coverage & Protection Plans for Rental Fleets</h3>
                     <p>Transform your rental operation with embedded on-rent daily coverage designed specifically for car rental fleets. 
                      Flexible, A-rated insurance solutions that keep your vehicles protected and your business compliant.</p>
                     <ul className='insurance-list'>
                        <li>
                          <a href="#CommercialAutoInsurance">
                            <img width="24px" height="24px"src="/img/commercial_icon.svg" alt="commercial_icon" />
                            <span>Commercial Auto Insurance</span>
                          </a>
                        </li>
                        <li>
                          <a href="#GuestProtectionPlans">
                            <img width="24px" height="24px"src="/img/guest_icon.svg" alt="car-shield" />
                            <span>Guest Protection Plans</span>
                          </a>
                        </li>

                        <li>
                          <a href="#NationalRoadsideAssistance">
                            <img width="24px" height="24px"src="/img/tow_truck_icon.svg" alt="user" />
                            <span>National Roadside Assistance</span>
                          </a>
                        </li>

                        <li>
                          <a href="#DigitalClaimsManagement">
                            <img width="24px" height="24px"src="/img/claims_management_icon.svg" alt="tow truck" />
                            <span>Digital Claims Management</span>
                          </a>
                        </li>
                        <li>
                          <a href="#CoverageSettings">
                            <img width="24px" height="24px"src="/img/settings_icon.svg" alt="web protection" />
                            <span>Coverage Settings</span>
                          </a>
                        </li>
                        <li>
                          <a href="#InsuranceServicesFAQ">
                            <img width="24px" height="24px"src="/img/book_open_icon.svg" alt="digital" />
                            <span>Insurance Services FAQ</span>
                          </a>
                        </li>

                     </ul>
                 </div>
                </div>
                <div className='col-lg-7 col-md-12 col-sm-12 col-12'>
                <div className='insuranceRight'>
                  <div className='insuranceRightImage'>
                      <img class="img-fluid" src="/img/insurance-img.png" alt="insurance" />
                      <div className='auto-insurance-sec'>
                          <div className='auto-insurance'>
                            < img src="/img/featured-img1.svg" alt="liablity" />    
                                <div class='inner-text-sec'>
                                  <h5>A-Rated Auto Insurance</h5>
                                  <p>Industry leading commercial auto insurance</p>
                                </div>
                          </div>
                          <div className='auto-insurance left-space-box'>
                              <img src="/img/featured-img1.svg" alt="liablity" />   
                              <div class='inner-text-sec'>
                                <h5>On Demand Coverages</h5>
                                <p>Choose from four types of coverage plans</p>
                              </div>
                          </div>
                          <div className='auto-insurance left-space-box1'>
                              <img src="/img/featured-img1.svg" alt="liablity" />   
                              <div class='inner-text-sec'>
                                <h5>Automated Peace of Mind</h5>
                                <p>Manually or auto accept based on coverages</p>
                              </div>
                          </div>
                      </div>
                    </div>
                    </div>
                   
           
            
                </div>
              </div>
         </div>
        </section>
        </>
    )
}

export default Insurance;