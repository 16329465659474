import react from 'react';
import './HeaderHostsignup.css';
import BreadcrumbCustom from '../BreadcrumbCustom/BreadcrumbCustom';

const HeaderHostsignup = () => {
    return(
        <>
        <header className="headerSignup">
            <div className="container">
                <div className="headerSignupInner">
                    <div class="logoSignup">
                        <img width="40px" height="40px" src="/img/logo_small_r.svg" alt="logo"/>
                    </div>
                    <BreadcrumbCustom/>
                </div>
            </div>
         </header>
        </>
    )
}

export default HeaderHostsignup;