import react from 'react';
import './DecisionSupport.css'

const DecisionSupport = ()=> {
    return(
        <>

<section className="timelineMain" id='RiskManagement'>
<img class="timeline_2" src="/img/RentID/timeline_2.png" alt="img"/>
<div className="container">
<div className="timelineOuter">
        <div className="row justify-content-center align-items-center">
            <div className="col-xl-9 col-lg-11 col-md-12 col-sm-12">
                <div class="timeHeading justify-content-center align-items-start">
                    <figure><img class="img-fluid" width="48px" height="48px" src="/img/RentID/setting_icon.svg" alt="icon"/></figure>
                    <div class="commonText">
                        <h2>Risk Management & Decision Support</h2>
                        <p>Empower your business to make confident rental decisions and approvals with our integrated risk management and support tools.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="timeline">
            <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="timelineLeft">
                            <div class="timelineInner">
                                <figure><img class="img-fluid" src="/img/RentID/time_1.jpg" alt="img"/></figure>
                                <div class="timelineInnerData">
                                    <span>01</span>
                                    <h3>Risk Notifications</h3>
                                    <p>Receive real-time alerts when a potential renter matches criteria on the DNR List.</p>
                                </div>
                            </div>
                            <div class="timelineInner">
                                <figure><img class="img-fluid" src="/img/RentID/time_3.jpg" alt="img"/></figure>
                                <div class="timelineInnerData">
                                    <span>03</span>
                                    <h3>Custom Rule Settings</h3>
                                    <p>Define and adjust rules to align with your operational policies and risk tolerance.</p>
                                </div>
                            </div>

                            <div class="timelineInner">
                            <figure><img class="img-fluid" src="/img/RentID/time_5.jpg" alt="img"/></figure>
                            <div class="timelineInnerData">
                                <span>05</span>
                                <h3>Decision Logging</h3>
                                <p>Keep a detailed record of all rental decisions for accountability and auditing purposes.</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="timelineRight">
                    <div class="timelineInner">
                                <figure><img class="img-fluid" src="/img/RentID/time_2.jpg" alt="img"/></figure>
                                <div class="timelineInnerData">
                                    <span>02</span>
                                    <h3>Automated Recommendations</h3>
                                    <p>Receive system-generated suggestions based on comprehensive data analysis.</p>
                                </div>
                            </div>
                        <div class="timelineInner">
                            <figure><img class="img-fluid" src="/img/RentID/time_4.jpg" alt="img"/></figure>
                            <div class="timelineInnerData">
                                <span>04</span>
                                <h3>Override Options</h3>
                                <p>Allow authorized personnel to override automated decisions when necessary.</p>
                            </div>
                        </div>


                    </div>
                    </div>
            </div>
        </div>
</div>
</div>
</section>
 
        </>
    )
}

export default DecisionSupport;