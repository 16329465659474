import React, { useState, useEffect } from 'react';
import './Header.css';
import rentid from '../../assets/images/green3.png';
import red2 from '../../assets/images/red2.png';
import blue3 from '../../assets/images/blue3.png';
import purple2 from '../../assets/images/purple2.png';
import yellow3 from '../../assets/images/yellow3.png';
import BetaForm from '../BetaForm';
import throttle from 'lodash.throttle';
import guestimg from '../../assets/images/user-2.svg';
import hostimg from '../../assets/images/briefcase.svg';
import { Link } from 'react-router-dom';
const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProductsSubMenuOpen, setIsProductsSubMenuOpen] = useState(false);
  const [isProtectionSubMenuOpen, setIsProtectionSubMenuOpen] = useState(false);
  const [isSolutionsSubMenuOpen, setIsSolutionsSubMenuOpen] = useState(false);
  const [isResourcesSubMenuOpen, setIsResourcesSubMenuOpen] = useState(false);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollPos = window.scrollY;
  //     const isScrolled = currentScrollPos > 0;
  //     setScrolled(isScrolled);

  //     if (prevScrollPos > currentScrollPos) {
  //       setShowHeader(true);
  //     } else {
  //       setShowHeader(false);
  //     }

  //     setPrevScrollPos(currentScrollPos);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [prevScrollPos]);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const currentScrollPos = window.pageYOffset || document.documentElement.scrollTop;
      const isScrolled = currentScrollPos > 10;
      setScrolled(isScrolled);

      if (currentScrollPos <= 10) {
        // Ensure navbar remains visible near the top
        setShowHeader(true);
      } else if (prevScrollPos > currentScrollPos) {
        // Scrolling up
        setShowHeader(true);
      } else {
        // Scrolling down
        setShowHeader(false);
      }

      setPrevScrollPos(currentScrollPos);
    }, 100); // Throttle execution every 100ms for performance

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);


  const additionalClass = scrolled ? 'scrolled' : '';
  const visibilityClass = showHeader ? 'visible' : 'hidden';


  return (
    <div className={`${additionalClass} ${visibilityClass}`}>
      <header id="header" className={`header d-flex align-items-center fixed-top`}>
        <div className="container position-relative d-flex align-items-center justify-content-between px-0">
          <div className="col-md-7 mainmenu d-flex align-items-center">
            <a href="/" className="logo d-flex align-items-center me-auto me-xl-0">
              <h1 className="sitename">
                <img src="/img/Rentid-white.svg" alt="desktop logo" className="desktop-logo" />
                <img src="/img/mobile-logo.svg" alt="mobile logo" className="mobile-logo" />
              </h1>
            </a>
            <div className="mainmenu d-none d-lg-flex align-items-center">
          <div className="nav-item-with-dropdown">
            <a href="/" className="nav-link-custom d-none">Products</a>
            <div className="mega-menu">
              <a href="/rentdirect" className="dropdown-item1">RentDirect</a>
              <a href="/rentid" className="dropdown-item1">RentID</a>
              <a href="/rentshield" className="dropdown-item1">RentShield</a>
              <a href="/rentsuccess" className="dropdown-item1">RentSuccess</a>
              <a href="/rentacademy" className="dropdown-item1">RentAcademy</a>
            </div>
          </div>

        <div className="nav-item-with-dropdown1 d-none">
          <a href="/" className="nav-link-custom">Protection</a>
          <div className="mega-menu1">
            <a href="#" className="dropdown-item1">Liability Insurance</a>
            <a href="#" className="dropdown-item1">Physical Damage</a>
            <a href="#" className="dropdown-item1">Roadside Assistance</a>
            <a href="#" className="dropdown-item1">Claims Management</a>
            <a href="#" className="dropdown-item1">Rentshield 360</a>
          </div>
        </div>

        <div className="nav-item-with-dropdown2 d-none">
          <a href="/" className="nav-link-custom">Solutions</a>
          <div className="mega-menu2">
            <a href="#" className="dropdown-item1">Car Rental</a>
            <a href="#" className="dropdown-item1">Marketplaces</a>
            <a href="#" className="dropdown-item1">Service/Repair</a>
            <a href="#" className="dropdown-item1">Dealerships</a>
            <a href="#" className="dropdown-item1">Hospitality</a>
          </div>
        </div>

        <div className="nav-item-with-dropdown3 d-none">
          <a href="/" className="nav-link-custom">Resources</a>
          <div className="mega-menu3">
            <a href="#" className="dropdown-item1">Articles</a>
            <a href="#" className="dropdown-item1">How-To Guides</a>
            <a href="#" className="dropdown-item1">Comparisons</a>
            <a href="#" className="dropdown-item1">Videos</a>
            <a href="#" className="dropdown-item1">FAQs</a>
          </div>
        </div>

        <div className="pricing d-none">
          <a href="/" className="nav-link-custom">Pricing</a>
        </div>
        
      </div>
          </div>


      

      <div className="hamburger-icon d-lg-none d-flex align-items-center">
        <i className={`bi ${isMenuOpen ? 'bi-x' : 'bi-list'}`} onClick={() => setIsMenuOpen(!isMenuOpen)} ></i>
      </div>

      <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
      <div className="menu-item">
          <a  className="nav-link-custom">
            Products
            <i
              className={`bi ${isProductsSubMenuOpen ? 'bi-chevron-up' : 'bi-chevron-down'}`}
              onClick={() => setIsProductsSubMenuOpen(!isProductsSubMenuOpen)}
            ></i>
          </a>

          {/* Submenu for Products */}
          {isProductsSubMenuOpen && (
            <div className="submenu">
              <a href="/rentdirect" className="dropdown-item1"><img height='30px' src={rentid}/>RentDirect</a>
              <a href="/rentid" className="dropdown-item1"><img height='30px' src={blue3}/>RentID</a>
              <a href="/rentshield" className="dropdown-item1"><img height='30px' src={red2}/>RentShield</a>
              <a href="/rentsuccess" className="dropdown-item1"> <img height='30px' src={purple2}/>RentSuccess</a>
              <a href="/rentacademy" className="dropdown-item1"> <img height='30px' src={yellow3}/>RentAcademy</a>
            </div>



            
          )}
        </div>
      

        {/* Protection Section */}
  <div className="menu-item ham_menu">
    <a className="nav-link-custom">
      Protection
      <i
        className={`bi ${isProtectionSubMenuOpen ? 'bi-chevron-up' : 'bi-chevron-down'}`}
        onClick={() => setIsProtectionSubMenuOpen(!isProtectionSubMenuOpen)}
      ></i>
    </a>

    {/* Submenu for Protection */}
    {isProtectionSubMenuOpen && (
      <div className="submenu">
        <a href="#" className="dropdown-item1">Liability Insurance</a>
        <a href="#" className="dropdown-item1">Physical Damage</a>
        <a href="#" className="dropdown-item1">Roadside Assistance</a>
        <a href="#" className="dropdown-item1">Claims Management</a>
        <a href="#" className="dropdown-item1">Rentshield 360</a>
      </div>
    )}
  </div>
        {/* Solutions Section */}
  <div className="menu-item ham_menu">
    <a className="nav-link-custom">
      Solutions
      <i
        className={`bi ${isSolutionsSubMenuOpen ? 'bi-chevron-up' : 'bi-chevron-down'}`}
        onClick={() => setIsSolutionsSubMenuOpen(!isSolutionsSubMenuOpen)}
      ></i>
    </a>

    {/* Submenu for Solutions */}
    {isSolutionsSubMenuOpen && (
      <div className="submenu">
        <a href="#" className="dropdown-item1">Car Rental</a>
        <a href="#" className="dropdown-item1">Marketplaces</a>
        <a href="#" className="dropdown-item1">Service/Repair</a>
        <a href="#" className="dropdown-item1">Dealerships</a>
        <a href="#" className="dropdown-item1">Hospitality</a>
      </div>
    )}
  </div>

  
  {/* Resources Section */}
  <div className="menu-item ham_menu">
    <a className="nav-link-custom">
      Resources
      <i
        className={`bi ${isResourcesSubMenuOpen ? 'bi-chevron-up' : 'bi-chevron-down'}`}
        onClick={() => setIsResourcesSubMenuOpen(!isResourcesSubMenuOpen)}
      ></i>
    </a>

    {/* Submenu for Resources */}
    {isResourcesSubMenuOpen && (
      <div className="submenu">
        <a href="#" className="dropdown-item1">Articles</a>
        <a href="#" className="dropdown-item1">How-To Guides</a>
        <a href="#" className="dropdown-item1">Comparisons</a>
        <a href="#" className="dropdown-item1">Videos</a>
        <a href="#" className="dropdown-item1">FAQs</a>
      </div>
    )}
  </div>
  
        <a href="/" className="nav-link-custom pricing_menu">Pricing</a>
          <div className='mobile_navigation_btn'>
              {/* <button className="btn-login w-100 mb-2">Log In</button> */}

                <div class="dropdown">
                    <a className="loginbtn d-lg-block dropdown-toggle" id="dropdownMenu2" data-bs-toggle="dropdown" target="_blank" href="#">
                    <span>Login</span> <img src="/img/arrow-down.svg" alt="mobile logo" /></a>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <li><Link to='/guest' ><button class="dropdown-item" type="button"> <img src={guestimg} alt="mobile logo" className=""  width={24}/>Login as Guest</button></Link></li>
                        <li> <Link to='/host'><button class="dropdown-item" type="button"><img src={hostimg} alt="mobile logo" className=""  width={24}/>Login as Host</button></Link></li>
                    </ul>
                </div>

              <button  data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn-beta w-100">Apply For Beta</button>
          </div>

      </div>


          <div className="col-md-5 rightbtn d-flex align-items-center justify-content-end d-xs-none ">
            <a className="d-lg-none d-md-none d-block " target="_blank" href="https://getrentid.io/marketing/login">
              <img src="/img/login-btn.svg" alt="mobile logo" className="mobile-logo"  width={34}/>
            </a>
            {/* <a className="loginbtn d-lg-block d-none" target="_blank" href="#">
              Login  <img src="/img/arrow-down.svg" alt="mobile logo" />
            </a> */}
            <div class="dropdown">
            <a className="loginbtn d-lg-block d-none dropdown-toggle" id="dropdownMenu2" data-bs-toggle="dropdown" target="_blank" href="#">
              Login  <img src="/img/arrow-down.svg" alt="mobile logo" />
            </a>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
    <li><Link to='/guest' ><button class="dropdown-item" type="button"> <img src={guestimg} alt="mobile logo" className=""  width={24}/>Login as Guest</button></Link></li>
    <li> <Link to='/host'><button class="dropdown-item" type="button"><img src={hostimg} alt="mobile logo" className=""  width={24}/>Login as Host</button></Link></li>
  
  </ul>
</div>
            {/* <a className="btn-getstarted d-lg-block" href="/beta-signup">
              Apply For Beta
            </a> */}
            <button  data-bs-toggle="modal"
                    data-bs-target="#exampleModal"  className="btn-getstarted d-lg-block">apply for beta</button>
                   
            <i className="mobile-nav-toggle d-none bi bi-list"></i>
          </div>
       
         
        </div>
      </header>

      <BetaForm/>
      
    </div>
  );
};

export default Header;
