import react from 'react';


const Confirmation = () => {
    return (
        <>
            <div className="commonHostMain">
                <div className="commonHostOuter">
                    <div className="confirmationResetInner">
                        <img width="72px" height="72px"src="/img/check_green.svg" alt="check"/>
                        <h3>Password Reset Successful!</h3>
                        <p>Your password has been successfully updated. You can now log in with your new password.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Confirmation;