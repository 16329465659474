import React, { useContext, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { validationSchemas } from "./validationSchemas";
import GeneralInfo from "./GeneralInfo";
import BusinessState from "./BusinessState";
import RentalCompanies from "./RentalCompanies";
import RentalBusiness from "./RentalBusiness";
import TelematicsDevices from "./TelematicsDevices";
import IdVerification from "./IdVerification";
import AutoInsurance from "./AutoInsurance";
import MarketingServices from "./MarketingServices";
import InsuranceProvider from "./InsuranceProvider";
import InsuranceOptions from "./InsuranceOptions";
import DeliveryDrivers from "./ DeliveryDrivers"
// import RemoteVehicle from "./RemoteVehicle";
import { API_ENDPOINT } from "../../Constant/Constant";
import qs from "qs";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/FormDataContext";
import { Link } from "react-router-dom";
const steps = [
  {
    component: GeneralInfo,
    validationSchema: validationSchemas[0],
    label: "General Information",
  },
  {
    component: BusinessState,
    validationSchema: validationSchemas[1],
    label: "Legal Identity  ",
  },
  {
    component: RentalCompanies,
    validationSchema: validationSchemas[2],
    label: "Compliance",
  },
  {
    component: RentalBusiness,
    validationSchema: validationSchemas[3],
    label: "Rental Management ",
  },
  {
    component: TelematicsDevices,
    validationSchema: validationSchemas[4],
    label: "Tracking Devices",
  },
  {
    component: IdVerification,
    validationSchema: validationSchemas[5],
    label: "Trust and Safety",
  },
  {
    component: AutoInsurance,
    validationSchema: validationSchemas[6],
    label: "Trust and Safety",
  },
  {
    component: MarketingServices,
    validationSchema: validationSchemas[7],
    label: "Marketing",
  },
  {
    component: DeliveryDrivers,
    validationSchema: validationSchemas[8],
    label: "Additional Revenue",
  },
  {
    component: InsuranceProvider,
    validationSchema: validationSchemas[9],
    label: "Insurance",
  },
  {
    component: InsuranceOptions,
    validationSchema: validationSchemas[10],
    label: "Insurance",
  },
  // {
  //   component: RemoteVehicle,
  //   validationSchema: validationSchemas[11],
  //   label: "Remote Vehicle",
  // },

  // Ensure correct index
];

const BetaForm = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [telematicsData, setTelematicsData] = useState([]);
  const [insuranceData, setInsuraceData] = useState([]);
  const { updateUserFormData } = useContext(UserContext);
  const { userData } = useContext(UserContext);
  const [commaVal, setCommaVal] = useState("")

  const formatPhoneNumber = (phone) => {
    if (!phone) return '';
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  };
  const getaround = [];
  const softwarerental = [];
  const initialValues = {
    first_name: userData.firstName || '',
    last_name: userData.lastName || '',
    email_address: userData.email || '',
    // phone_number:  userData.phone || '',
    phone_number: formatPhoneNumber(userData.phone) || '',
    companyName:userData.companyName || '',
    state: userData.state || '',
    fleet_size: userData.fleet || '',
    // email_address: "",
    // phone_number: "",
    // companyName: "",
    // state: "",
    // fleet_size: "",
    business_compliance: "",
    regulations_compliance: "",
    manage_business: [],
    getaround,


    platforms: [],
    platforms_option_detail_Get_Around: {
      key:getaround
    },
    platforms_option_detail_Hyrecar:{key:getaround},
    platforms_option_detail_Navotar:{key:getaround},
    platforms_option_detail_Other:{key:getaround},
    platforms_option_detail_Turo:{key:getaround},
    //manage_business: getaround,
    softwarerental,
    software_option_detail_HQ_Rental_Software:{key:softwarerental},
    software_option_detail_RentWorks:{key:softwarerental},
    software_option_detail_Navotar:{key:softwarerental},
    software_option_detail_Rent_Centric:{key:softwarerental},
    software_option_detail_Other:{key:softwarerental},
    selected_telematics: [],
    telematics_option_detail: "",
    automated_verification: "",
    automated_validation: "",
    marketing_services: "",
    renting_vehicles: "",
    insurance_provider: "",
    selected_insurance_providers: "",
    insurance_provider_option_detail: "",
    on_rent_insurance: "",
    learn_deliverless: "",
    using_telematics: "0",
    selected_telematics: [], // This will hold the selected devices
     telematics_option_detail: '',
  };
 
  
  
console.log(initialValues);
  const nextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const prevStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleChange = (e) => {
    const { value } = e.target;

    setTelematicsData((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((item) => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };

  const handleChangeInsure = (e) => {
    const { value } = e.target;

    setInsuraceData((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter(item => item !== value);
      } else {
        return [...prevValues, value];
      }
    });
  };


  const handleSubmit = async (data, { setSubmitting }) => {
 

    // setFormSubmitting(true)
    var insurace = insuranceData;
    var chckNumbers = insurace.map(Number);
    var insuranceprovider = chckNumbers.join(',');


 
    if (currentStep === steps.length - 1) {
      const manage_business = data.manage_business.toString();
      const commaSeparated = telematicsData.toString();
      

      try {
        var formData = {
          "beta_users[first_name]": data.first_name || "First Name",
          "beta_users[last_name]": data.last_name || "Last Name",
          "beta_users[phone_number]": data.phone_number || "",
          "beta_users[email_address]": data.email_address || "Email Address",
          'beta_users[state]': data.state || 'State',
          'beta_users[fleet_size]': data.fleet_size || 'State',
          'beta_users[business_compliance]': data.business_compliance || 'Registered Business',
          'beta_users[regulations_compliance]': data.regulations_compliance || 'Familiar With Regulations',
          'beta_users[manage_business]': manage_business || 'Management Method',
          'beta_users[platforms_option_detail]': data.platforms_option_detail || 'Platform Option',
          'beta_users[software_option_detail]': data.software_option_detail,
          'beta_users_data[selected_telematics]': commaSeparated || '',
          'beta_users[telematics_option_detail]': data.telematics_option_detail,
          'beta_users[automated_verification]': data.automated_verification || 'IdVerification',
          'beta_users[automated_validation]': data.automated_validation || 'Auto Insurance',
          'beta_users[marketing_services]': data.marketing_services || 'Marketing Services',
          'beta_users[renting_vehicles]': data.renting_vehicles || 'Delivery Drivers',
          'beta_users[insurance_provider]': data.insurance_provider || 'Insurance Providers',
          'beta_users_data[selected_insurance_providers]': insuranceprovider,
          'beta_users[insurance_provider_option_detail]': data.insurance_provider_option_detail,
          'beta_users[on_rent_insurance]': data.on_rent_insurance || 'Insurance Interest',
          'beta_users[learn_deliverless]': data.learn_deliverless || 'Remote Vehicle',
          'beta_users_data[selected_platform_url]': commaVal || '',
          'beta_users[using_telematics]': data.using_telematics || 'using_telematics',
        };
        const urlEncodedData = qs.stringify(formData);


        const response = await axios.post(
          `${API_ENDPOINT}/beta/register`,
          // `https://api.getrentid.io/api/beta/register`,
          urlEncodedData,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
        updateUserFormData(data.first_name, data.last_name);
        nextStep();
        navigate("/BetaThankyou");
      } catch (error) {

        console.error("Error submitting form:", error);
      }

    } 
    else {
      
      nextStep();
    }

    setSubmitting(false);
  };



  const CurrentStepComponent = steps[currentStep].component;
  const currentValidationSchema = steps[currentStep].validationSchema;
  const progress = ((currentStep + 1) / steps.length) * 100;
  return (
    <div className="main beta-signup">
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
             
            >
              <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                  <div className="container">
                  {/* <header className=" pt-2">
                    <div className="row beta-header">
                      
                      <div className="col-lg-3 beta-flow-section">
                        <a href="/">
                          <img
                            src="/img/beta-signup/mobile-logo.png"
                            className="img-fluid"
                            alt="feature img"
                            style={{ maxHeight: "50px" }}
                          />
                        </a>
                      </div>

                      <div className="col-lg-9 beta-flow-section text-end">
                        <a href="/" className="btn return-hone">
                          Return Home
                        </a>
                      </div>
                    </div>
                  </header> */}
                    <div className="modal-header">
                      <div className="row w-100 align-items-center information-sec">
                        <div className="col-12 col-xs-12 col-md-6 p-0 d-flex gap-3">
                       
                        {currentStep > 0 && (
                        <img
                              src="/img/beta-signup/back.svg"
                              className="back_step"
                              alt="back"
                              onClick={prevStep}
                            />        )} <h5
                            className="modal-title text-start"
                            id="exampleModalLabel"
                          >
                            {steps[currentStep].label}
                          </h5>
                        </div>
                        <div className="col-12 col-xs-12 col-md-6 px-0">
                          <p className="step-count stepcount-text text-md-end mb-0 ps-md-0">
                            Step {currentStep + 1}/{steps.length}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-body bottom-border py-4">
                      <CurrentStepComponent
                        handleChange={handleChange}
                        handleChangeInsure={handleChangeInsure}
                        telematicsData={telematicsData}
                        insuranceData={insuranceData}
                        setTelematicsData={setTelematicsData}
                        setInsuraceData={setInsuraceData}
                        commaVal={commaVal}
                        setCommaVal={setCommaVal}
                      />
                    </div>
                  </div>
                  <div className="modal-footer pt-0 px-0">
                    <div className="row w-100">
                      <div className="col-12 p-0">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${progress}%` }}
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container beta-footer">
                    <div className="row align-items-center w-100 pt-3 px-md-0">
                      <div className="col-4 exit-button">
                        <button
                          type="button"
                          className="btn exitbtn"
                          onClick={() => setShowConfirmationModal(true)}
                        >
                          Exit
                        </button>
                      </div>
                      <div className="col-8 btn-continue text-end d-flex gap-2 align-items-center justify-content-end">
                      {currentStep > 0 && (
                          <button
                            type="button"
                            className="btn btn-outline-dark radius-5 backbtn "
                            onClick={prevStep}
                          >
                            <span className="">Back</span>
                        
                          </button>
                          
                        )}

                        {currentStep > 0 && (
                        <button
                          type="button"
                          className="btn btn-outline-dark radius-5 back-arrow-btn  d-lg-none"
                          onClick={prevStep}
                        >
                          <img
                            src="/img/beta-signup/back-icon.svg"
                            className="img-fluid back-icon"
                            alt="Back"
                          />
                        </button>
                      )}

                        <div className="footer-btn-sec">
                        {/* <img
                              src="/img/beta-signup/back-icon.svg"
                              className="img-fluid close-modal d-lg-none d-block"
                              alt="back"
                            /> */}
                        <button
                          type="submit"
                          className="btn btn-dark continuebtn sendbtn send_btn radius-5 ms-0"
                          disabled={isSubmitting}

                        >
                          {currentStep === steps.length - 1 ? "Confirm And Send" : "Continue"}
                        </button>
                        </div>

                      </div>
                    </div>
                    </div>
                   
                  </div>

                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>


{/* modal2 */}
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div
              className="modal fade"
              id="exampleModal2"
              tabIndex="-1"
              aria-labelledby="exampleModal2Label"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-fullscreen">
                <div className="modal-content">
                  <div className="container">
                  {/* <header className=" pt-2">
                    <div className="row beta-header">
                      
                      <div className="col-lg-3 beta-flow-section">
                        <a href="/">
                          <img
                            src="/img/beta-signup/mobile-logo.png"
                            className="img-fluid"
                            alt="feature img"
                            style={{ maxHeight: "50px" }}
                          />
                        </a>
                      </div>

                      <div className="col-lg-9 beta-flow-section text-end">
                        <a href="/" className="btn return-hone">
                          Return Home
                        </a>
                      </div>
                    </div>
                  </header> */}
                    <div className="modal-header">
                      <div className="row w-100 align-items-center information-sec">
                        <div className="col-12 col-xs-12 col-md-6 p-0 d-flex gap-3">
                       
                        {currentStep > 0 && (
                        <img
                              src="/img/beta-signup/back.svg"
                              className="back_step"
                              alt="back"
                              onClick={prevStep}
                            />        )} <h5
                            className="modal-title text-start"
                            id="exampleModalLabel"
                          >
                            {steps[currentStep].label}
                          </h5>
                        </div>
                        <div className="col-12 col-xs-12 col-md-6 px-0">
                          <p className="step-count stepcount-text text-md-end mb-0 ps-md-0">
                            Step {currentStep + 1}/{steps.length}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="modal-body bottom-border py-4">
                      <CurrentStepComponent
                        handleChange={handleChange}
                        handleChangeInsure={handleChangeInsure}
                        telematicsData={telematicsData}
                        insuranceData={insuranceData}
                        setTelematicsData={setTelematicsData}
                        setInsuraceData={setInsuraceData}
                        commaVal={commaVal}
                        setCommaVal={setCommaVal}
                      />
                    </div>
                  </div>
                  <div className="modal-footer pt-0 px-0">
                    <div className="row w-100">
                      <div className="col-12 p-0">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${progress}%` }}
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container beta-footer">
                    <div className="row align-items-center w-100 pt-3 px-md-0">
                      <div className="col-4 exit-button">
                        <button
                          type="button"
                          className="btn exitbtn"
                          onClick={() => setShowConfirmationModal(true)}
                        >
                          Exit
                        </button>
                      </div>
                      <div className="col-8 btn-continue text-end d-flex gap-2 align-items-center justify-content-end">
                      {currentStep > 0 && (
                          <button
                            type="button"
                            className="btn btn-outline-dark radius-5 backbtn "
                            onClick={prevStep}
                          >
                            <span className="">Back</span>
                        
                          </button>
                          
                        )}

                        {currentStep > 0 && (
                        <button
                          type="button"
                          className="btn btn-outline-dark radius-5 back-arrow-btn  d-lg-none"
                          onClick={prevStep}
                        >
                          <img
                            src="/img/beta-signup/back-icon.svg"
                            className="img-fluid back-icon"
                            alt="Back"
                          />
                        </button>
                      )}

                        <div className="footer-btn-sec">
                        {/* <img
                              src="/img/beta-signup/back-icon.svg"
                              className="img-fluid close-modal d-lg-none d-block"
                              alt="back"
                            /> */}
                        <button
                          type="submit"
                          className="btn btn-dark continuebtn sendbtn send_btn radius-5 ms-0"
                          disabled={isSubmitting}

                        >
                          {currentStep === steps.length - 1 ? "Confirm And Send" : "Continue"}
                        </button>
                        </div>

                      </div>
                    </div>
                    </div>
                   
                  </div>

                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className={`modal fade ${showConfirmationModal ? 'show' : ''}`} id="confirmationModal" tabIndex="-1" aria-labelledby="confirmationModalLabel" aria-hidden={!showConfirmationModal} style={{ display: showConfirmationModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 m-0">
              <div className="row w-100 align-items-center">
                <div className="col-10">
                  <h5 className="modal-title ps-5" id="confirmationModalLabel">
                    <img src="/img/beta-signup/infoicon.svg" className="img-fluid" alt="info" />
                  </h5>
                </div>
                <div className="col-2 text-end">
                  <button type="button" className="btn-close" onClick={() => setShowConfirmationModal(false)} aria-label="Close"></button>
                </div>
              </div>
            </div>
            <div className="modal-body m-0">
              <h3 className="modal-confirmation-text text-center">Are you sure you want to exit the sign-up process<br /> for the Rentid Beta Program?</h3>
            </div>
            <div className="modal-footer py-4">
              <div className="row w-100 align-items-center justify-content-center pt-0">
                <div className="col-6 col-xs-12 col-md-6 mt-md-0">
                  <a href="/"><button type="button" className="btn modal-exit-btn w-100 backbtn" >Exit Sign-Up</button></a>
                </div>
                <div className="col-6 col-xs-12 col-md-6 continueBtn">
                <a href="/"  class="text-decoration-none"><button type="button" className="btn modal-exit-btn w-100 exit_btn" >Exit Sign-Up</button></a>
                  <button type="button" className="btn modal-continue-btn w-100" onClick={() => setShowConfirmationModal(false)}>Continue Signing Up</button>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  );

};

export default BetaForm;
