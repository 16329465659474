import react from 'react';
import './MostMorden.css';

const MostMorden = ()=>{
    return(
        <>
<section className="mostMordenMain">
    <div className="container">
        <div class="row justify-content-center">
          <div class="col-lg-9">
              <div class="headmostMorden">
                  <h2>The most modern & intuitive booking experience designed for vehicle rental</h2>
                  <p>Say hello to seamless rental operations with customizable, automated booking workflows 
                    that enhance productivity and efficiency with every rental.</p>
              </div>
          </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#mostMorden_1">
                            <img width="22px" class="img_simple" height="18px" src="/img/rentdirect/most_morden_shield_icon.svg" alt="icon"/>
                            <img width="22px" class="img_active" height="18px" src="/img/rentdirect/most_morden_shield_icon_active.svg" alt="icon"/>
                            <span>Guest Booking Experience</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#mostMorden_2">
                            <img width="22px" class="img_simple" height="18px" src="/img/rentdirect/most_morden_shield_icon.svg" alt="icon"/>
                            <img width="22px" class="img_active" height="18px" src="/img/rentdirect/most_morden_shield_icon_active.svg" alt="icon"/>
                            <span>Host Booking Experience</span>
                        </a>
                    </li>
                </ul>
            
                <div class="tab-content">
                    {/* START Tab 1 */}
                    <div class="tab-pane fade" id="mostMorden_1">
                        <div className='mostMordenCommon mostGuestOuter'>
                            <div className='row align-items-center'>
                                <div className='col-lg-7 col-md-12 col-sm-12 col-12'>
                                    <div className='mostGuestLeft'>
                                    <h3>Guest Booking Experience</h3>
                                        <p>Booking is effortless with our <span>smart booking management</span> system. 
                                        Guests can search on your branded website or app and follow a modernized rental journey to access all trip details, 
                                        ensuring a truly seamless travel experience from start to finish</p>
                                        <ul>
                                            <li>Branded Experience</li>
                                            <li>Search</li>
                                            <li>Promotions</li>
                                            <li>Protection Plans</li>
                                            <li>Extras</li>
                                            <li>Secure Payments</li>
                                            <li>ID Verification</li>
                                            <li>Insurance Validation</li>
                                            <li>Scheduled Instructions</li>
                                            <li>Messaging</li>
                                            <li>Start Trip</li>
                                            <li>End Trip</li>
                                            <li>Trip Photos</li>
                                            <li>Rental Agreement</li>
                                            <li>Insurance Card</li>
                                            <li>Modify Trip</li>
                                            <li>File Claim</li>
                                            <li>Review System</li>
                                            <li>Leave Tip</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-lg-5 col-md-12 col-sm-12 col-12'>
                                    <div className='mostGuestRight'>
                                        <video width="380" height="572" controls loop autoPlay muted playsInline>
                                            <source src="/img/rentdirect/guest_booking.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* START Tab 2 */}
                    <div class="tab-pane active" id="mostMorden_2">
                        <div className='mostMordenCommon mostHostOuter'>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className='mostHostInner'>
                                            <h3>Host Booking Experience</h3>
                                            <p>Our <span>smart booking management</span> system seamlessly integrates reservation data into automated 
                                            workflows, streamlining your rental operations like never before. Effortlessly process 
                                            bookings, prepare vehicles, and stay connected with guests to deliver outstanding 
                                            experiences. Simplify your operations, enhance guest satisfaction, and unlock growth 
                                            for your business with ease.</p>
                                            <ul>
                                                <li>Liability</li>
                                                <li>Protection Plans</li>
                                                <li>ID Verification</li>
                                                <li>Insurance Validation</li>
                                                <li>Primary Coverage</li>
                                                <li>A-Rated</li>
                                                <li>Billing</li>
                                                <li>Fleet Size</li>
                                            </ul>

                                            <figure>
                                                <img class="img-fluid" src="/img/rentdirect/mostMorden_2.jpg" alt="Most Morden"/>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                        </div> 
                    </div> 
        
                </div>
            </div>
        </div>
    </div>
</section>
        </>
    )
}

export default MostMorden;