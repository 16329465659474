// GeneralInfo.js
import React, { useState, useEffect , useContext} from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import InputMask from 'react-input-mask';
import { UserContext } from "../../context/FormDataContext";
const GeneralInfomation = () => {
  const [formData, setFormData] = useState({});
  const { userData } = useContext(UserContext);
  // const [value, setValue] = useState()
  // const setValue=(val)=>{
  //   setPhoneNumber(val)
  // }
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const [isFocused, setIsFocused] = useState(false);
  
  const handlePhoneChange = (value) => {
    setFieldValue('phone_number', value);
  };
  console.log("errorserrorserrors", errors)

  
   
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };
  
console.log(userData.phone, 'this is phone number')
  return (
    <div className="">
      <div className="row">
        <div className="col-12 col-xs-12 col-md-12 px-0">
          <div className="host_form py-0">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="firstName" className="form-label">
                    First name<span class="required">*</span>
                  </label>
                  <Field
                  required
                    type="text"
                    value={values.first_name || userData.firstName}
                    onChange={handleInputChange}
                    className="form-control"
                    
                    id="first_name"
                    name="first_name"
                    // placeholder="John"
                    placeholder="Enter first name"
                    
                  />
                  <ErrorMessage
                    name="first_name"
                    component="div"
                    className="invalid-feedback"
                    
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="lastName" className="form-label">
                    Last name<span class="required">*</span>
                  </label>
                  <Field
                   value={values.last_name || userData.lastName}
                   onChange={handleInputChange}
                    type="text"
                    required
                    className="form-control"
                    id="last_name"
                    name="last_name"
                    placeholder="Enter last name"
                  />
                  <ErrorMessage
                    name="last_name"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="phone_number" className="form-label">
                    Phone Number<span class="required">*</span>
                  </label>
                  <div className="row border align-items-center countryflag">
                    <div className="col-2 p-0 d-flex align-items-center text-center countrycode">
                      <img
                        src="/img/us.svg"
                        className="img-fluid me-2"
                        alt="Country Flag"
                      />
                      <span>+1</span>
                    </div>
                    <div className="col-10 p-0">
                      <InputMask
                        mask="(999) 999-9999"
                        // value={values.phone_number || ''}
                        value={values.phone_number || userData.phone}
                        onChange={(e) => setFieldValue('phone_number', e.target.value)}
                        className="form-control"
                     
                        id="phone_number"
                        placeholder="Enter phone number"
                        maskChar={null}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        formatCharacters={{ '9': '[0-9]' }}
                          >
                        {(inputProps) => <Field {...inputProps} name="phone_number" />}
                      </InputMask>
                    </div>
                  </div>
                  <ErrorMessage
                    name="phone_number"
                    component="div"
                    className="invalid-feedback"
                  />
                  {/* <ErrorMessage
                          name="phone_number"
                          component="div"
                          className="invalid-feedback"
                        />  */}
                  {/* <PhoneInput
                    id="phone_number"
                    limitMaxLength="10"
                    defaultCountry="US"
                    placeholder="(999) 999-9999"
                    value={values.phone_number}
                    onChange={handlePhoneChange}
                  />
                  {
                    errors?.phone_number && (
                      <ErrorMessage
                      name="phone_number"
                      component="div"
                      className="invalid-feedback"
                    />
                    )
                  }                 */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email address<span class="required">*</span>
                  </label>
                  <Field
                value={values.email_address || userData.email}
                onChange={handleInputChange}
                required
                    type="email"
                    className="form-control"
                  
                    id="email_address"
                    name="email_address"
                    placeholder="Enter your email"
                  />
                  <ErrorMessage
                    name="email_address"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="companyName" className="form-label">
                  Address Line 1<span class="required">*</span>
                  </label>
                  <Field
                    required
                  value={values.companyName || userData.companyName}
                  onChange={handleInputChange}
                    type="text"
                    className="form-control"
                    id="companyName"
                    name="companyName"
                    placeholder="Business name"
                  />
                  <ErrorMessage
                    name="companyName"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="companyName" className="form-label">
                  Address Line 2
                  </label>
                  <Field
                    required
                  value={values.companyName || userData.companyName}
                  onChange={handleInputChange}
                    type="text"
                    className="form-control"
                    id="companyName"
                    name="companyName"
                    placeholder="Business name"
                  />
                  <ErrorMessage
                    name="companyName"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="state" className="form-label">
                    City<span class="required">*</span>
                  </label>
                  
                  <select

                   value={values.state || userData.state}
                   onChange={handleInputChange}
                    // as="select"
                    className="selectpicker"
                    id="state"
                    name="state"
                    data-live-search="true"
                  >
               
                    <option value="">Select</option>
                    <option value='Alabama'>Alabama</option>
                    <option value='Alaska'>Alaska</option>
                    <option value='Arizona'>Arizona</option>
                    <option value='Arkansas'>Arkansas</option>
                    <option value='California'>California</option>
                    <option value='Colorado'>Colorado</option>
                    <option value='Connecticut'>Connecticut</option>
                    <option value='Delaware'>Delaware</option>
                    <option value='Florida'>Florida</option>
                    <option value='Georgia'>Georgia</option>
                    <option value='Hawaii'>Hawaii</option>
                    <option value='Idaho'>Idaho</option>
                    <option value='Illinois'>Illinois</option>
                    <option value='Indiana'>Indiana</option>
                    <option value='Iowa'>Iowa</option>
                    <option value='Kansas'>Kansas</option>
                    <option value='Kentucky'>Kentucky</option>
                    <option value='Louisiana'>Louisiana</option>
                    <option value='Maine'>Maine</option>
                    <option value='Maryland'>Maryland</option>
                    <option value='Massachusetts'>Massachusetts</option>
                    <option value='Michigan'>Michigan</option>
                    <option value='Minnesota'>Minnesota</option>
                    <option value='Mississippi'>Mississippi</option>
                    <option value='Missouri'>Missouri</option>
                    <option value='Montana'>Montana</option>
                    <option value='Nebraska'>Nebraska</option>
                    <option value='Nevada'>Nevada</option>
                    <option value='New Hampshire'>New Hampshire</option>
                    <option value='New Jersey'>New Jersey</option>
                    <option value='New Mexico'>New Mexico</option>
                    <option value='New York'>New York</option>
                    <option value='North Carolina'>North Carolina</option>
                    <option value='North Dakota'>North Dakota</option>
                    <option value='Ohio'>Ohio</option>
                    <option value='Oklahoma'>Oklahoma</option>
                    <option value='Oregon'>Oregon</option>
                    <option value='Pennsylvania'>Pennsylvania</option>
                    <option value='Rhode Island'>Rhode Island</option>
                    <option value='South Carolina'>South Carolina</option>
                    <option value='South Dakota'>South Dakota</option>
                    <option value='Tennessee'>Tennessee</option>
                    <option value='Texas'>Texas</option>
                    <option value='Utah'>Utah</option>
                    <option value='Vermont'>Vermont</option>
                    <option value='Virginia'>Virginia</option>
                    <option value='Washington'>Washington</option>
                    <option value='West Virginia'>West Virginia</option>
                    <option value='Wisconsin'>Wisconsin</option>
                    <option value='Wyoming'>Wyoming</option>
                  </select>
                  <ErrorMessage
                    name="state"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6 select_2">
                <div className="mb-3">
                  <label htmlFor="fleetSize" className="form-label">
                    State<span class="required">*</span>
                  </label>
                  <select
                   value={values.fleet_size || userData.fleet}
                   onChange={handleInputChange}
                   required
                    as="select"
                    className="selectpicker"
                    id="fleet_size"
                    name="fleet_size"
                  >
                    <option value="">Select</option>
                    <option value="1">1</option>
                    <option value="2 to 5">2 to 5</option>
                    <option value="5 to 10">5 to 10</option>
                    <option value="Greater than 10">Greater than 10</option>
                  </select>
                  <ErrorMessage
                    name="fleet_size"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="companyName" className="form-label">
                  Zip Code<span class="required">*</span>
                  </label>
                  <Field
                    required
                  value={values.companyName || userData.companyName}
                  onChange={handleInputChange}
                    type="text"
                    className="form-control"
                    id="companyName"
                    name="companyName"
                    placeholder="Business name"
                  />
                  <ErrorMessage
                    name="companyName"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfomation;
