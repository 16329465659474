import React, { useEffect } from 'react';
import './Rentdirectanimation.css' // Assuming your CSS is in a separate file named styles.css
import 'bootstrap/dist/js/bootstrap.bundle.min';
import * as bootstrap from 'bootstrap';
import img1 from './assets/images/Icon.svg';
import img2 from './assets/images/calendar-favorite-02.svg';
import img3 from './assets/images/policy.svg';
import img4 from './assets/images/analytics-01.svg';
import img5 from './assets/images/car-shield.svg';
import img6 from './assets/images/document-validation.svg';
import img7 from './assets/images/chart-histogram.svg';
import img8 from './assets/images/payment-01.svg';
import img9 from './assets/images/artificial-intelligence-04.svg';
import img10 from './assets/images/market-analysis.svg';
import img11 from './assets/images/headset.svg';
import img12 from './assets/images/ai-mail.svg';
import img13 from './assets/images/computer-phone-sync.svg';
import img14 from  './assets/images/calendar-02-1.svg';
import img15 from './assets/images/calendar-02-1.svg';
import img16 from './assets/images/package-add.svg';
import img17 from './assets/images/rentdirect.svg';
const RendDirectanimation = () => {
  
  useEffect(() => {
    const container = document.querySelector('.container');
    const wrapper = document.querySelector('.wrapper');

    const images = [
      img1, img2, img3, img4, img5,img6,img7,img8,img9,img10,img11,img12,img13,img14,img15,img16,img17
    ];

    // Generating the Card for using Array
    const elementsData = [
      "BrandedExperiences",
      "BookingManagement",
      "Policies",
      "AnalyticsReporting",
      "FleetOperations",
      "Claims",
      "FinancialMangement",
      "Payments",
      "AI",
      'CustomerRelations',
      "Violations",
      "Email",
      "Guest_app",
      "FinancialMangementS",
      "Booking",
      "Experiences",
      "Redirect",
     
    ];


    const cardLinks = [
      "#telemetics", "/BookingManagement", "policies", "#CustomerRelations", "#notification", "/claims", "#FinancialMangement", "/payments", "#ai", "#AnalyticsReporting",
      "/violations", "/email", "/guest-app", "/FinancialMangementsync", "/booking", "/extras", "/redirect",
    ];



    Array.from({ length: 36 }).forEach((_, index) => {
      const elements = document.createElement('div');
      elements.classList.add('items1', `item-${index + 1}`);

      if (elementsData[index]) {
        const title = document.createElement('span');
        //title.innerHTML = elementsData[index];
        
        let camelCaseText = title.innerHTML; // Extract the current text
        camelCaseText = elementsData[index]; // Update the variable
		    let formattedText = camelCaseText.replace(/([A-Z])/g, ' $1').trim();
        if(formattedText == 'Analytics Reporting'){
          formattedText = 'Analytics & Reporting'
        }
        title.innerHTML =  formattedText;     // Update the DOM element

         const img = document.createElement('img');
        img.src = images[index % images.length]; // Cycle through images
        img.classList.add('icons');
       
     

        if (elementsData[index] === "Redirect") {
          elements.classList.add('redirect-card'); // Add custom class here
        }

        const anchor = document.createElement('a');
        anchor.href = cardLinks[index]; // Assign link based on the index
       // console.log(index,'test data');
      
        anchor.classList.add('card-link');
      
        //anchor.classList.add('card-link');
     
         anchor.appendChild(img);

        
        elements.appendChild(anchor);
        elements.append(title);
        if (elementsData[index] === "FinancialMangement") {
          elements.style.gridArea = "FinancialMangement"; // Set grid area name with a space
      } else {
          elements.style.gridArea = elementsData[index].replace(" ", "_"); // Replace spaces with underscores for other elements
      }
        elements.style.gridArea = elementsData[index];
        elements.setAttribute('data-name', elementsData[index]);
        // elements.setAttribute('data-bs-toggle', 'tooltip');
      elements.setAttribute('title', ` ${elementsData[index]}`);

        wrapper.append(elements);
      }
    });
    // const initializeTooltips = () => {
    //   const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      
    //   tooltipTriggerList.forEach((tooltipTriggerEl) => {
    //     // Add the placement attribute dynamically if not already present
    //     if (!tooltipTriggerEl.hasAttribute('data-bs-placement')) {
    //       tooltipTriggerEl.setAttribute('data-bs-placement', 'bottom');
    //     }
        
    //     // Initialize the tooltip
    //     new bootstrap.Tooltip(tooltipTriggerEl); 
    //   });
    // };
    

    

    // Generating SVG Line for each Element
    const elementsDataName = [
      ["BrandedExperiences", "BookingManagement"],
      ["Redirect", "AnalyticsReporting"],
      ["Redirect","CustomerRelations" ],
      ["FleetOperations","BookingManagement" ],
      ["Redirect", "FinancialMangement"]
    ];

    // Generating SVGs inside the .container
    elementsDataName.forEach((_, index) => {
      const svgContainer = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svgContainer.classList.add('svg-lines', `svg-lines-${index + 1}`);
      container.appendChild(svgContainer);
    });


    
    // Function to create SVG paths
    const createSVGPath = (startX, startY, endX, endY) => {
      const svgNS = "http://www.w3.org/2000/svg";
      const path = document.createElementNS(svgNS, "path");
      const cornerRadius = 20;

      const pathData = `
        M ${startX} ${startY}
        L ${startX} ${endY}
        L ${endX} ${endY}
        L ${endX} ${endY}
      `;

    path.setAttribute("d", pathData);
    path.setAttribute("stroke", "#1CD7A8");
    path.setAttribute("stroke-width", "3");
    path.setAttribute("stroke-linecap", "round");
    path.setAttribute("stroke-linejoin", "round");
    path.setAttribute("fill", "none");
    path.classList.add("animate-path");

      return path;
    };
    const drawLines = () => {
      connectElements();
      const paths = document.querySelectorAll('.animate-path');

      paths.forEach((path) => {
        const pathLength = path.getTotalLength();
        path.style.strokeDasharray = pathLength;
        path.style.strokeDashoffset = pathLength;
        path.style.setProperty('--path-length', `${pathLength}px`);
      });
    };
   const connectElements = () => {
      elementsDataName.forEach((name, index) => {
        const svgContainer = document.querySelector(`.svg-lines-${index + 1}`);
        const [startName, endName] = name;

        const item1 = document.querySelector(`[data-name='${startName}']`);
        const item2 = document.querySelector(`[data-name='${endName}']`);

        // Clear previous SVG content
        svgContainer.innerHTML = "";

        // Get positions and dimensions
        const rect1 = item1.getBoundingClientRect();
        const rect2 = item2.getBoundingClientRect();

        // Calculate start and end points
        const startX = rect1.left + rect1.width / 2;
        const startY = rect1.top + rect1.height / 2;
        const endX = rect2.left + rect2.width / 2;
        const endY = rect2.top + rect2.height / 2;
        console.log(`Line ${index + 1} from ${startName} to ${endName}: (${startX}, ${startY}) -> (${endX}, ${endY})`);
        // Append path to SVG container
        svgContainer.appendChild(createSVGPath(startX, startY, endX, endY));
      });
    };


    
    const animatingLine = async () => {
      const path1 = document.querySelector('.svg-lines-1 path');
      const path2 = document.querySelector('.svg-lines-2 path');
      const path3 = document.querySelector('.svg-lines-3 path');
      const path4 = document.querySelector('.svg-lines-4 path');
      const path5 = document.querySelector('.svg-lines-5 path');
      const path6 = document.querySelector('.svg-lines-6 path');
      const path7 = document.querySelector('.svg-lines-7 path');
      const path8 = document.querySelector('.svg-lines-8 path');
      const path9 = document.querySelector('.svg-lines-9 path');
      const path10 = document.querySelector('.svg-lines-10 path');
      const path11 = document.querySelector('.svg-lines-11 path');
      const path12 = document.querySelector('.svg-lines-12 path');
      const paymentCard = document.querySelector("[data-name='Redirect']");
      const paymentCards = document.querySelector("[data-name='Payments']");
      const Fleetoperations = document.querySelector("[data-name='FleetOperations']");
      const Violations = document.querySelector("[data-name='Violations']");
      const Payments = document.querySelector("[data-name='Payments']");
      const treasuryCard = document.querySelector("[data-name='Guest_app']");
      const Claims = document.querySelector("[data-name='Claims']");
      const Policies = document.querySelector("[data-name='Policies']");
      //const Modify = document.querySelector("[data-name='BookingManagement']");
      const BrandedExperiences = document.querySelector("[data-name='BrandedExperiences']");
      const BookingManagement = document.querySelector("[data-name='BookingManagement']");
      const AnalyticsReporting = document.querySelector("[data-name='AnalyticsReporting']");
      const CustomerRelations = document.querySelector("[data-name='CustomerRelations']");
      const Guest_app = document.querySelector("[data-name='Guest_app']");
      const FinancialMangement = document.querySelector("[data-name='FinancialMangement']");
      const FinancialMangementsync = document.querySelector("[data-name='FinancialMangementS']");
      const Booking = document.querySelector("[data-name='Booking']");
      const Email = document.querySelector("[data-name='Email']");
      const AI = document.querySelector("[data-name='AI']");
      const Extras = document.querySelector("[data-name='Extras']");
      async function animateCards() {
        while (true) {
            // First Animation Sequence
            eachAnimation(path1, BrandedExperiences, BookingManagement, "add");
            await delay(2000);
            eachAnimation(path1, BrandedExperiences, BookingManagement, "remove");

            eachAnimation(path2, paymentCard, AnalyticsReporting, "add");
            await delay(2000);
            eachAnimation(path2, paymentCard, AnalyticsReporting, "remove");

            eachAnimation(path3, paymentCard, CustomerRelations, "add");
            await delay(2000);
            eachAnimation(path3, paymentCard, CustomerRelations, "remove");


            eachAnimation(path4, Fleetoperations, BookingManagement, "add");
            await delay(2000);
            eachAnimation(path4, Fleetoperations, BookingManagement, "remove");

            
            eachAnimation(path5, paymentCard, FinancialMangement, "add");
            await delay(2000);
            eachAnimation(path5, paymentCard, FinancialMangement, "remove");

            
           /* eachAnimation(path3, Violations, Payments, "add");
            await delay(2500);
            eachAnimation(path3, Violations, Payments, "remove");
            // Intermediate Animation Sequence
            eachAnimation(path4, Payments, Claims, "add");
            eachAnimation(path5, Claims, Policies, "add");
            await delay(2500);
            eachAnimation(path4, Payments, Claims, "remove");
            eachAnimation(path5, Claims, Policies, "remove");
            
            eachAnimation(path6, Policies, Modify, "add");
            await delay(2500);
            eachAnimation(path6, Policies, Modify, "remove");

            eachAnimation(path7, Modify, Telemetics, "add");
            await delay(2500);
            eachAnimation(path7, Modify, Telemetics, "remove");

            eachAnimation(path8, paymentCard, AnalyticsReporting, "add");
            eachAnimation(path9, paymentCard, CustomerRelations, "add");
            eachAnimation(path10, paymentCard, Guest_app, "add");
            await delay(2500);
            eachAnimation(path8, paymentCard, AnalyticsReporting, "remove");
            eachAnimation(path9, paymentCard, CustomerRelations, "remove");
            eachAnimation(path10, paymentCard, Guest_app, "remove");


            eachAnimation(path11, Guest_app, FinancialMangementsync, "add");
            await delay(2500);
            eachAnimation(path11, Guest_app, FinancialMangementsync, "remove");
           

            eachAnimation(path12, paymentCard, FinancialMangement, "add");
            await delay(2500);
            eachAnimation(path12, paymentCard, FinancialMangement, "remove");*/
        


           
         
          
        }
    }
    function delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
  }
  animateCards();
    };
    

    

  const eachAnimation = (path, card1, card2, action) => {
  const text1 = card1.querySelector('span');
  const text2 = card2.querySelector('span');

  const isRedirectCard = (card) => card.getAttribute('data-name') === 'Redirect';

  if (action === "add") {
    path.classList.add("animate-now");
    card1.classList.add("animate-now");
    setTimeout(() => {
      card2.classList.add('animate-now-delay');
  }, 1100);
    //card2.classList.add("animate-now-delay");

    if (isRedirectCard(card1)) card1.classList.add("glow-effect");
    if (isRedirectCard(card2)) card2.classList.add("glow-effect");

    if (text1) text1.classList.add("show-text");
    if (text2) text2.classList.add("show-text");
  } else if (action === "remove") {
    path.classList.remove("animate-now");
    card1.classList.remove("animate-now");
    card2.classList.remove("animate-now-delay");

    if (isRedirectCard(card1)) card1.classList.remove("glow-effect");
    if (isRedirectCard(card2)) card2.classList.remove("glow-effect");
  }
};

    // Initial drawing and animation
    setTimeout(() => {
      drawLines();
      animatingLine();
    }, 500);
    // initializeTooltips();
    // Event listeners
    //window.addEventListener("resize", connectElements);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", connectElements);
      // Clean up any SVGs or elements created if necessary
    };
  }, []); // Empty dependency array to run only on mount

  return (
    <div className="container">
    
      <div className="animation-lines-box wrapper"></div>
    </div>
  );
};

export default RendDirectanimation;
