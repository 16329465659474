import react from 'react';
import './MoreSolutions.css';

const MoreSolutions = ()=>{
    return(
        <>
        <section className='moreSolutionMain'>
            <div className='container'>

                    <div class="row">
                        <div class="col-lg-8 col-md-12 col-sm-12">
                            <div class="moreSolutionHeading">
                                <h2>Discover more solutions</h2>
                                <p>Rental tools to help you scale your business</p>
                            </div>
                        </div>
                    </div>

                    <div className='row justify-content-center'>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 moreSolutionRES">
                            <div class="moreSolutionInner moreSolutionGreen">
                                <a href="/rentdirect">
                                <div class="green_overlay"></div>
                                    <figure><img src="/img/rent_direct_logo.png" alt="logo"/></figure>
                                </a>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 moreSolutionRES">
                            <div class="moreSolutionInner moreSolutionRed">
                                <a href="/rentshield">
                                    <div class="red_overlay"></div>
                                    <figure><img src="/img/rent_shield_logo.png" alt="logo"/></figure>
                                </a>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 moreSolutionRES">
                            <div class="moreSolutionInner moreSolutionPurple">
                                <a href="/rentsuccess">
                                    <div class="purple_overlay"></div>
                                    <figure><img src="/img/rent_success_logo.png" alt="logo"/></figure>
                                </a>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 moreSolutionRES">
                            <div class="moreSolutionInner moreSolutionYellow">
                                <a href="/rentacademy">
                                    <div class="yellow_overlay"></div>
                                    <figure> <img src="/img/rent_academy_logo.png" alt="logo"/></figure>
                                </a>
                            </div>
                        </div>
 
                    </div>
            </div>
        </section>
        </>
    )
}

export default MoreSolutions;