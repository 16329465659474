import react from 'react';


const Confirmation = () => {
    return (
        <>
            <div className="commonHostMain">
                <div className="commonHostOuter">
                    <div className="confirmationInner">
                        
                        <img class="img-fluid" src="/img/logo_signup.svg" alt="logo"/>
                        <h3>Welcome [[User name]]!</h3>
                        <p>Your account has been successfully created! Next, you can manage your settings and preferences.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Confirmation;