import React, { useEffect, useRef , useState, useContext} from "react";
import createGlobe from "cobe";
import "./SupportTeam.css";
import { SpaceBar } from "@mui/icons-material";
import AnimationCards from "./Animationcards";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import { API_ENDPOINT } from '../../Constant/Constant';
import { UserContext } from "../../context/FormDataContext";
import qs from 'qs';


function SupportTeam() {
    const canvasRef = useRef();
    const containerRef = useRef();
    const globeRef = useRef(null);
    useEffect(() => {
        let phi = -1;
        let lastRenderTime = 0;
    
        const adjustGlobeSize = () => {
            const containerWidth = containerRef.current.offsetWidth;
            const globeSize = containerWidth * 0.9;
    
            canvasRef.current.width = globeSize * 2;
            canvasRef.current.height = globeSize * 2;
    
            if (globeRef.current) {
                globeRef.current.destroy();
            }
    
            globeRef.current = createGlobe(canvasRef.current, {
                devicePixelRatio: 1.0,
                width: globeSize * 2,
                height: globeSize * 2,
               // phi: 2.28,
                //theta: 0.36,
                phi: -95.7129 * (Math.PI / 180), // Convert degrees to radians
                theta: 37.0902 * (Math.PI / 180),
                dark: 1,
                scale: 1,
                diffuse: 2.2,
                //mapSamples: 3000,
                mapSamples: 7500,
                mapBrightness: 3.1,
                baseColor: [0.0, 0.2, 0.3],
                markerColor: [0.2, 0.97, 0.75],
                 glowColor: [0.1, 0.15, 0.25],
                //glowColor: [0.243, 0.074, 0.918],
                opacity: .90,
                markers: [
                    // { location: [39.5501, -105.7821], size: 0.03 },
                    // { location: [44.5000, -100.0000], size: 0.03 },
                    // { location: [32.7157, -117.1611], size: 0.03 },
                    // { location: [33.7490, -84.3880], size: 0.03 },
                    // { location: [29.9511, -90.0715], size: 0.03 },
                    // { location: [30.3322, -81.6557], size: 0.03 },
                    // { location: [34.0522, -118.2437], size: 0.03 },
                    // { location: [25.7617, -80.1918], size: 0.03 },
                    // { location: [21.3069, -157.8583], size: 0.03 },
					// { location: [32.7767, -96.797], size: 0.03 },
					// { location: [33.4484, -112.074], size: 0.03 },
					// { location: [47.6062, -122.3321], size: 0.03 },
					// { location: [41.8781, -87.6298], size: 0.03 },
					// { location: [38.9072, -77.0369], size: 0.03 },
					// { location: [34.0522, -118.2437], size: 0.03 }, // Los Angeles, CA
					// { location: [25.7617, -80.1918], size: 0.03 }, // Miami, FL
					// { location: [21.3069, -157.8583], size: 0.03 }, // Honolulu, HI
					// { location: [32.7767, -96.797], size: 0.03 }, // Dallas, TX
					// { location: [33.4484, -112.074], size: 0.03 }, // Phoenix, AZ
					// { location: [47.6062, -122.3321], size: 0.03 }, // Seattle, WA
					// { location: [41.8781, -87.6298], size: 0.03 }, // Chicago, IL
					// { location: [39.9526, -75.1652], size: 0.03 }, // Philadelphia, PA
					// { location: [38.9072, -77.0369], size: 0.03 }, // Washington, D.C.
					// { location: [37.7749, -122.4194], size: 0.03 }, // San Francisco, CA
					// { location: [36.1699, -115.1398], size: 0.03 }, // Las Vegas, NV
					// { location: [44.0682, -114.742], size: 0.03 }, // Idaho (State Center)
					// { location: [46.8797, -110.3626], size: 0.03 }, // Montana (State Center)
					// { location: [45.2538, -69.4455], size: 0.03 }, // Maine (State Center)
					// { location: [32.7157, -117.1611], size: 0.03 },
					// { location: [33.749, -84.388], size: 0.03 },
					// { location: [29.9511, -90.0715], size: 0.03 },
					// { location: [30.3322, -81.6557], size: 0.03 },
					// { location: [39.5501, -105.7821], size: 0.03 },
					// { location: [44.5, -100.0], size: 0.03 },
                ],
                // onRender: (state) => {
                //     const now = Date.now();
                //     if (now - lastRenderTime > 16) { // ~60 FPS
                //         state.phi = phi;
                //         phi += 0.0002;
                //         lastRenderTime = now;
                //     }
                // },

                onRender: (state) => {
                    const now = Date.now();
                    if (now - lastRenderTime > 8) { // ~60 FPS
                        state.phi = phi;
                        phi += 0.005; // Increased from 0.0002 to 0.001 for faster rotation
                        lastRenderTime = now;
                    }
                },
            });
        };
    
        adjustGlobeSize();
        window.addEventListener("resize", adjustGlobeSize);
    
        return () => {
            window.removeEventListener("resize", adjustGlobeSize);
            if (globeRef.current) globeRef.current.destroy();
        };
    }, []);


    const [show,setShow]=useState(false);
    const navigate = useNavigate();
    const { updateUserFormData } = useContext(UserContext);
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <section id="office" className="">

<div ref={containerRef} className="col-xl-5 col-lg-5 col-md-4 d-flex justify-content-center globe-sec">
<canvas
                className="canvas_globe"
                ref={canvasRef}
                style={{ width: 1000, height: 600, maxWidth: "100%", aspectRatio: 1 }}
            />
                      </div>
            
            <div className="container">
                <div className="row align-items-center"> <div className="col-xl-7 col-lg-7 col-md-8 p-0 ps-0">
                        <div className="col-12 px-0">
                            <h6>DIGITAL RENTAL BACK OFFICE</h6>
                        </div>
                        <h2 className="">
                        The world's first rental <span className="first_i">back-office infrastructure</span>
                        </h2>
                        <p>
                        Automated administrative, operational & logistical rental tasks so you can focus on customers.
                        </p>
                      
                       {/* <ul className="inner-froup-list">
                        <li> <button class="custom-btn">Guest Violations</button></li>
                        <li><button class="custom-btn">Resolution Center</button></li>
                        <li><button class="custom-btn">Roadside Assistance</button></li>
                        <li><button class="custom-btn">Collections & Recovery</button></li>
                        
                       </ul> */}
                    </div>
                    
                    <div ref={containerRef} className="col-xl-5 col-lg-5 col-md-4 d-flex justify-content-center">
                      
                    </div>
                </div>
                <AnimationCards/>
               <div className="join-btn-box text-center">
                   <a class="btn btn-primary waitlist_popup1">Join the waitlist</a>
                            
           
               </div>
            </div>
          
        </section>
    );
}

export default SupportTeam;
