import react from 'react';
import './RiskManagementstyle.css';

const RiskManagement = ()=>{
    return(
        <>
        <section className='riskManagementMain' id='RealtimeGuestVerification'>
        <div className='container'>
         <div className='row align-items-center'>
            <div className='col-lg-5 col-md-12 col-sm-12 col-12'>
              <div className='riskManagementText'>
                 <h6>RISK ASSESSMENT AND VETTING TOOLS</h6>
                 <h2>Manage <span>renter risk</span> & protect your fleet </h2>
                 <p>Protect your rental fleet with advanced AI-driven identity verification and risk assessment technology tailored specifically for car rental operators. Ensure security, compliance, and operational efficiency with smarter decision-making.</p>
                 <ul>
                    <li>
                      <a href="#RealtimeGuestVerification">
                        <img width="24px" height="24px"src="/img/rent-id.svg" alt="rentid"/>
                        <span>Realtime Guest Verification</span>
                      </a>
                    </li>
                    <li>
                      <a href="#AutoInsuranceValidation">
                        <img width="24px" height="24px"src="/img/shield-energy.svg" alt="rentid"/>
                        <span>Auto Insurance Validation</span>
                      </a>
                    </li>
                    <li>
                      <a href="#DriverIQScore">
                        <img width="24px" height="24px"src="/img/math.svg" alt="rentid"/>
                        <span>DriverIQ Score</span>
                      </a>
                    </li>
                    <li>
                      <a href="#NationalBlockListDatabase">
                        <img width="24px" height="24px"src="/img/do-not-touch.svg" alt="rentid"/>
                        <span>National BlockList Database</span>
                      </a>
                    </li>
                    <li>
                      <a href="#VerificationDashboard">
                        <img width="24px" height="24px"src="/img/computer-check.svg" alt="rentid"/>
                        <span>Verification Dashboard</span>
                      </a>
                    </li>
                    <li>
                      <a href="#RiskManagement">
                        <img width="24px" height="24px"src="/img/settings.svg" alt="rentid"/>
                        <span>Risk Management</span>
                      </a>
                    </li>
                 </ul>
              </div>
           </div>
           <div className='col-lg-7 col-md-12 col-sm-12 col-12'>
              <div className='riskManagementRight'>
                <div className='riskManagementImage'>
                    <figure>
                        <img class="img-fluid" src="/img/risk-management.png" alt="liablity"/> 
                    </figure>
                    <img class="img-fluid renter_risk_back" src="/img/renter_risk_back_1.png" alt="liablity"/> 
                    <div className='riskProtect'>
                      <div className='auto-insurance'>
                          <img src="/img/featured-img1.svg" alt="liablity" />    
                          <div class='inner-text-sec'>
                            <h5>Build Trust and Loyalty</h5>
                            <p>Create a book of great rental customers.</p>
                          </div>
                      </div>
                      <div className='auto-insurance left-space-box'>
                        <img src="/img/featured-img1.svg" alt="liablity" />   
                        <div class='inner-text-sec'>
                          <h5>Enhance Rental Operations</h5>
                          <p>Accept or decline bookings based on risk profile.</p>
                        </div>
                      </div>
                      <div className='auto-insurance left-space-box1'>
                        <img src="/img/featured-img1.svg" alt="liablity" />   
                        <div class='inner-text-sec'>
                          <h5>Optimize Business Risk Management</h5>
                          <p>Earn, grow, and thrive with data-driven decisions.</p>
                        </div>
                      </div>
                  </div>
                </div> 
              </div>
               </div>
           </div>
         </div>
  
        </section>
        </>
    )
}

export default RiskManagement;