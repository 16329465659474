import react from 'react';
import './NationalBlocklist.css';

const NationalBlocklist = ()=>{
    return(
        <>
        <section className='nationalBlocklistMain' id='NationalBlockListDatabase'>
            <div className='container'>
              <div class="row stickyHeading">
                  <div class="col-md-12">
                      <div class="nationalBlocklistHeading">
                        <figure>
                          <img class="img-fluid" width="48px" height="48px" src="/img/national_Icon.svg" alt="icon"/>
                        </figure>
                        <div class="autoInsuranceText">
                          <h2>National BlockList Database</h2>
                          <p>Safeguard your staff, fleet and business.</p>
                        </div>
                      </div>
                  </div>
              </div>

              <div className='nationalBlocklistOuter'>
                <div className='row align-items-center'>
                      <div class="col-md-6">
                          <div class="nationalBlocklistLeft">
                              <h3>Our ongoing commitment...</h3>
                              <p>to maintaining a safe, risk-free platform helps you avoid high-risk renters.
                                We prioritize your peace of mind by equipping you with the tools needed to safeguard your operations.</p>
                          </div>
                      </div>
                    
                      <div class="col-md-6">
                          <div class="nationalBlocklistRight">
                              <img class="img-fluid" src="/img/our_ongoing.png" alt="img"/>
                          </div>
                      </div>
                  </div>
              </div>

              <div className='nationalBlocklistOuter'>
                <div className='row align-items-center'>
                      <div class="col-md-6">
                          <div class="nationalBlocklistLeft">
                              <h3>Our comprehensive block list...</h3>
                              <p>Enables you to identify and exclude individuals who may pose a threat to your business, including: </p>

                              <ul>
                                <li>
                                  <img class="img-fluid" width="16px" height="16px" src="/img/check_black.svg" alt="icon"/>
                                  <span>Flagged for fraud</span>
                                </li>
                                <li>
                                  <img class="img-fluid" width="16px" height="16px" src="/img/check_black.svg" alt="icon"/>
                                  <span>Unpaid dues</span>
                                </li>
                                <li>
                                  <img class="img-fluid" width="16px" height="16px" src="/img/check_black.svg" alt="icon"/>
                                  <span>Vehicle damage</span>
                                </li>
                                <li>
                                  <img class="img-fluid" width="16px" height="16px" src="/img/check_black.svg" alt="icon"/>
                                  <span>Policy violations</span>
                                </li>
                                <li>
                                  <img class="img-fluid" width="16px" height="16px" src="/img/check_black.svg" alt="icon"/>
                                  <span>Rental bans</span>
                                </li>
                                <li>
                                  <img class="img-fluid" width="16px" height="16px" src="/img/check_black.svg" alt="icon"/>
                                  <span>Reckless driving</span>
                                </li>
                                <li>
                                  <img class="img-fluid" width="16px" height="16px" src="/img/check_black.svg" alt="icon"/>
                                  <span>Abusive behavior</span>
                                </li>
                              </ul>
                          </div>
                      </div>
                    
                      <div class="col-md-6">
                          <div class="nationalBlocklistRight">
                              <img class="img-fluid" src="/img/our_comprehensive.png" alt="img"/>
                          </div>
                      </div>
                  </div>
              </div>

              <div className='nationalBlocklistOuter'>
                <div className='row align-items-center'>
                      <div class="col-md-6">
                          <div class="nationalBlocklistLeft">
                              <h3>When flagged renters attempt ...</h3>
                              <p>to book we’ll notify you immediately. Protect your business and ensure compliance with 
                                smarter data that keeps your operation secure, safe and sustainable. </p>
                          </div>
                      </div>
                    
                      <div class="col-md-6">
                          <div class="nationalBlocklistRight">
                              <img class="img-fluid" src="/img/when_flagged.png" alt="img"/>
                          </div>
                      </div>
                  </div>
              </div>
              
            </div>
        </section>
        </>
    )
}

export default NationalBlocklist;