import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";

const CreatePassword = () => {


        const [showPassword, setShowPassword] = useState(false);
      
        // Validation Schema
        const validationSchema = Yup.object({
          emailOrPhone: Yup.string()
            .required("Email or Phone number is required")
            .matches(
              /^(\+?[0-9]{7,15}|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
              "Enter a valid email or phone number"
            ),
          password: Yup.string()
            .required("Password is required")
            .min(6, "Password must be at least 6 characters"),
        });
      
        // Initial Values
        const initialValues = {
          emailOrPhone: "",
          password: "",
        };
      
        // Submit Handler
        const onSubmit = (values) => {
          console.log("Form Data", values);
          // Handle the login logic here
        };

  
    return (
        <>
            <div className="commonHostMain">
                <div className="commonHostOuter">
                    <h2>Create Password</h2>
                    <div className="createPassowrdInner">
                        <div className="host_form">
                            <div className="mb-4">
                                <label htmlFor="password" className="form-label">
                                    Password<span class="required">*</span>
                                </label>
                                <div className="input-group">
                                    
                                    <Field
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    className='form-control'
                                    placeholder="Your password"
                                    />
                                    { <button
                                    type="button"
                                    className="btn btn-outline-secondary password-toggle"
                                    onClick={() => setShowPassword(!showPassword)}
                                    > 
                                    <img
                                        src='/img/view-off.svg'
                                        alt="Logo"
                                        className="d-inline-block align-text-top"
                                        style={{ height: "40px" }}
                                        />
                                    </button> }
                                </div>
                                <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="password" className="form-label">
                                    Confirm Password<span class="required">*</span>
                                </label>
                                <div className="input-group">
                                    
                                    <Field
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    className='form-control'
                                    placeholder="Confirm password"
                                    />
                                    { <button
                                    type="button"
                                    className="btn btn-outline-secondary password-toggle"
                                    onClick={() => setShowPassword(!showPassword)}
                                    > 
                                    <img
                                        src='/img/view-off.svg'
                                        alt="Logo"
                                        className="d-inline-block align-text-top"
                                        style={{ height: "40px" }}
                                        />
                                    </button> }
                                </div>
                                <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>
                            <div className="errorMessage">
                                <h4>Your password must contains</h4>
                                <ul>
                                    <li>
                                        <img width="14px" height="14px" src="/img/check_green.svg" alt="check"/>
                                        <span>At least 8 characters</span>
                                    </li>
                                    <li>
                                        <img width="14px" height="14px" src="/img/check_green.svg" alt="check"/>
                                        <span>At least 3 of the followings:</span>
                                    </li>
                                    <li>
                                        <img width="14px" height="14px" src="/img/check_green.svg" alt="check"/>
                                        <span>Lower case letters (a-z)</span>
                                    </li>
                                    <li>
                                        <img width="14px" height="14px" src="/img/check_green.svg" alt="check"/>
                                        <span>Upper case letters (A-Z)</span>
                                    </li>
                                    <li>
                                        <img width="14px" height="14px" src="/img/cross_red.svg" alt="cross"/>
                                        <span>Numbers (0-9)</span>
                                    </li>
                                    <li>
                                        <img width="14px" height="14px" src="/img/cross_red.svg" alt="cross"/>
                                        <span>Special characters (e.g. !@#$%^&*)</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreatePassword