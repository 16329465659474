import React, { useState, useContext } from 'react'
import './Insurance.css'
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import qs from 'qs';
import axios from 'axios';
import { UserContext } from '../../context/FormDataContext.js';
import { useNavigate } from 'react-router-dom';


function Insurance() {
const navigate = useNavigate();



    return (
        <>
            <section id="coverages" className="coverage">
                <div className="container-fluid pe-0">
                    <div className="row">
                    
                        <div className="col-xl-6 col-lg-6 col-md-6 px-lg-0 px-3 coverage-heading">
                            <h2>
                                Primary A-rated commercial
                                auto insurance coverages
                            </h2>
                            <p className="sub-head pt-0 mb-3">Peace of mind exclusively available for your fleet</p>

                            <div className="row pt-5">
                                <div className="col-md-1 col-2 ps-0 pe-0">
                                    <img src="/img/state-mandatory.svg" className="img-fluid" width="48" alt="Mandatory Insurance" />
                                </div>
                                <div className="col-md-11 col-10 ps-0">
                                    <h4 className="pb-1 px-lg-3">State Mandatory Liability Insurance</h4>
                                    <p className='px-lg-3 covarege-paragraph'>
                                        Stay compliant and protected with liability insurance  covering bodily injury and
                                        property damage to others.
                                    </p>
                                </div>
                            </div>

                            <div className="row pt-5 pb-lg-3 pb-0">
                                <div className="col-md-1 col-2 ps-0 pe-0 ">
                                    <img src="/img/physical-damage.svg" className="img-fluid" width="48" alt="Physical Damage Insurance" />
                                </div>
                                <div className="col-md-11 col-10 ps-0">
                                    <h4 className="pb-1 px-lg-3">Physical Damage Insurance</h4>
                                    <p className='px-lg-3 covarege-paragraph'>
                                        Protect your vehicle from unexpected damage, including collisions, theft, and natural
                                        disasters.
                                    </p>
                                </div>
                            </div>

                            <div className="row waitlist pb-0">
                                <div className="col-md-12 px-1">
                                    <button type="button" className="btn btn-dark waitlist_popup1" >Join Waitlist</button>
                                    <p className="sub-text">* For qualified vehicles only.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 coverage-image">
                        <img src="/img/coverage-logo.svg"  width="48" alt="" className="d-md-block d-none" />
                        <div class="rentid_car">
                        {/* <img src="/img/rentidcar.jpg" /> */}
                        </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="insurance" className="">
                <div className="container">
                    <div className="row align-items-center companies rounded-5 mx-0">
                        <div className="col-xl-5 col-lg-5 col-md-5">
                            <h3 className="mb-3">
                            Insurance Coverage 
                            for Rideshare & Delivery Drivers
                            </h3>
                            <p className="sub-head">Rent confidently to gig-economy drivers</p>
                            <p className="pt-3">
                                  Our insurance coverage ensures that both you and your drivers are protected, allowing you to confidently expand your rental business. Elevate your service and attract  more drivers by offering a secure and reliable  rental experience.
                            </p>
                        </div>

                        <div className="col-xl-7 col-lg-7 col-md-7 text-center company-logo">
                            <div className="row ">
                                <div className="col-md-4 col-6 text-center">
                                    <img src="/img/uber.webp" alt="Uber" />
                                </div>
                                <div className="col-md-4 col-6 text-center">
                                    <img src="/img/postmates.webp" alt="Postmates" />
                                </div>
                                <div className="col-md-4 col-6 text-center">
                                    <img src="/img/instacart.webp" alt="Instacart" />
                                </div>

                                <div className="col-md-4 col-6 text-center">
                                    <img src="/img/doordash.webp" alt="DoorDash" />
                                </div>
                                <div className="col-md-4 col-6 text-center">
                                    <img src="/img/lyr.webp" alt="Lyft" />
                                </div>
                                <div className="col-md-4 col-6 text-center">
                                    <img src="/img/ubereats.webp" alt="Uber Eats" />
                                </div>

                                <div className="col-md-4 col-6 text-center">
                                    <img src="/img/grubhub.webp" alt="Grubhub" />
                                </div>
                                <div className="col-md-4 col-6 text-center">
                                    <img src="/img/gopuff.webp" alt="Gopuff" />
                                </div>
                                <div className="col-md-4 col-12 text-center">
                                    <img src="/img/caviar.webp" alt="Caviar" style={{ width: '170px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
            </section>
        </>
    )
}

export default Insurance