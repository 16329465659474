import react from 'react';
import './PrimaryCommericialSub.css';
import RentHeader from '../../RentHeader/RentHeader';
import BannerCommericial from './BannerCommericial/BannerCommericial';
import OnRent from './OnRent/OnRent';
import OffRentInsurance from './OffRentInsurance/OffRentInsurance';
import BoostEarnings from './BoostEarnings/BoostEarnings';

import MoreSolutions from '../../Rentid2/MoreSolutions/MoreSolutions';
import PopularTopics from '../../Rentid2/PopularTopics/PopularTopics';
import Footer from '../../Footer/Footer';
 

const PrimaryCommericialSub = ()=>{
    return(
        <>
          <RentHeader/>
          <BannerCommericial/>
          <OnRent/>
          <OffRentInsurance/>
          <BoostEarnings/>
          <MoreSolutions/>
          <PopularTopics/> 
          <Footer/>
        </>
    )
}

export default PrimaryCommericialSub;