import react from 'react';
import React, { useEffect } from 'react';
import Slider from 'react-slick';  // Import the React Slick Slider
import Insurancestyle from './ClaimsProcessing.css';
const PrevArrow = (props) => (
    <button {...props} className="slick-prev">
      <img src="/img/rentshield/claims_arrow_left.svg" alt="Previous" width="48" height="48" />
    </button>
  );
  
  const NextArrow = (props) => (
    <button {...props} className="slick-next">
      <img src="/img/rentshield/claims_arrow_right.svg" alt="Next" width="48" height="48" />
    </button>
  );


const ClaimsProcessing = ()=>{

    const settings = {
        slidesToShow: 6,
        slidesToScroll: 1,
        margin: 30,
        autoplay: true,
        autoplaySpeed: 2000,
        loop: true,
        dots: false,
        arrows: true,
        focusOnSelect: true,
        centerPadding: '30px',
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 5, // Correcting the key to 'slidesToShow'
              infinite: false,
              centerPadding: "30px",
              margin: 30,
            },
          },
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 4, // Correcting the key to 'slidesToShow'
              infinite: false,
              margin: 30,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3, // Correcting the key to 'slidesToShow'
              margin: 20,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3, // Correcting the key to 'slidesToShow'
            },
          },
          {
            breakpoint: 699,
            settings: {
              slidesToShow: 2.9, // Correcting the key to 'slidesToShow'
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 2.2, // Correcting the key to 'slidesToShow'
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2, // Correcting the key to 'slidesToShow'
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1.9, // Correcting the key to 'slidesToShow'
            },
          },
          {
            breakpoint: 400,
            settings: {
              slidesToShow: 1, // Correcting the key to 'slidesToShow'
              centerPadding: "30px",
            },
          },
        ],
      };


    return(
        <>
        <section className='claimsProcessingMain'>
         <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-lg-9 col-md-12 col-sm-12 col-12'>
                    <div className='claimsProcessingHead'>
                    <img width="43px" height="43px" src="/img/rentshield/claimsProcessing_icon.svg" alt="icon"/>
                        <h2>Simplified Digital Claims Processing</h2>
                        <p>Experience hassle-free claims management with our intuitive <span>Resolution Center</span></p>
                    </div>
                </div>
              </div>
            <div className='row justify-content-center'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-12'>


                    <Slider {...settings} className="claimsProcessingOuter"> {/* Use React Slick Slider */}
                        <div class="item">
                            <div class="claimsDetail">
                                <span>01</span>
                                <h3>Real-Time Claim Submission</h3>
                                <p>File claims instantly through our user-friendly portal.</p>
                            </div>
                        </div>
                        <div class="item">
                            <div class="claimsDetail">
                                <span>02</span>
                                <h3>Digital Document Upload</h3>
                                <p>Easily upload all your necessary photos and documentation.</p>
                            </div>
                        </div> 
 
                        <div class="item">
                            <div class="claimsDetail">
                                <span>03</span>
                                <h3>Automated Status Updates</h3>
                                <p>Stay informed with real-time notifications on claim progress.</p>
                            </div>
                        </div>
                        <div class="item">
                            <div class="claimsDetail">
                                <span>04</span>
                                <h3>Adjuster  Communication</h3>
                                <p>Communicate directly for quicker resolutions.</p>
                            </div>
                        </div> 
 
                        <div class="item">
                            <div class="claimsDetail">
                                <span>05</span>
                                <h3>Integrated Repair Network</h3>
                                <p>Access our network of trusted repair facilities.</p>
                            </div>
                        </div>
                        <div class="item">
                            <div class="claimsDetail">
                                <span>06</span>
                                <h3>Express Payment and Collections</h3>
                                <p>Speed up financial transactions to minimize downtime.</p>
                            </div>
                        </div> 
                        <div class="item">
                            <div class="claimsDetail">
                                <span>07</span>
                                <h3>Comprehensive Claim History:</h3>
                                <p>Maintain organized records for future reference.</p>
                            </div>
                        </div> 
                    </Slider> {/* End of React Slick Slider */}
  
                </div>
              </div>
         </div>
        </section>
        </>
    )
}

export default ClaimsProcessing;