import React, { useCallback } from 'react';
import './HostSignup.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import HostForm from '../HostForm/Index';
import HeaderHostsignup from './HeaderHostsignup/HeaderHostsignup';

const HostSignup = () => {

    const handleContinueToBeta = () => {
        setTimeout(() => {
          const modal = new window.bootstrap.Modal(document.getElementById('exampleModal1'));
          modal.show(); 
        }, ); 
      };

  return (
    <>
    <HeaderHostsignup/>
    <div className="welcomeMain">
        <div className="container">
          <div class="row justify-content-between">
              <div class="col-lg-6 col-md-6">
                  <div class="welcomeLeft">
                      <h2><span>Welcome to</span> <img className="img-fluid" src="/img/logo_signup.svg" alt="logo"/></h2>
                      <p>The only car rental platform you’ll ever need.</p>
                      <img className="img-fluid image_signup" src="/img/image_signup.png" alt="image"/>
                  </div>
              </div>
              <div class="col-lg-5 col-md-6">
                  <div class="welcomeRight">
                    <ul>
                        <li>
                          <h3>Create an account</h3>
                          <p>Provide your information to sign up.</p>
                        </li>
                        <li>
                          <h3>Manage your settings</h3>
                          <p>We're going to ask questions about your operations and the rental processes.</p>
                        </li>
                        <li>
                          <h3>Start renting!</h3>
                          <p>Add vehicle details, pictures and pricing for one or multiple vehicles. </p>
                        </li>
                    </ul>
                    
                  </div>
              </div>
          </div>
        </div>
    </div>


    <div class="footerCustom">
          <div className="container">
                <div class="row align-items-center">
                        <div class="col-lg-12 col-md-12">
                            <div class="footerCustomInner">
                                  <div class="skipFooter">
                                      <a href="#">Skip</a> 
                                  </div> 
                                  <div class="getFooter">
                                      <button
                                          type="button"
                                          className="btn btn-radius"
                                          onClick={handleContinueToBeta}
                                        >
                                          Get Started
                                      </button> 
                                  </div> 
                            </div> 
                        </div>
                  </div>
            </div>
      </div>
    <HostForm/>
    </>
  );
};

export default HostSignup;
