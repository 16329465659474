import react from 'react';
import './MadeMarketing.css';

const MadeMarketing = ()=>{
    return(
        <>
<section className="madeMarketingMain">
    <div className="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
              <div class="headmadeMarketing">
                  <h2><span>Marketing made simple.</span> Keep renters coming back with built-in campaigns that work.</h2>
              </div>
          </div>
        </div>


        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="tab" href="#madeMarketing_1">
                    <img width="22px" height="24px" src="/img/rentdirect/most_morden_shield_icon.svg" alt="icon"/>
                    <span>Collections</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#madeMarketing_2">
                    <img width="22px" height="24px" src="/img/rentdirect/most_morden_shield_icon.svg" alt="icon"/>
                    <span>Promotions</span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#madeMarketing_3">
                    <img width="22px" height="24px" src="/img/rentdirect/most_morden_shield_icon.svg" alt="icon"/>
                    <span>Spotlight</span>
                </a>
            </li>
        </ul>
    </div>

    <div className="madeMarketingOuter">
        <div className="container">   
            <div class="tab-content">
                {/* START Tab 1 */}
                <div class="tab-pane active" id="madeMarketing_1">
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='madeMarketingLeft'>
                                <h3><span>Curate a collection of vehicles </span>for guests</h3>
                                <p>Create tailored lists for specific guests, complete with exclusive offers, 
                                booking links and expires on a date you set ensuring a seamless, customized
                                quote experience.</p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='madeMarketingRight'>
                                <figure>
                                    <img class="img-fluid" src="/img/rentdirect/madeMarketing_1.png" alt="Image"/>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>

                {/* START Tab 2 */}
                <div class="tab-pane fade" id="madeMarketing_2">
                   <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='madeMarketingLeft'>
                                <h3>Built in <span>promotional & discount</span> programs</h3>
                                <p>Create system-wide promotions with percentage discounts or usage-based codes 
                                available at checkout. You can also offer automatic daily or weekly discounts 
                                when guests book the required minimum rental duration.</p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='madeMarketingRight'>
                                <figure>
                                    <img class="img-fluid" src="/img/rentdirect/madeMarketing_2.png" alt="Image"/>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div> 

                {/* START Tab 3 */}
                <div class="tab-pane fade" id="madeMarketing_3">
                   <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='madeMarketingLeft'>
                                <h3><span>Spotlight</span> noteworthy <span>headlines & promotions</span></h3>
                                <p>Keep your brand top of mind with Spotlight. Highlight your promo or announcement and 
                                feature it prominently in your dedicated storefront. Guests see your offer whenever 
                                they explore your page, ensuring your brand stays in focus and drives engagement.</p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='madeMarketingRight'>
                                <figure>
                                    <img class="img-fluid" src="/img/rentdirect/madeMarketing_3.png" alt="Image"/>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</section>
        </>
    )
}

export default MadeMarketing;