import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import logo from '../../assets/images/logo_new.png';
import arrow_down from "../../assets/images/arrow-down-01.png"
import rentdirect from "../../assets/images/rentdirect.png";
import rend_id from "./assets/images/rent-id-logo.svg";
import rend_id2 from "../../assets/images/rend_id2.png";
import RentIdanimation from './RentIdanimation'
import RentHeader from '../RentHeader/RentHeader';
import RiskManagement from "./RiskManagement/RiskManagement";
import LoyalGuest from './LoyalGuest/LoyalGuest';
import Rentidheader from './Rentid_header/Rentidheader';
import GuestVerification from './GuestVerification/GuestVerification';
import AutoInsurance from './AutoInsurance/AutoInsurance';
import DriverIq from './DriverIq/DriverIq';
import NationalBlocklist from './NationalBlocklist/NationalBlocklist';
import VerificationDashboard from './VerificationDashboard/VerificationDashboard';
import DecisionSupport from './DecisionSupport/DecisionSupport';
import Safety from './Safety/Safety';
import MoreSolutions from './MoreSolutions/MoreSolutions';
import PopularTopics from './PopularTopics/PopularTopics';
import BetaForm from '../BetaForm';
import Footer from '../Footer/Footer';
import { useLocation } from 'react-router-dom';
const RentId2 = () => {
const navigate = useNavigate();

const location = useLocation();
console.log(location.pathname);




  return (
   <>
     <Box
        sx={{
          background: 'linear-gradient(180deg, #082C72 0%, #001132 55%, #000000 100%)',
          height: '100vh',
          width: '100%',
        }}
      >
        <Container>
        <RentHeader />


{/* Banner section start from here */}

        <section className='bannerRentIdMain'>
            <div className='row'>
                <div className='col-lg-5'>
                    <div className='bannerIdLeft'>
                    <img src={rend_id}></img>
                    <small>ADVANCED TRUST AND SAFETY TOOLS</small>
                      <h1>Outsmart risk with built-in <span>security</span> <span>measures.</span> </h1>
                      <p>Use data-driven decision-making to protect your account, vehicles, and future earnings. 
                        You alone control who gets the keys.</p>
                    </div>
                </div>

                <div className='col-lg-7'>
                    {/* <img src={rend_id2} alt=''/> */}
                <RentIdanimation />
                </div>
            </div>
        </section>
        
        </Container>
      </Box>
      {location.pathname === '/rentid1' && (
          <>
            {/* <Rentidheader /> */}
            <RiskManagement />
            <LoyalGuest />
            <BetaForm />
            <GuestVerification />
            <AutoInsurance />
            <DriverIq />
            <NationalBlocklist />
            <VerificationDashboard />
            <DecisionSupport />
            <Safety />
            <MoreSolutions />
            <PopularTopics />
            <Footer />
          </>
        )}
   </>
  )
}

export default RentId2
