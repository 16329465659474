import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import 'bootstrap/dist/css/bootstrap.min.css';

const GuestForgotEmail = () => {
    const initialValues = {
        firstname: '',
        lastname: '',
        email: '',
        country: '',
        number: '',
        password: '',
        confirmPassword: '',
    };

    const validationSchema = Yup.object({
        firstname: Yup.string().required('First name is required'),
        lastname: Yup.string().required('Last name is required'),
        email: Yup.string().email('Invalid email format').required('Email is required'),
        country: Yup.string().required('Country is required'),
        number: Yup.string()
            .matches(/^\d+$/, 'Only digits are allowed')
            .required('Phone number is required'),
        password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm password is required'),
    });

    const handleSubmit = (values) => {
        console.log('Form data:', values);
    };

    return (
        <section className="logMain">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-md-12 logLeft">
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="logRight">
                            <div className="logRightInner">
                                <h1>Forgot <span>Password</span></h1>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                        <div className="row"> 
                                            <div className="col-lg-12 col-md-12"> 
                                                <div className="mb-3 form-group">
                                                    <label htmlFor="email" className="form-label">Email</label>
                                                    <Field
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="email" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12"> 
                                                <div className="mb-3 forgotText"> 
                                                    <p> Forgot your email? <a href="#">Reset here</a></p>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12"> 
                                                <button type="submit" className="btn w-100 btnBlue" disabled={isSubmitting}>
                                                    Recover Password
                                                </button>
                                            </div>
                                        </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GuestForgotEmail;
