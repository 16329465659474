import react from 'react';
import Header from './Header';
import './HostReset.css';
import { Field, ErrorMessage } from "formik";

const RecoverPassword = () => {

    return (
        <>
            <div className="commonHostMain">
                <div className="commonHostOuter">
                    <h2>Select How You’d Like to Recover Your Password.</h2>
                    <div className="recoverPasswordInner">
                        <div className="row">
                            <div className="col-12 col-xs-12 col-md-6 p-0 pe-md-2">
                                <div className="form-check ps-0">
                                <Field
                                    type="radio"
                                    className="form-check-input "
                                    id="business_compliance"
                                    name="business_compliance"
                                    value="0"
                                />
                                <label
                                    className="form-check-label form-check-field w-100"
                                    htmlFor="business_compliance"
                                >
                                    <div className="row">
                                    <div className="col-10 ps-0">
                                        <img
                                        src="/img/email_radio_icon.svg"
                                        className="img-fluid"
                                        alt="llc"
                                        />
                                    </div>
                                    </div>
                                    <div className="row mt-3">
                                    <div className="col-12 ps-0">
                                        <p className="llc-heading mb-0">
                                        Email
                                        </p>
                                    </div>
                                    </div>
                                </label>
                                <ErrorMessage
                                    name="business_compliance"
                                    component="div"
                                    className="invalid-feedback"
                                />
                                </div>
                            </div>
                            <div className="col-12 col-xs-12 col-md-6 mt-3 mt-md-0 p-0 ps-md-2">
                                <div className="form-check ps-0">
                                <Field
                                    type="radio"
                                    className="form-check-input"
                                    id="registeredNo"
                                    name="business_compliance"
                                    value="1"
                                />
                                <label
                                    className="form-check-label form-check-field w-100"
                                    htmlFor="registeredNo"
                                >
                                    <div className="row">
                                    <div className="col-10 ps-0">
                                        <img
                                        src="/img/message_radio_icon.svg"
                                        className="img-fluid"
                                        alt="llc"
                                        />
                                    </div>
                                    </div>
                                    <div className="row mt-3">
                                    <div className="col-12 ps-0">
                                        <p className="llc-heading mb-0">Phone Number</p>
                                    </div>
                                    </div>
                                </label>
                                {/* <ErrorMessage
                                    name="business_compliance"
                                    component="div"
                                    className="invalid-feedback"
                                /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    )
}

export default RecoverPassword;