
import './SolutionSection.css'
import React, { useState, useContext } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import qs from 'qs';
import axios from 'axios';
import { UserContext } from '../../context/FormDataContext.js';
import { useNavigate } from 'react-router-dom';

function SolutionSection() {
    const [show,setShow]=useState(false);
    const navigate = useNavigate();
    const { updateUserFormData } = useContext(UserContext);
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <>
            <section id="eleven" className="perfect-solution">
                <div className="container">
                    <div className="row align-items-center reverse-columns" id='fleet'>
                        <div className="col-xl-5 col-lg-5 col-md-5 px-0 fleet-description">
                            <h6 className="">THE PERFECT SOLUTION </h6>
                            <h3>Fleet Sizes</h3>
                            <p className="">
                                Whether you're just getting started or have years of experience...we help
                                fleets ranging from one to hundreds of vehicles, across multiple branches.
                            </p>
                            <div className="solution-img-sec">
								     <img src="/img/obi-pixel.png" alt="" class="img-fluid"/>
								 </div>
                            <ul className="fleet">
                                <li>  <img src="/img/check-icon.svg" alt="" /> 5 Vehicles</li>
                                <li> <img src="/img/check-icon.svg" alt="" />5 - 25 Vehicles</li>
                                <li> <img src="/img/check-icon.svg" alt="" />25 - 100 Vehicles</li>
                                <li> <img src="/img/check-icon.svg" alt="" />100+ Vehicles</li>
                            </ul>
                        </div>

                        <div className="col-xl-7 col-lg-7 col-md-7 pb-lg-0 perfect-section-glry">
                            <div className="row">
							     <div className="col-xl-4 col-lg-4 col-md-4 pb-lg-0 perfection-inner-image1">
								     <img src="/img/obi-pixel.png" alt="" class="img-fluid"/>
								 </div>
								 
								 <div className="col-xl-4 col-lg-4 col-md-4 pb-lg-0 perfection-inner-image">
								      <img src="/img/connor.png" alt="" class="img-fluid"/>
								      <img src="/img/benjamin.png" alt="" class="img-fluid"/>
								 </div>
								 <div className="col-xl-4 col-lg-4 col-md-4 pb-lg-0 perfection-inner-image1">
								     <img src="/img/grant-richie.png" alt="" class="img-fluid"/>
								 </div>
							</div>
                        </div>
                    </div>

                    <div className="row align-items-center partners"  id='partners'>
                        <div className="col-xl-6 col-lg-6 col-md-6 px-0">
                            <div class="row">
                             <div class="col-md-6 partner-img perfection-inner-image2">
                             <img src="/img/Base-carousel-milti-image.png" className="w-100" alt="base" />
                             </div>
                             <div class="col-md-6 partner-img perfection-inner-image2">
                             <img src="/img/enhanced-image.png" className="w-100" alt="base" />
                            </div>
                            <div class="col-md-6 lets-partner perfection-inner-image2">
                            <img src="/img/Adobe-stock.png" className="w-100" alt="base" />
                              </div>
                              <div class="col-md-6 lets-partner perfection-inner-image2">
                              <img src="/img/adobestock.png" className="w-100" alt="base" />
                               </div>
                            </div>
                          
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 solution-inner-sec">
                            <div className="rightcard ">
                                <h6 className="">SOMETHING FOR EVERYONE </h6>
                                <h3>Let’s Partner</h3>
                                <p className="">
                                    We're excited to partner with entrepreneurs who are passionate about
                                    enhancing the customer experience and committed to generating revenue, efficiently.
                                </p>
                                <div class="partner-img-sec">
                             <img src="/img/Base-carousel-milti-image.png" className="w-100" alt="base" />
                             </div>
                                <ul className="fleet">
                                    <li> <img src="/img/check-icon.svg" alt="" /> Car Rental Agencies</li>
                                    <li> <img src="/img/check-icon.svg" alt="" /> Service & Repair / Auto body</li>
                                    <li> <img src="/img/check-icon.svg" alt="" /> Marketplace Hosts & Co-Hosts</li>
                                    <li> <img src="/img/check-icon.svg" alt="" /> Pre-owned and New Auto Dealerships</li>
                                    <li> <img src="/img/check-icon.svg" alt="" /> Hospitality and Parking Management Industries</li>
                                </ul>
                                <div className="col-md-12 px-1 mt-3">
                                    <button type="button" id="join_wait" className="btn btn-dark waitlist_popup1" >Join The Waitlist</button>
                                </div>

                

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SolutionSection