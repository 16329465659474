import react from 'react';
import React, { useState } from "react";


const Verification = () => {
    const [otp, setOtp] = useState(new Array(6).fill(""));

    const handleChange = (element, index) => {
      if (!/^[0-9]*$/.test(element.value)) return;
  
      const newOtp = [...otp];
      newOtp[index] = element.value;
      setOtp(newOtp);
  
      // Move focus to the next input
      if (element.value && index < otp.length - 1) {
        element.nextSibling?.focus();
      }
    };
  
    const handleKeyDown = (e, index) => {
      if (e.key === "Backspace" && !otp[index] && index > 0) {
        e.preventDefault();
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        e.target.previousSibling?.focus();
      }
    };
  
    return (
        <>
        <div className="commonHostMain">
            <div className="commonHostOuter verificationOuter">
            <h2>Enter the code we sent to you by SMS to 908XXXXXXX</h2>
            {/* <h2>Thanks! We are verifying your details</h2> */}
                <div className="otp-inputs">
                    {otp.map((value, index) => (
                        <input
                        key={index}
                        type="text"
                        maxLength="1"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        className="otp-box"
                        value={value}
                        onChange={(e) => handleChange(e.target, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        required
                        />
                    ))}
                </div>
                <p>Didn’t receive our SMS? <span>Resend the code.</span></p>
                <div class="loaderOuter">
                    <div class="loader"></div>
                    <p>Verifying...</p>
                </div>
            </div>
        </div>
        </>
    )
}

export default Verification