import react from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Insurancestyle from './GuestProtection.css';

const GuestProtection = ()=>{
    return(
        <>
        <section className='guestProtectionlMain' id='GuestProtectionPlans'>
 
         <div className='container'>
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                    <div className='guestProtectionhead'>
                        <h2>Guest Protection Plans <span> safeguard every renter and trip</span></h2>
                    </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                    <div className='guestProtectionInner'>
                        {/* <div className='guestProtectionTab'>
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#view">View Plans</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#earn">Earn Profit</a>
                                </li>
                            </ul>
                        </div> */}
                        <p className="plansPara">Choose a Rentid Protection Plan <span>up until the trip start time online or from the app.</span> 
                            Our plans provide peace of mind by covering unexpected incidents ensuring confidence for every rental.
                        </p>
                        <div class="tab-content">
                            <div class="tab-pane active" id="view">
                                <div className='row justify-content-center'>
                                    <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                                        <div className='plansInner'>
                                            <img class="img-fluid" src="/img/rentshield/tick_01.svg" alt="icon"/>
                                            <h3>Basic<span> Protection</span></h3>
                                            <p>Does not cover damage to your vehicle</p>
                                            <h4> State Mandated Liability Coverage </h4>
                                            <ul>
                                                <li>Property Damage to others vehicle</li>
                                                <li>Property Damage to others property.</li>
                                                <li>Bodily Injury to other persons.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                                        <div className='plansInner'>
                                            <img class="img-fluid" src="/img/rentshield/tick_01.svg" alt="icon"/>
                                            <h3>Premiur<span> Protection</span></h3>
                                            <p>Covers damage to your vehicle</p>
                                            <h4>Liability + Physical Damage Coverage</h4>
                                            <ul>
                                                <li>State required liability insurance</li>
                                                <li>Physical damage coverage for up to $110,000 or ACV*</li>
                                                <li>Supplemental Liability Protection up to $1 million</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="tab-pane fade" id="earn">
                                <div className='row justify-content-center'>
                                    <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                                        <div className='plansInner'>
                                            <img class="img-fluid" src="/img/rentshield/tick_01.svg" alt="icon"/>
                                            <h3>Basic<span> Protection</span></h3>
                                            <p>Does not cover damage to your vehicle</p>
                                            <h4> State Mandated Liability Coverage </h4>
                                            <ul>
                                                <li>Property Damage to others vehicle</li>
                                                <li>Property Damage to others property.</li>
                                                <li>Bodily Injury to other persons.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                                        <div className='plansInner'>
                                            <img class="img-fluid" src="/img/rentshield/tick_01.svg" alt="icon"/>
                                            <h3>Premiur<span> Protection</span></h3>
                                            <p>Covers damage to your vehicle</p>
                                            <h4>Liability + Physical Damage Coverage</h4>
                                            <ul>
                                                <li>State required liability insurance</li>
                                                <li>Physical damage coverage for up to $110,000 or ACV*</li>
                                                <li>Supplemental Liability Protection up to $1 million</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div class="planBtn">
                        <p className="plansPara_2">Deductibles for physical damage claims range from  $2500.00 to $5000.00 out of pocket.</p> 
                        <a href="#" class="btn">Get Started <img src="/img/rentshield/arrow_white.svg" alt="icon"/></a>
                        </div>
                    </div>
                </div>
              </div>

         </div>
        </section>
        </>
    )
}

export default GuestProtection;