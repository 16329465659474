// GeneralInfo.js
import React, { useState, useEffect , useContext} from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import 'react-phone-number-input/style.css'
import { UserContext } from "../../context/FormDataContext";
const ProfilePicture = () => {
  return (
    <div className="commonHostMain">
      <div className="commonHostOuter profilePictureOuter">
        <h2>Upload a photo for host portal profile.</h2>
        <small>Preview</small>
        <div className="profilePictureInner">
            <div className="profilePictureLeft">
              <figure><img className="img-fluid" src="/img/user_image.jpg" alt="logo"/></figure>
            </div>
            <div className="profilePictureRight">
                <input type="file" name="myfile"/>
                <img width="32px" height="32px" src="/img/upload_icon.svg" alt="upload"/>
                <p>Upload Profile Photo</p>
            </div>
        </div>
        <div class="alert alert-info alert-dismissible">
            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
            <img width="24px" height="24px" src="/img/info_icon.svg" alt="upload"/> 
            You can skip this step for now and add it later in the account settings.
        </div>
    </div>
  </div>
  );
};

export default ProfilePicture;
