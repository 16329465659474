import react from 'react';
import './FinancialManagement.css';

const FinancialManagement = ()=>{
    return(
        <>
<section className="financialManagementsMain">
    <img class="financialManagements_1" src="/img/rentdirect/financialManagements_1.svg" alt="img"/>
    <div className="container">
        <div class="row justify-content-center">
          <div class="col-lg-9">
              <div class="headFinancialManagements">
                  <h2>Financial Management</h2>
                  <p>Easily manage transactions with solutions that support your rental business.</p>
              </div>
          </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#financialManagements_1">
                            <img width="22px" class="img_simple" height="18px" src="/img/rentdirect/most_morden_shield_icon.svg" alt="icon"/>
                            <img width="22px" class="img_active" height="18px" src="/img/rentdirect/most_morden_shield_icon_active.svg" alt="icon"/>
                            <span>Dynamic Invoices </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#financialManagements_2">
                            <img width="22px" class="img_simple" height="18px" src="/img/rentdirect/most_morden_shield_icon.svg" alt="icon"/>
                            <img width="22px" class="img_active" height="18px" src="/img/rentdirect/most_morden_shield_icon_active.svg" alt="icon"/>
                            <span>Dynamic Agreements</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#financialManagements_3">
                            <img width="22px" class="img_simple" height="18px" src="/img/rentdirect/most_morden_shield_icon.svg" alt="icon"/>
                            <img width="22px" class="img_active" height="18px" src="/img/rentdirect/most_morden_shield_icon_active.svg" alt="icon"/>
                            <span>Payment Methods</span>
                        </a>
                    </li>
                </ul>
            
                <div class="tab-content">
                    {/* START Tab 1 */}
                    <div class="tab-pane active" id="financialManagements_1">
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                                <div className='financialManagementsLeft'>
                                    <figure>
                                        <img class="img-fluid" src="/img/rentdirect/financialManagements_1.png" alt="Img"/>
                                    </figure>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                                <div className='financialManagementsRight'>
                                    <img width="48px" height="48px" src="/img/rentdirect/dynamic_icon.svg" alt="star"/>
                                    <h3>Dynamic Invoices</h3>
                                    <p>Rentid's dynamic invoices use real-time booking data from your guests and your customizations, ensuring every 
                                        invoice is accurate, tailored, and up-to-date. No more paperwork – everything is digitally 
                                        organized in your portal, making it easy to manage payments and maintain financial records.</p>
                                    <ul>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Real-Time Customization:</strong> Invoices automatically adjust based on booking details like dates, rates, fees, and add-ons.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Efficient Management:</strong> Eliminate manual data entry, saving time and minimizing errors.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Clear Documentation:</strong> Provide guests with accurate, itemized invoices for a professional experience.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Organized Records:</strong> Keep all invoices accessible in your portal for easy tracking and financial management.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* START Tab 2 */}
                    <div class="tab-pane fade" id="financialManagements_2">
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                                <div className='financialManagementsLeft'>
                                    <figure>
                                        <img class="img-fluid" src="/img/rentdirect/financialManagements_2.png" alt="Customer Relationship"/>
                                    </figure>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                                <div className='financialManagementsRight'>
                                    <img width="48px" height="48px" src="/img/rentdirect/dynamic_icon.svg" alt="star"/>
                                    <h3>Dynamic Rental Agreements</h3>
                                    <p>Rentid's dynamic rental agreements automatically adapt to each booking, ensuring every agreement is precise, customized, and compliant. 
                                        Everything is stored digitally in your portal for easy access and management.</p>
                                        <h4>Refund Steps</h4>
                                    <ul>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Tailored to Every Booking:</strong> Agreements update in real time with details like rental dates, terms, and fees.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Professional Communication:</strong> Ensure guests receive clear, consistent agreements for transparency.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Compliance and Trust:</strong> Built to adhere to platform standards, providing a seamless and trustworthy experience.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Digital Accessibility:</strong> Manage all agreements in one place, reducing paperwork and improving organization.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> 

                    {/* START Tab 3 */}
                    <div class="tab-pane fade" id="financialManagements_3">
                    <div className='row align-items-center'>
                            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                                <div className='financialManagementsLeft'>
                                    <figure>
                                        <img class="img-fluid" src="/img/rentdirect/financialManagements_3.png" alt="Customer Relationship"/>
                                    </figure>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                                <div className='financialManagementsRight'>
                                    <img width="48px" height="48px" src="/img/rentdirect/payment_icon.svg" alt="star"/>
                                    <h3>Payment Methods</h3>
                                    <p>Gain valuable insights into your business with our powerful analytics and reporting tools. Make informed, 
                                        data-driven decisions by accessing real-time metrics and customizable reports. Understand trends, 
                                        identify opportunities for improvement, and forecast future performance to drive sustainable growth.</p>
                                    <ul>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Real-Time Performance Metrics:</strong> Stay updated on key indicators.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Customizable Dashboards:</strong> Tailor views to focus on what matters most.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Fleet Utilization Reports:</strong> Optimize vehicle usage and availability.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_white.svg" alt="icon"/>
                                            <span><strong>Revenue Analysis:</strong> Understand financial trends and patterns.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> 
                    {/* END Tab 3 */}
    

                </div>
            </div>
        </div>
    </div>
</section>
        </>
    )
}

export default FinancialManagement;