import React, { useContext } from 'react';
import './Thankyou.css'
import { useLocation } from 'react-router-dom';
import { UserContext } from "../../context/FormDataContext";
import thankyou from "../../assets/images/thankyou.gif"
const ThankYou = () => {
  const location = useLocation();
  const { firstName, lastName } = location.state || {};

  console.log("BetaThankyou", location.state);
  const { userData } = useContext(UserContext);
  console.log("fsadfdsa",userData)

  return (
    <div>
      <main className="main thankyouMain">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-xs-12 col-md-12 col-lg-4 p-0">
              <div className="banner-img">
                <div className="row">
                  <div className="col-12 p-0">
                    <div className="row">
                      <div className="col-12 p-md-0 main-logo">
                        <a href="/">
                        <img
                          src="/img/beta-signup/logo.png"
                          className="img-fluid d-none d-lg-block"
                          alt="feature img"
                        />
                        </a>
                        <a href="/">
                        <img
                          src="/img/beta-signup/mobile-logo.png"
                          className="img-fluid  d-lg-none"
                          alt="feature img"
                        />
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 p-md-0 mt-5 mt-md-0">
                
                        <div className="row details-section">
                          <div className="col-12 p-0 px-md-3">
                            <p className="quote">
                                “A journey is best measured in friends rather than miles.” <br/><br/>
                                -Tim Cahill
                            </p>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xs-12 col-md-12 col-lg-8 p-0">
              <a href="/">
            <img
                src="/img/beta-signup/Close-X.png"
                className="img-fluid cross-icon"
                alt="close"
              />
              </a>
              <div className="row center-row align-items-center">
                
                <div className="col-10 text-md-center mx-auto">
                    <div className="row">
                      <div className="col-12 p-md-0">
                        <div className="img-section text-center">
                          {/* <img
                            src="/img/beta-signup/Featured.png"
                            className="img-fluid"
                            alt="feature img"
                          /> */}
                          <img src={thankyou} alt='thank'/>
                        </div>
                        <p className="thanks-congrats-text pt-2">
                            <span>Congratulations</span> 
                        </p>
                        <div className="row details-section">
                          <div className="col-12 p-0 px-md-3">
                            <p className="thanks-user-name">
                              {/* [[Juan Jose Lizcano]] [[Rental Brand LA ]] */}
                              {userData.firstName} {userData.lastName} <br/>
                              {/* Rental Brand  */}
                            </p>
                            <p className="thank-you-text">
                              Thank you for joining our waitlist!
                            </p>
                            <p className="thanks-desc-notes mb-2 mb-md-5">
                              We're excited to have you on this journey with us!
                              Please check your email for a confirmation and
                              further details. We'll keep you updated with the
                              latest news and developments as we get closer to
                              launch.{" "}
                            </p>
                            <p className="thanks-desc-notes mb-3">
                              In the meantime, feel free to share Rentid with
                              your friends - We can't wait to welcome everyone!
                            </p>
                            <h3 className="thanks-rent-team">-Team Rentid</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  {/* <button
                    type="button"
                    class="btn btn-radius"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Continue to beta
                  </button> */}
                </div>
               
              </div>
              <div className="row footer-section">
                <div className="col-12 text-center">
                  <p>
                    <span>
                      <img
                        src="/img/beta-signup/elements.png"
                        className="img-fluid"
                        alt="copy right"
                      />
                      2024 Rentid Inc.
                    </span>
                    <span className="ms-3 footer-link">
                    <a href="terms" target='_blank'><u>Legal</u></a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default ThankYou
