import * as Yup from 'yup';
let ppt = true;
export const validationSchemas = [
  
  Yup.object({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    phone_number: Yup.string()
      .required('Phone number is required'),
      // .matches(
      //   /^\(\d{3}\) \d{3}-\d{4}$/,
      //   'Phone number is required'
      // ),
    // email_address: Yup.string().email('Invalid email address').required('Email is required'),
    // email_address: Yup.string().email('Email address is not in the correct format please enter it correctly').required('Email is required'),
    email_address: Yup.string()
      .email('Email address is not in the correct format please enter it correctly')
      .required('Email is required')
      .test('has-period-after-at', 'Domain part must include a period.', value => {
        if (!value) return false;
        const [localPart, domainPart] = value.split('@');
        return domainPart && domainPart.includes('.');
      }),
    state: Yup.string().required('State is required'),
    fleet_size: Yup.string().required('Fleet size is required'),
  })
]