import react from 'react';
import './FleetManagementSystem.css';

const FleetManagementSystem = ()=>{
    return(
        <>
<section className="fleetManagementMain">
    <img class="fleet_1" src="/img/rentdirect/fleet_1.svg" alt="img"/>
    <div className="container">
        <div class="row justify-content-center">
          <div class="col-lg-9">
              <div class="headfleetManagement">
                  <h2>Fleet Management Center</h2>
                  <p>Manage your fleet with tools designed to handle every detail. From tracking vehicles 
                    to resolving issues, you’re equipped for smooth operations.</p>
              </div>
          </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#fleetManagement_1">
                            <img width="22px" height="24px" src="/img/rentdirect/most_morden_shield_icon.svg" alt="icon"/>
                            <span>Booking Data & Calendar</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#fleetManagement_2">
                            <img width="22px" height="24px" src="/img/rentdirect/most_morden_shield_icon.svg" alt="icon"/>
                            <span>Branch Fleet Manager</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#fleetManagement_3">
                            <img width="22px" height="24px" src="/img/rentdirect/most_morden_shield_icon.svg" alt="icon"/>
                            <span>Vehicle Profile Manager </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#fleetManagement_4">
                            <img width="22px" height="24px" src="/img/rentdirect/most_morden_shield_icon.svg" alt="icon"/>
                            <span>Telematics & Tracking</span>
                        </a>
                    </li>
                </ul>
            
                <div class="tab-content">
                    {/* START Tab 1 */}
                    <div class="tab-pane active" id="fleetManagement_1">
                        <div className='row align-items-center'>
                            <div className='col-lg-5 col-md-12 col-sm-12 col-12'>
                                <div className='fleetManagementLeft'>
                                    <h3>Booking Data & Calendar</h3>
                                    <p>Effortlessly manage bookings, vehicle availability, and maintenance schedules all in one place.</p>
                                    <ul>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_fleet.svg" alt="icon"/>
                                            <span>Instantly identify the status of current and upcoming vehicle events.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_fleet.svg" alt="icon"/>
                                            <span>Easily mark vehicles unavailable for routine maintenance or repairs.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_fleet.svg" alt="icon"/>
                                            <span>Click any event to quickly access details, approve requests, or make changes.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-12 col-sm-12 col-12'>
                                <div className='fleetManagementRight'>
                                    <figure>
                                        <img class="img-fluid" src="/img/rentdirect/fleet_management_1.png" alt="Fleet Management"/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* START Tab 2 */}
                    <div class="tab-pane fade" id="fleetManagement_2">
                        <div className='row align-items-center'>
                            <div className='col-lg-5 col-md-12 col-sm-12 col-12'>
                                <div className='fleetManagementLeft'>
                                    <h3>Fleet Manager</h3>
                                    <p>Gain complete control and visibility over your vehicles, ensuring every aspect of your 
                                    operations runs smoothly.</p>
                                    <ul>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_fleet.svg" alt="icon"/>
                                            <span>Access real-time rental insights into every vehicle</span></li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_fleet.svg" alt="icon"/>
                                            <span>Optimize operations and schedule maintenance proactively</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_fleet.svg" alt="icon"/>
                                            <span>Ensure your fleet is always ready to meet customer demand.</span>
                                        </li>
                                    </ul>
                                   
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-12 col-sm-12 col-12'>
                                <div className='fleetManagementRight'>
                                    <figure>
                                        <img class="img-fluid" src="/img/rentdirect/fleet_management_2.png" alt="Fleet Management"/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div> 

                    {/* START Tab 3 */}
                    <div class="tab-pane fade" id="fleetManagement_3">
                        <div className='row align-items-center'>
                            <div className='col-lg-5 col-md-12 col-sm-12 col-12'>
                                <div className='fleetManagementLeft'>
                                    <h3>Vehicle Profile Manager</h3>
                                    <p>Showcase your vehicles in the best light and maintain complete control over individual listings.</p>
                                    <ul>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_fleet.svg" alt="icon"/>
                                            <span>Set specific requirements such as trip duration, coverage minimums, and distance limits. </span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_fleet.svg" alt="icon"/>
                                            <span> Define cancellation rules that align with your business goals while keeping renters informed.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_fleet.svg" alt="icon"/>
                                            <span> Tailor pricing for different seasons, holidays, or booking durations to stay competitive.</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_fleet.svg" alt="icon"/>
                                            <span> Define custom pickup and drop-off locations to enhance convenience for renters. </span>
                                        </li>
                                    </ul>
                                   
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-12 col-sm-12 col-12'>
                                <div className='fleetManagementRight'>
                                    <figure>
                                        <img class="img-fluid" src="/img/rentdirect/fleet_management_3.png" alt="Fleet Management"/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div> 
                    {/* START Tab 4 */}
                    <div class="tab-pane fade" id="fleetManagement_4">
                        <div className='row align-items-center'>
                            <div className='col-lg-5 col-md-12 col-sm-12 col-12'>
                                <div className='fleetManagementLeft'>
                                    <h3>Telematics & Tracking</h3>
                                    <p>Stay connected to your fleet like never before with  telematics and tracking integrations.</p>
                                    <ul>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_fleet.svg" alt="icon"/>
                                            <span>Monitor each vehicle’s location, status and activity from a centralized dashboard</span>
                                        </li>
                                        <li>
                                            <img width="24px" height="24px" src="/img/rentdirect/check_fleet.svg" alt="icon"/>
                                            <span>Access driving behavior such as speed, braking, and acceleration to promote safe vehicle use.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-12 col-sm-12 col-12'>
                                <div className='fleetManagementRight'>
                                    <figure>
                                        <img class="img-fluid" src="/img/rentdirect/fleet_management_4.png" alt="Fleet Management"/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
        </>
    )
}

export default FleetManagementSystem;