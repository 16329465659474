import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import 'bootstrap/dist/css/bootstrap.min.css';

const GuestSignup = () => {
    const initialValues = {
        firstname: '',
        lastname: '',
        email: '',
        country: '',
        number: '',
        password: '',
        confirmPassword: '',
    };

    const validationSchema = Yup.object({
        firstname: Yup.string().required('First name is required'),
        lastname: Yup.string().required('Last name is required'),
        email: Yup.string().email('Invalid email format').required('Email is required'),
        country: Yup.string().required('Country is required'),
        number: Yup.string()
            .matches(/^\d+$/, 'Only digits are allowed')
            .required('Phone number is required'),
        password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm password is required'),
    });

    const handleSubmit = (values) => {
        console.log('Form data:', values);
    };

    return (
        <section className="logMain">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-md-12 logLeft">
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="logRight">
                            <div className="logRightInner">
                                <h1>Register With <span>Rentid</span></h1>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                        <div className="row"> 
                                            <div className="col-lg-6 col-md-6"> 
                                                <div className="mb-3 form-group">
                                                <label htmlFor="firstname" className="form-label">First Name</label>
                                                <Field
                                                    type="text"
                                                    id="firstname"
                                                    name="firstname"
                                                    className="form-control"
                                                />
                                                <ErrorMessage name="firstname" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6"> 
                                                <div className="mb-3 form-group">
                                                    <label htmlFor="lastname" className="form-label">Last Name</label>
                                                    <Field
                                                        type="text"
                                                        id="lastname"
                                                        name="lastname"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="lastname" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12"> 
                                                <div className="mb-3 form-group">
                                                    <label htmlFor="email" className="form-label">Email</label>
                                                    <Field
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="email" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12"> 
                                                <div className="mb-3 form-group">
                                                    <label htmlFor="number" className="form-label">Phone Number</label>
                                                    <Field
                                                        type="text"
                                                        id="number"
                                                        name="number"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="number" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-12"> 
                                                <div className="mb-3 form-group">
                                                    <label htmlFor="password" className="form-label">Password</label>
                                                    <Field
                                                        type="password"
                                                        id="password"
                                                        name="password"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="password" component="div" className="text-danger" />
                                                    <div class="errorMessageGuest d-none">
                                                        <ul>
                                                            <li>
                                                                <img width="14px" height="14px" src="/img/check_green.svg" alt="check"/>
                                                                <span>At least 8 characters</span>
                                                            </li>
                                                            <li>
                                                                <img width="14px" height="14px" src="/img/check_green.svg" alt="check"/>
                                                                <span>At least 1 uppercase letter</span>
                                                            </li>
                                                            <li>
                                                                <img width="14px" height="14px" src="/img/check_green.svg" alt="check"/>
                                                                <span>At least 1 lowercase letter</span>
                                                            </li>
                                                            <li>
                                                                <img width="14px" height="14px" src="/img/check_green.svg" alt="check"/>
                                                                <span>At least 1 lowercase Number</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-12"> 
                                                <div className="mb-3 form-group">
                                                    <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                                    <Field
                                                        type="password"
                                                        id="confirmPassword"
                                                        name="confirmPassword"
                                                        className="form-control"
                                                    />
                                                    <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12"> 
                                                <div className="mb-3 form-group">
                                                    <label htmlFor="country" className="form-label">Country or Regin</label>
                                                    <Field
                                                        as="select"
                                                        id="country"
                                                        name="country"
                                                        className="form-select"
                                                    >
                                                        <option value="">Select Country</option>
                                                        <option value="USA">USA</option>
                                                        <option value="Canada">Canada</option>
                                                        <option value="India">India</option>
                                                    </Field>
                                                    <ErrorMessage name="country" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12"> 
                                                <button type="submit" className="btn w-100 btnBlue" disabled={isSubmitting}>
                                                    Sign Up
                                                </button>
                                            </div>


                                            <div className="col-lg-12 col-md-12"> 
                                                <div className="or">
                                                    <span>Or</span>
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12"> 
                                                <div className="continueLogin">
                                                    <ul>
                                                        <li>
                                                            <img width="16px" height="16px" src="/img/google_icon.svg" alt="img"/>
                                                            <span>Continue with Google</span>
                                                        </li>
                                                        <li>
                                                            <img width="16px" height="16px" src="/img/apple_icon.svg" alt="img"/>
                                                            <span>Continue with Apple</span>
                                                        </li>
                                                        <li>
                                                            <img width="16px" height="16px" src="/img/facebook_icon.svg" alt="img"/>
                                                            <span>Continue with Facebook</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12"> 
                                                <div className="descLogin">
                                                    <p>By continuing, you agree to Rentid's Terms of Service and acknowledge you've read our Privacy Policy</p>
                                                    <p>Already have an account? <a href="#">Login</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GuestSignup;
