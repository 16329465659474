import react from 'react';


const Header = () => {
    return (
        <>
        {/* <h1>Navbar:</h1> */}
        </>
    )
}

export default Header;