
import './Footer.css'
import { useNavigate } from 'react-router-dom';
import { Link } from "react-scroll";
import React, { useState, useContext } from 'react'
import { Form, Modal,Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import BetaForm from '../BetaForm';
import * as Yup from 'yup';
import qs from 'qs';
import axios from 'axios';
import { UserContext } from '../../context/FormDataContext.js';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { API_ENDPOINT } from '../../Constant/Constant';


const Footer = ({activeKey,setActiveKey,isVisible, setIsVisible,handleCloseButtonClick,tabTitle,handleTabSelect}) => {

    const navigate = useNavigate();
    
    const handleFooterMenuClick = (key) => {
        navigate(`/${key.toLowerCase()}`);
        setActiveKey(key);
        setIsVisible(true);
        // alert(key)
      };

      const [show,setShow]=useState(false);
     
      const { updateUserFormData } = useContext(UserContext);
      const [isExpanded, setIsExpanded] = useState(false);
      const text = `By submitting this form, you consent to Rentid contacting you via SMS, phone, and email for information about our services, promotional offers, and updates. You understand and agree that Rentid may use automated technology including autodialing, AI generated messaging, and AI generated voice to contact you, and that consent is not a condition of purchase. Message frequency may vary, and message/data rates may apply. You can opt out at any time by following the instructions in our communications or by contacting us at contact@rentid.io.`;
  
      const shortText = text.slice(0, 120) + '...'; // First 160 characters + ellipsis
      const readMoreText = isExpanded ? text : shortText;
      const [value, setValue] = useState();
      const openModal = () => {
        const modalElement = document.getElementById("exampleModal");
        const modalInstance = new window.bootstrap.Modal(modalElement);
        modalInstance.show();
      };
  const validationSchema = Yup.object().shape({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      // companyName: Yup.string().required('Company Name is required'),
      phone: Yup.string()
      .required('Phone number is required'),
      // .min(10, 'Phone number must be at least 10 digits'),
      email: Yup.string().email('Email is invalid').required('Email is required'),
      state: Yup.string().required('State is required'),
      fleet: Yup.string().required('Fleet size is required')
  });
  
      const { register, handleSubmit, formState: { errors }, reset, control } = useForm({
          resolver: yupResolver(validationSchema)
      });
  
      const onSubmit = async (data) => {        
          try {
              // Prepare the form data object
              const formData = {
                  'waitlist[first_name]': data.firstName || 'First Name',
                  'waitlist[last_name]': data.lastName || 'Last Name',
                  'waitlist[company_name]': data.companyName || 'Company Name',
                  'waitlist[phone_number]': data.phone || 'Phone Number',
                  'waitlist[email_address]': data.email || 'Email Address',
                  'waitlist[state]': data.state || 'State',
                  'waitlist[fleet_size]': data.fleet || 'Fleet Size',
              };
      
              // Convert formData object to URL-encoded string
              const urlEncodedData = qs.stringify(formData);
      
              // Configure the request
              let config = {
                  method: 'post',
                  // url: 'https://api.getrentid.io/api/waitlist/submit-waitlist',
                  url: `${API_ENDPOINT}/waitlist/submit-waitlist`,
                  headers: { 
                      'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  data: urlEncodedData
              };
      
              // Make the request
              axios.request(config)
                  .then((response) => {
                      console.log(JSON.stringify(response.data));
                      updateUserFormData(data.firstName, data.lastName,data.companyName, data.phone,data.email, data.state,data.fleet);
                      navigate("/beta-signup");
                      // window.location.href = '/thankyou';
                  })
                  .catch((error) => {
                      console.error('Error submitting form:', error);
                  });
      
              // Reset the form
              handleClose();
              reset();
          } catch (error) {
              console.error('Error submitting form:', error);
          }
      };

      const handleModal=()=>{
        setShow(!show)
    }
    const handleClose=()=>{
        setShow(false)
    }


    return (
        <footer id="footer" className="footer position-relative">
            <div className="container">
                <div className="row align-items-top pb-4 footer-top-row">
                    <div className="col-xs-12 col-sm-12 col-md-1 ps-0 logo-rent">
                        <img src="/img/r-logo.svg" alt="" class="img-fluid"/>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-3 footermenu px-lg-4 px-2 rent-id-links-ftr rentidFooter">
                        <h2>Rentid</h2>
                        <ul>
                            <li><a href="#fleet">Fleets</a></li>
                            <li><a href="#partners">Partners</a></li>                           
                            <li><a href="#support">Support</a></li>
                        </ul>
                    </div>

                    <div className="col-xs-6 col-sm-6 col-md-3 footermenu footermenu-products productsFooter">
                        <h2>Products</h2>
                        {/* <ul>
                            <li><img src="/img/rentdirect.svg" alt="" /></li>
                            <li><img src="/img/rentshield.svg" alt="" /></li>
                            <li><img src="/img/rentid.svg" alt="" /></li>
                            <li><img src="/img/rentsuccess.svg" alt="" /></li>
                            <li><img src="/img/rentacademy.svg" alt="" /></li>
                        </ul>
                         */}
                    <ul>
                        {/* <li onClick={() => handleFooterMenuClick('home')}>Home</li> */}
                        <li onClick={() => handleFooterMenuClick('RentDirect')}><img src="/img/rentdirect.svg" alt="" /></li>
                        <li onClick={() => handleFooterMenuClick('Rentid')}><img src="/img/rentid.svg" alt="" /></li>
                        <li onClick={() => handleFooterMenuClick('rentshield')}><img src="/img/rentshield.svg" alt="" /></li>
                        <li onClick={() => handleFooterMenuClick('RentSuccess')}><img src="/img/rentsuccess.svg" alt="" /></li>
                        <li onClick={() => handleFooterMenuClick('RentAcademy')}><img src="/img/rentacademy.svg" alt="" /></li>
                    </ul>
                    </div>

                    <div className="col-xs-6 col-sm-6 col-md-3 footermenu footer-meu-list featuresFooter">
                        <h2>Features</h2>
                        <ul>
                            <li><a href="#office">Back Office</a></li>
                            {/* <li><a href="#coverages">Insurance</a></li> */}
                            <li><Link to='coverages' smooth={true} duration={1000}>Insurance</Link> </li> 
                            <li><Link to='insurance' smooth={true} duration={1000}>Ride Share</Link> </li> 
                            <li><a href="#customizable">Customizable</a> </li>
                            <li><a href="#rentalapp">Rental App</a></li>
                        </ul>
                    </div>

                    {/* <div className="col-12 col-xs-12 col-md-2 footermenu">
                        <h2>Insurance</h2>
                        <ul>
                            <li><a href="#insurance">Rental</a></li>
                            <li><a href="#insurance">Ride Share</a></li>
                        </ul>
                    </div> */}

                    <div className="col-xs-6 col-sm-6 col-md-2 footermenu footer-meu-list1 featuresSign">
                        <h2>Sign Up</h2>
                        <ul>
                            <li><a onClick={handleModal}>Waitlist</a></li>
                            <Modal id="waitlist" className='joinlist' show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Join The Waitlist</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="first_name">First Name<span class="required">*</span></Form.Label>
                                                <Form.Control type="text" id="first_name" placeholder='Enter first name' name="waitlist[first_name]" {...register('firstName')}
                                                     />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.firstName?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="last_name">Last Name<span class="required">*</span></Form.Label>
                                                <Form.Control type="text" id="last_name" placeholder='Enter last name' name="lastName" {...register('lastName')}
                                                    />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.lastName?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-12">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="company_name">Company name (Optional)</Form.Label>
                                                <Form.Control type="text" id="company_name" placeholder='Enter company name' name="companyName" {...register('companyName')}
                                                  />
                                                    isInvalid={!!errors.companyName} 
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.companyName?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                       <Form.Group className="mb-3">
                                    <Form.Label htmlFor="phone_number">
                                        Phone number<span className="required">*</span>
                                    </Form.Label>
                                    <Controller
                                        name="phone"
                                        control={control}
                                        rules={{
                                            required: "Phone number is required",
                                            validate: (value) => value && value.length >= 10 || "Phone number must be valid",
                                        }}
                                        render={({ field }) => (
                                            <PhoneInput
                                                {...field}
                                                id="phone_number"
                                                limitMaxLength="10"
                                                defaultCountry="US"
                                                placeholder="(000) 000-000"
                                                onChange={(value) => field.onChange(value)} // Ensure React Hook Form value updates
                                                value={field.value || ''}
                                            />
                                        )}
                                    />
                                    {errors.phone && (
                                        <Form.Control.Feedback type="invalid" className="d-block">
                                            {errors.phone.message}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>

                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="email_address">Email address<span class="required">*</span></Form.Label>
                                                <Form.Control type="email" id="email_address" placeholder='Enter your email' name="email" {...register('email')}
                                                    isInvalid={!!errors.email} />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="state">State<span class="required">*</span></Form.Label>
                                                <Form.Select id="state" name="state" {...register('state')} isInvalid={!!errors.state}>
                                                    <option value="">Select</option>
                                                    <option value='Alabama'>Alabama</option>
                                                    <option value='Alaska'>Alaska</option>
                                                    <option value='Arizona'>Arizona</option>
                                                    <option value='Arkansas'>Arkansas</option>
                                                    <option value='California'>California</option>
                                                    <option value='Colorado'>Colorado</option>
                                                    <option value='Connecticut'>Connecticut</option>
                                                    <option value='Delaware'>Delaware</option>
                                                    <option value='Florida'>Florida</option>
                                                    <option value='Georgia'>Georgia</option>
                                                    <option value='Hawaii'>Hawaii</option>
                                                    <option value='Idaho'>Idaho</option>
                                                    <option value='Illinois'>Illinois</option>
                                                    <option value='Indiana'>Indiana</option>
                                                    <option value='Iowa'>Iowa</option>
                                                    <option value='Kansas'>Kansas</option>
                                                    <option value='Kentucky'>Kentucky</option>
                                                    <option value='Louisiana'>Louisiana</option>
                                                    <option value='Maine'>Maine</option>
                                                    <option value='Maryland'>Maryland</option>
                                                    <option value='Massachusetts'>Massachusetts</option>
                                                    <option value='Michigan'>Michigan</option>
                                                    <option value='Minnesota'>Minnesota</option>
                                                    <option value='Mississippi'>Mississippi</option>
                                                    <option value='Missouri'>Missouri</option>
                                                    <option value='Montana'>Montana</option>
                                                    <option value='Nebraska'>Nebraska</option>
                                                    <option value='Nevada'>Nevada</option>
                                                    <option value='New Hampshire'>New Hampshire</option>
                                                    <option value='New Jersey'>New Jersey</option>
                                                    <option value='New Mexico'>New Mexico</option>
                                                    <option value='New York'>New York</option>
                                                    <option value='North Carolina'>North Carolina</option>
                                                    <option value='North Dakota'>North Dakota</option>
                                                    <option value='Ohio'>Ohio</option>
                                                    <option value='Oklahoma'>Oklahoma</option>
                                                    <option value='Oregon'>Oregon</option>
                                                    <option value='Pennsylvania'>Pennsylvania</option>
                                                    <option value='Rhode Island'>Rhode Island</option>
                                                    <option value='South Carolina'>South Carolina</option>
                                                    <option value='South Dakota'>South Dakota</option>
                                                    <option value='Tennessee'>Tennessee</option>
                                                    <option value='Texas'>Texas</option>
                                                    <option value='Utah'>Utah</option>
                                                    <option value='Vermont'>Vermont</option>
                                                    <option value='Virginia'>Virginia</option>
                                                    <option value='Washington'>Washington</option>
                                                    <option value='West Virginia'>West Virginia</option>
                                                    <option value='Wisconsin'>Wisconsin</option>
                                                    <option value='Wyoming'>Wyoming</option>
                                                   
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.state?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="fleet_size">Fleet size</Form.Label>
                                                <Form.Select id="fleet_size" name="fleet" {...register('fleet')} isInvalid={!!errors.fleet}>
                                                    <option value="">Select</option>
                                                    <option value="1">1</option>
                                                    <option value="2 to 5">2 to 5</option>
                                                    <option value="5 to 10">5 to 10</option>
                                                    <option value="Greater than 10">Greater than 10</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.fleet?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <p className='mb-0 disclaimer'>{readMoreText} <span onClick={() => setIsExpanded(!isExpanded)}>
                                            {isExpanded ? 'Read Less' : 'Read More'}
                                        </span></p>
                                        
                                        <p className="mt-3 mb-0 d-flex align-items-start">
                                            <input type="checkbox" id="privacy-checkbox" className="me-2"  required/>
                                            <label htmlFor="privacy-checkbox" className="me-2">
                                            I consent to receiving communications from Rentid via email, phone call, and SMS, including with the use of AI and automated dialing technologies, and I agree to Rentid’s <a href="terms" target="_blank" className="text-white">Terms of Service</a> and <a href="privacy" target="_blank" className="text-white">Privacy Policy</a>.
                                            </label>
                                           
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button  type="submit" className='joinbtn' variant="primary">
                            Join The Waitlist
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
                            <li><a  className='openModal'>Beta</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row copyright">
                    <div className="col-md-6 text-center mx-auto privacy-legal">
							<div class="dropdown">
								  {/* <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
									<img src="/img/flags.svg" class="img-fluid"/> USA
								  </a> */}

								  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
									<li><a class="dropdown-item" href="#"><img src="/img/flags.svg" class="img-fluid"/> USA</a></li>
									<li><a class="dropdown-item" href="#"><img src="/img/flags.svg" class="img-fluid"/> USA</a></li>
								  </ul>
							</div>
							<ul className="policy d-flex align-items-center justify-content-between">
								<li style={{ listStyle: 'none', display: 'flex' }}><img style={{paddingRight:'4px'}} src="/img/copyright.svg" alt="" width="20" /> 2024 Rentid, Inc.</li>
								<li><a href="terms" target='_blank'>Legal</a></li>
								<li><a href="privacy" target='_blank'>Privacy Policy</a></li>
							</ul>
                    </div>
                  
                     <div className="col-md-6 text-lg-end text-center">
                        <ul className="social-links d-flex justify-content-lg-end justify-content-center">
                            <li>
                                <a href="https://www.facebook.com/profile.php?id=100087781668300" target="_blank">
                                    <img src="/img/facebook-01.svg" alt="" width="20" />
                                </a>
                            </li>

                            <li>
                                <a href="https://www.youtube.com/channel/UCHfKMNp9QPpsyedUSgFm7oA" target="_blank">
                                    <img src="/img/youtube.svg" alt="" width="20" />
                                </a>
                            </li>

                            <li>
                                <a href="https://www.instagram.com/rentid.io/" target="_blank">
                                    <img src="/img/instagram.svg" alt="" width="20" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/in/aria-aston-087162260/" target="_blank">
                                    <img src="/img/linkedin-01.svg" alt="" width="20" />
                                </a>
                            </li>
                        </ul>
                    </div> 
                </div>
            </div>
            {/* <BetaForm/> */}
        </footer>
    );
};

export default Footer;
