import react from 'react';
import './BannerCommericial.css';

const BannerCommericial = ()=>{
    return(
        <>
<section className="bannerCommericialMain">
  <div className="container">
    <div className="bannerCommericialOuter">
      <div className="row align-items-center">
          <div className="col-lg-6 col-md-6">
            <div className="bannerCommericialLeft">
              <img className="img-fluid" src="/img/rentshield/shield_banner.svg" alt="img"/>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="bannerCommericialRight">
              <small>COMMERCIAL AUTO INSURANCE & PROTECTION</small>
              <h2>Direct rental, Primary, Commercial Auto Insurance, <span>billed per day.</span></h2>
              <p>Protect revenue-generating assets for personal and gig-economy rentals.</p>
              <ul>
                  <li>On-Rent Coverage</li>
                  <li>Off-Rent Coverage</li>
                  <li>Comparisons</li>
                  <li>Personal Rental</li>
                  <li>Gig Rental</li>
                  <li>FAQ</li>
              </ul>
            </div>
          </div>
      </div>
    </div>
  </div>
</section>
        </>
    )
}

export default BannerCommericial;