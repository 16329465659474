import react from 'react';
import { useState } from "react";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Container, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import './InsuranceServicesFaq.css';

const InsuranceServicesFaq = () => {
  // Set the default expanded state to the first accordion (panel0)
  const [expanded, setExpanded] = useState("panel0");

  // Data for each accordion
  const accordionData = [
    {
        title: "What is RentShield?",
        content: "RentShield is a technology product that provides on-rent protection plans and claims management solutions for car rental operators.",
        image: "/img/rentshield/insuranceServicesFaq_1.png",
    },
    {
        title: "Who is RentShield for?",
        content: "RentShield is designed for independent car rental operators, car sharing hosts, dealerships and other businesses that need reliable, scalable insurance coverage for their rental or subscription fleet.",
        image: "/img/rentshield/insuranceServicesFaq_2.png",
    },
    {
        title: "How does RentShield work?",
        content: "RentShield protects your vehicles while they are on rent by providing liability and physical damage coverage during rental period. Vehicle must have suitable off-rent coverage to participate in RentShield’s on-rent protection plans.",
        image: "/img/rentshield/insuranceServicesFaq_3.png",
    },
    {
        title: "Which vehicle types qualify for coverage?",
        content: "Vehicle must be compatible with modern telematics devices, must have 4 wheels, do not require a commercial license to operate, or reach a weight limit that typically requires a commercial license to operate.",
        image: "/img/rentshield/insuranceServicesFaq_4.png",
    },
    {
        title: "In which states is coverage offered?",
        content: "Lorem ipsum this . van fgon talent",
        image: "/img/rentshield/insuranceServicesFaq_5.png",
    },
    {
        title: "Are protection plans comprehensive?",
        content: "Yes. Premier Protection Plan offers industry-leading coverage for incidents like collisions, theft, earthquakes, and more. Certain exclusions may apply; refer to our policy for full details.",
        image: "/img/rentshield/insuranceServicesFaq_6.png",
    },
    {
        title: "Who is responsible for the deductible? ",
        content: "Car rental operators are responsible for deductible amounts when filing a claim, but the Resolution Center provides services to help recover payments from guests.",
        image: "/img/rentshield/insuranceServicesFaq_7.png",
    },
  ];


  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (

    <section className='insuranceServicesFaqMain' id='InsuranceServicesFAQ'>
        <div className='container-fluid'>
            <div className="row jusify-content-center align-items-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div class="insuranceFaqLeft">
                        <div className="headIsurance">
                            <h2>Insurance Services FAQ</h2>
                        </div>
                        <div class="insuranceFaqLeft_Inn">
                            {accordionData.map((accordion, index) => (
                            <Box key={index} className="boxAccordian">
                                <Accordion className="headingAccordian"
                                expanded={expanded === `panel${index}`}
                                onChange={handleAccordionChange(`panel${index}`)}
                                >
                                <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        >
                                    
                                    <Typography>
                                    {accordion.title}
                                    </Typography>
                                </AccordionSummary>
                                </Accordion>
                                {/* Content below the accordion button */}
                                {expanded === `panel${index}` && (
                                <Box className="summaryAccordian">
                                    <Typography>{accordion.content}</Typography>
                                </Box>
                                )}
                            </Box>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div class="insuranceFaqRight">
                            {accordionData.map((accordion, index) => (
                            expanded === `panel${index}` ? (
                                <Box key={index} sx={{ textAlign: 'center' }}>
                                <img
                                    src={accordion.image}
                                    alt={`Image for ${accordion.title}`}
                                />
                                </Box>
                            ) : null
                            ))}
                    </div>
                </div>
            </div>


    </div>
    </section>
    
  );
};

export default InsuranceServicesFaq;
