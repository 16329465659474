import react from 'react';
import React, { useState } from "react";


const GuestNumberVerification = () => {
    const [otp, setOtp] = useState(new Array(6).fill(""));

    const handleChange = (element, index) => {
      if (!/^[0-9]*$/.test(element.value)) return;
  
      const newOtp = [...otp];
      newOtp[index] = element.value;
      setOtp(newOtp);
  
      // Move focus to the next input
      if (element.value && index < otp.length - 1) {
        element.nextSibling?.focus();
      }
    };
  
    const handleKeyDown = (e, index) => {
      if (e.key === "Backspace" && !otp[index] && index > 0) {
        e.preventDefault();
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        e.target.previousSibling?.focus();
      }
    };
  
    return (
        <>
        <section className="confirmDetailsMain">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-12">
                        <div className="confirmDetailsInner">
                            <h2>Number Verification</h2>
                            <h3>Please verify your account by accessing the email we sent to you or by entering the code we 
                                sent to your mobile device and email (908 XXXXXXX).</h3>
                            <div className="otp-inputs">
                                {otp.map((value, index) => (
                                    <input
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    className="otp-box"
                                    value={value}
                                    onChange={(e) => handleChange(e.target, index)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    required
                                    />
                                ))}
                            </div>
                            <p>Did you receive the SMS? <a href="#"> If not, try again to resend the code</a></p>
                            <div className="col-lg-12 col-md-12"> 
                                <button type="submit" className="btn w-100 btnBlue" disabled>
                                    Recover Password
                                </button>
                            </div>
                            <div class="loaderOuter">
                                <div class="loader"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        </>
    )
}

export default GuestNumberVerification