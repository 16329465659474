import react from 'react';
import React, { useState, useEffect , useContext} from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { UserContext } from "./../../context/FormDataContext";

const ResetPassword = () => {

    const [formData, setFormData] = useState({});
    const { userData } = useContext(UserContext);
    // const [value, setValue] = useState()
    // const setValue=(val)=>{
    //   setPhoneNumber(val)
    // }
    const { values, setFieldValue, errors, touched } = useFormikContext();
    const [isFocused, setIsFocused] = useState(false);
    
    const handlePhoneChange = (value) => {
      setFieldValue('phone_number', value);
    };
    console.log("errorserrorserrors", errors)
  
    
     
   
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFieldValue(name, value);
    };
    return (
        <>
            <div className="commonHostMain">
                <div className="commonHostOuter">
                    <h2>We will send you a verification code on this email.</h2>
                    <div className="resetPasswordInner">
                        <div className="host_form py-0">
                      
                            <div className="form-group">
                            <label htmlFor="email" className="form-label">
                                Email<span class="required">*</span>
                            </label>
                            <Field
                            value={values.email_address || userData.email}
                            onChange={handleInputChange}
                            required
                                type="email"
                                className="form-control"
                            
                                id="email_address"
                                name="email_address"
                                placeholder="Enter email address"
                            />
                            <ErrorMessage
                                name="email_address"
                                component="div"
                                className="invalid-feedback"
                            />
                            </div>
                            <p class="specialLine">Want to change recovery method? <span>Recover by Phone Number.</span></p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword;