import react from 'react';
import Insurancestyle from './RentShieldInsurance.css';


const RentShieldInsurance = ()=>{

 

    return(
        <>
        <section className='rentShieldInsuranceMain'>
         <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                    <div className='rentShieldInsuranceInner'>
                        <h2>Simplified Digital Claims Processing</h2>
                        <p>RentShield insurance products are provided by Rentid Property & Casualty, LLC and   underwritten by Gotham Insurance Company, rated A (Excellent) by AM Best. Coverage availability and terms may vary by state. Please refer to policy documents for complete details.</p>
                    </div>
                </div>
              </div>
         </div>
        </section>
        </>
    )
}

export default RentShieldInsurance;