import react from 'react';
import './ResolutionCenter.css';

const ResolutionCenter = ()=>{
    return(
        <>
<section className="resolutionCenterMain">
    <div className="container">
        <div class="row justify-content-center">
          <div class="col-lg-9">
              <div class="headResolutionCenter">
                  <h2>Incidents tend to happen during rental periods.</h2>
              </div>
          </div>
        </div>
        <div className='resolutionCenterOuter'>
            <div className='row align-items-center'>
                <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                    <div className='resolutionCenterLeft'>
                        <figure>
                            <img src="/img/rentdirect/resolution_center.jpg" alt="Resolution Center" />
                        </figure>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                    <div className='resolutionCenterRight'>
                        <h3>Visit the Resolution Center</h3>
                        <p>Resolve incidents with guests, recover funds, and handle simple or complex rentals confidently.</p>
                        <ul>
                            <li>
                                <img width="16px" height="16px" src="/img/tick-grade.svg" alt="icon" />
                                <span>Trip Refunds</span>
                            </li>
                            <li>
                                <img width="16px" height="16px" src="/img/tick-grade.svg" alt="icon" />
                                <span>Insurance Claims</span>
                            </li>
                            <li>
                                <img width="16px" height="16px" src="/img/tick-grade.svg" alt="icon" />
                                <span>Guest Violations</span>
                            </li>
                            <li>
                                <img width="16px" height="16px" src="/img/tick-grade.svg" alt="icon" />
                                <span>Report Guest</span>
                            </li>
                        </ul>
                        <a class="btn btnTransparent_2" href="#">Learn More
                            <img width="24px" height="24px" src="/img/arrow-right-white.svg" alt="icon"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div className='resolutionCenterBottom'>
            <div className='row align-items-center'>
                <div className='col-lg-12'>
                    <img class="img-fluid"  src="/img/power_rent_success.png" alt="icon" />
                </div>
            </div>
        </div>

</div>
</section>
        </>
    )
}

export default ResolutionCenter;