import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import { Swiper } from "swiper";
import "swiper/swiper-bundle.css";
import './YourBrand.css';
import { ScrollTrigger } from "gsap/ScrollTrigger";

const YourBrand = () => {
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const images = document.querySelectorAll("[sticky-image]");
    const blocks = document.querySelectorAll("[scrolling-block]");
    // const scrollingImages = new Swiper(".scrolling-images", {
    //   slidesPerView: 1,
    //   initialSlide: 0,
    //   effect: "fade",
    //   direction:"vertical",
    //   fadeEffect: {
    //     crossFade: true,
    //   },
    //   speed: 900,
    //   on: {
    //     slideChange: function () {
    //       handleSlideChange(this);
    //     },
    //   },
    // });


    const scrollingImages = new Swiper(".scrolling-images", {
      slidesPerView: 1,
      initialSlide: 0,
      effect: "fade", 
      direction: "vertical",
      fadeEffect: {
        crossFade: true,
      },
      speed: 200,
      on: {
        slideChangeTransitionStart: function () {
          // Get the current and next slides
          const previousSlide = this.slides[this.previousIndex];
          const currentSlide = this.slides[this.activeIndex];
  
          // Zoom out the previous slide
          // gsap.to(previousSlide, {
          //  scale: 0.75, // Slightly more zoomed out for a more dramatic effect
          // opacity: 0.5, 
          // duration: 0.2, 
          // ease: "power4.out",
          // });
  
          // // Reset and zoom in the next slide
          // gsap.fromTo(
          //   currentSlide,
          //   { scale: 1.3, opacity: 0 }, 
          //   { scale: 1, opacity: 1, duration: 0.2, ease: "power2.out" }
          // );
        },
      },
    });

    const scrollingGradients = new Swiper(".backgound_gradient", {
      slidesPerView: 1,
      initialSlide: 0,
      effect: "fade",
      direction:"vertical",
      fadeEffect: {
        crossFade: true,
      },
      speed: 200,
      on: {
        slideChange: function () {
          handleSlideChange(this);
        },
      },
    });

    gsap.registerPlugin(ScrollTrigger);

    blocks.forEach((block) => {
      ScrollTrigger.create({
        trigger: block,
        start: isTablet ? "top 60%" : "top 30%",
        end: isTablet ? "bottom 60%" : "bottom 40%",
        onEnter: () => changeImage(block),
        onLeave: () => changeImage(block),
        onLeaveBack: () => changeImage(block),
        onEnterBack: () => changeImage(block),
      });
    });

    // Scroll-triggered image animation
    // gsap.utils.toArray(".scrolling_item").forEach((block, index) => {
    //   ScrollTrigger.create({
    //     trigger: block,
    //     start: "top bottom",
    //     end: "bottom top",
    //     scrub: true,
    //     onEnter: () => animateImage(index),
    //     onLeaveBack: () => resetImage(index),
    //   });
    // });

    function changeImage(block) {
      const blockNum = parseInt(block.getAttribute("scrolling-block"));
      if (scrollingImages) {
        scrollingImages.slideTo(blockNum);
        scrollingGradients.slideTo(blockNum);
      } else {
        console.error("scrollingImages is not initialized");
      }
    }

    function handleSlideChange(swiper) {
      const currentImages = document.querySelectorAll(`[sticky-image='${swiper.activeIndex}']`);

      // Fade out all images
      images.forEach((img) => {
        gsap.to(img, {
         opacity: 0,
            duration: 0.4,
            scale: 0.8
        });
      });

      // Fade in the current images with a smooth top-to-bottom effect
      currentImages.forEach((img) => {
        gsap.to(img, {
          opacity: 1,
            duration: 0.4,
            scale: 1
        });
      });
    }

    // function animateImage(index) {
    //   const image = document.querySelector(`[sticky-image="${index}"]`);
    //   gsap.to(image, {
    //     opacity: 1,
    //     y: 0,  // Move image back to its original position
    //     duration: 0.6,
    //     ease: "power2.out",
    //   });
    // }

    // function resetImage(index) {
    //   const image = document.querySelector(`[sticky-image="${index}"]`);
    //   gsap.to(image, {
    //     opacity: 0,
    //     y: 50,  // Move image down slightly for the reset effect
    //     duration: 0.6,
    //     ease: "power2.in",
    //   });
    // }

    function checkViewportSize() {
      const isTabletOrBelow = window.innerWidth <= 991;
      setIsTablet(isTabletOrBelow);
    }

    // Add resize and load event listeners
    window.addEventListener("resize", checkViewportSize);
    window.addEventListener("load", checkViewportSize);

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener("resize", checkViewportSize);
      window.removeEventListener("load", checkViewportSize);
    };
  }, [isTablet]); // Dependency on isTablet state to re-run if the screen size changes

  return (
    <>
      <section class="yourBrandMain">
        <div class="container">
        <div className="row justify-content-center stickyHeadingBrand">
          <div className="col-lg-12">
            <div className="headYourBrand">
              <h2>Your Brand, Our Tech</h2>
              <p>
                Branded digital experiences that increase awareness, build lasting
                customer relationships and drive more commission-free rentals.
              </p>
              <small>On our platform, your storefront takes center stage for guests to:</small>
              <ul>
                <li>
                  <img width="16px" height="16px" src="/img/tick-grade.svg" alt="icon" />
                  <span>Visit Your Website</span>
                </li>
                <li>
                  <img width="16px" height="16px" src="/img/tick-grade.svg" alt="icon" />
                  <span>Call You Directly</span>
                </li>
                <li>
                  <img width="16px" height="16px" src="/img/tick-grade.svg" alt="icon" />
                  <span>Send Direct Messages</span>
                </li>
                <li>
                  <img width="16px" height="16px" src="/img/tick-grade.svg" alt="icon" />
                  <span>Email Your Business</span>
                </li>
                <li>
                  <img width="16px" height="16px" src="/img/tick-grade.svg" alt="icon" />
                  <span>Follow On Social Media</span>
                </li>
                <li>
                  <img width="16px" height="16px" src="/img/tick-grade.svg" alt="icon" />
                  <span>Search Your Business Online</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
          <div class="swiper backgound_gradient">
            <div class="swiper-wrapper" aria-live="polite">
              <div class="swiper-slide gradient_slide" aria-label="1 / 4">
                <div sticky-image="0" class="rd_marron"></div>
              </div>
              <div class="swiper-slide gradient_slide" aria-label="2 / 4">
                <div sticky-image="1" class="rd_forest"></div>
              </div>
              <div class="swiper-slide gradient_slide" aria-label="3 / 4">
                <div sticky-image="2" class="rg_pink"></div>
              </div>
              <div class="swiper-slide gradient_slide" aria-label="4 / 4">
                <div sticky-image="3" class="rg_red"></div>
              </div>
            </div>
          </div>

          <div class="yourBrandOuter">
            <div id="scroll-block" class="yourBrandLeft">
              <div scrolling-block="0" class="scrolling_item">
                <div class="yourBrandInner">
                  <h3>Create a <span>free rental website.</span></h3>
                  <p>
                    Rentid supports your marketing with a <span>free vehicle rental website</span>
                    —use our simple website builder to enhance your reach, attract customers, and
                    build a standout brand at no extra cost. Enjoy easy setup and increased
                    visibility for your business.
                  </p>
                </div>
              </div>

              <div scrolling-block="1" class="scrolling_item">
                <div class="yourBrandInner">
                  <h3>Already <span>have your own</span> website?</h3>
                  <p>
                    We provide an <span>embed link</span> that seamlessly integrates with your
                    site, making it rental ready. This saves you time on setup, ensures your
                    branding stays consistent, and enhances your site with powerful rental
                    management tools.
                  </p>
                </div>
              </div>

              <div scrolling-block="2" class="scrolling_item">
                <div class="yourBrandInner">
                  <h3><span>Your brand</span> on any mobile device.</h3>
                  <p>
                    Your <span>website is fully responsive</span>, included at no extra cost to
                    save you time, money and headaches. Guests will appreciate the flexibility of
                    connecting with your brand seamlessly on tablets or mobile devices.
                  </p>
                </div>
              </div>

              <div scrolling-block="3" class="scrolling_item">
                <div class="yourBrandInner">
                  <h3><span>Your storefront</span> on an app experience.</h3>
                  <p>
                    Your dedicated app experience provides renters with an intuitive, user-friendly
                    platform to explore your business, check vehicle availability, and manage
                    bookings—enhancing both your rental operations and the guest experience.
                  </p>
                  <div class="yourBrandLeftBtn">
                    <a href="#">
                      <img class="img-fluid" src="/img/google_play.png" alt="icon" />
                    </a>
                    <a href="#">
                      <img class="img-fluid" src="/img/app_store.png" alt="icon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="yourBrandRight">
              <div class="mobile_bg">
                <div class="background_grain_black">
                  <div class="grains_image"></div>
                </div>
              </div>
              <div class="swiper scrolling-images">
                <div class="swiper-wrapper">
                  <div class="swiper-slide scrolling_image_slide" role="group" aria-label="1 / 4">
                    <div class="changing_img">
                      <img class="contain_image" src="/img/rentdirect/your_brand_1.png" />
                    </div>
                  </div>
                  <div class="swiper-slide scrolling_image_slide" role="group" aria-label="2 / 4">
                    <div class="changing_img">
                      <img class="contain_image" src="/img/rentdirect/your_brand_2.png" />
                    </div>
                  </div>
                  <div class="swiper-slide scrolling_image_slide" role="group" aria-label="3 / 4">
                    <div class="changing_img">
                      <img class="contain_image" src="/img/rentdirect/your_brand_3.png" />
                    </div>
                  </div>
                  <div class="swiper-slide scrolling_image_slide" role="group" aria-label="4 / 4">
                    <div class="changing_img">
                      <video width="226" height="423" controls loop autoPlay muted playsInline>
                        <source src="/img/rentdirect/your_brand_4.mov" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default YourBrand;
