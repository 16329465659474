import react from 'react';
import './AnalyticsReporting.css';

const AnalyticsReporting = ()=>{
    return(
        <>
<section className="analyticsReportingMain">
    <img class="analyticsReportingRight" src="/img/rentdirect/analytics_reporting.png" alt="Img"/>
    <div className="container">
        <div class="row">
          <div class="col-lg-10">
              <div class="headAnalyticsReporting">
                  <h2>Analytics & Reporting</h2>
              </div>
          </div>
        </div>
        <div className='row'>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                <div className='analyticsReportingLeft'>
                    <img width="41px" height="41px" src="/img/analytics_icon_2.svg" alt="star"/>
                    <h3>Data-Driven Decisions for Growth</h3>
                    <p>Gain valuable insights into your business with our powerful analytics and reporting tools. 
                        Make informed, data-driven decisions by accessing real-time metrics and customizable reports. 
                        Understand trends, identify opportunities for improvement, and forecast future performance to 
                        drive sustainable growth.</p>
                    <ul>
                        <li>
                            <img width="24px" height="24px" src="/img/rentdirect/check_fleet.svg" alt="icon"/>
                            <span><strong>Real-Time Performance Metrics:</strong> Monitor key indicators to stay informed and make data-driven decisions.</span>
                        </li>
                        <li>
                            <img width="24px" height="24px" src="/img/rentdirect/check_fleet.svg" alt="icon"/>
                            <span><strong>Customizable Dashboards:</strong> Focus on what matters most by tailoring views to your needs.</span>
                        </li>
                        <li>
                            <img width="24px" height="24px" src="/img/rentdirect/check_fleet.svg" alt="icon"/>
                            <span><strong>Fleet and Revenue Insights:</strong> Optimize vehicle usage and track financial trends with detailed reports.</span>
                        </li>
                        <li>
                            <img width="24px" height="24px" src="/img/rentdirect/check_fleet.svg" alt="icon"/>
                            <span><strong>Customer Behavior and Trends:</strong> Learn from customer interactions and forecast market changes to refine strategies.</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
        </>
    )
}

export default AnalyticsReporting;